<template>
  <b-modal
    id="modalSolved"
    :title="title"
    ok-title="Play Next Puzzle"
    ok-variant="dark"
    body-class="text-center"
    centered ok-only
    @hide="$emit('close')"
  >
    <p>You solved the puzzle!</p>

    <div class="puzzle-summary">
      <div class="hint">{{ board[0] }}</div>
      <div>{{ board[1] }}</div>
      <div>{{ board[2] }}</div>
      <div>{{ board[3] }}</div>
      <div class="hint">{{ board[4] }}</div>

      <div>{{ board[5] }}</div>
      <div class="spacer">&nbsp;</div>
      <div>{{ board[6] }}</div>

      <div>{{ board[7] }}</div>
      <div class="spacer">#{{ index + 1 }} - {{ date }}</div>
      <div>{{ board[8] }}</div>

      <div>{{ board[9] }}</div>
      <div class="spacer">&nbsp;</div>
      <div>{{ board[10] }}</div>

      <div class="hint">{{ board[11] }}</div>
      <div>{{ board[12] }}</div>
      <div>{{ board[13] }}</div>
      <div>{{ board[14] }}</div>
      <div class="hint">{{ board[15] }}</div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Solved',
  data: () => ({
    board: [],
  }),
  watch: {
    '$store.getters.puzzle': {
      handler(puzzle) {
        this.board = puzzle.board
        this.$forceUpdate()
      },
      deep: true,
    },
  },
  computed: {
    title() {
      const titles = [
        'Excellent!',
        'Great!',
        'Nice!',
        'Well Done!',
        'Way To Go!',
        'You Did It!',
        'Congratulations!',
      ]
      return titles[ Math.floor(Math.random() * titles.length) ]
    },
    date() {
      return moment().format('D MMM YY')
    },
    index() {
      return this.$store.getters.index
    },
  },
}
</script>

<style scoped>
.puzzle-summary {
  width: calc(5 * 2.25rem);
  margin: 1rem auto;
}
.puzzle-summary > div {
  display: inline-block;
  width: 2rem; height: 2rem;
  margin: 0.125rem;
  border: 0.125rem solid #dee2e6;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}
.puzzle-summary > div.hint { border-color: #343a40; }
.puzzle-summary > div.spacer {
  width: 6.5rem; height: 1rem;
  border: none;
  font-size: 0.75rem;
  line-height: 0.5rem;
}
</style>

