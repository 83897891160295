<template>
  <div class="keyboard">
    <b-button
      class="key" v-for="(letter, index) in letters" :key="index"
      :variant="active == index ? 'primary' : 'dark'"
      :disabled="!letter.enabled"
      @click="toggleActive(index)"
    >
      {{ letter.letter }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'KeyBoard',
  data: () => ({
    active: -1,
    letters: [],
  }),
  watch: {
    '$store.getters.active': {
      handler() {
        this.active = this.$store.getters.active
      },
    },
    '$store.getters.puzzle': {
      handler(puzzle) {
        if (puzzle === null) {
          this.letters = []
        } else {
          this.letters = puzzle.letters
        }
      },
      deep: true,
    },
  },
  methods: {
    toggleActive(index) {
      if (this.active == index) {
        this.$store.commit('setActive', -1)
      } else {
        this.$store.commit('setActive', index)
      }
    },
  }
}
</script>

<style scoped>
.keyboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  width: calc(4 * 5rem);
  margin: 1rem auto;
}
.key {
  width: 4rem; height: 4rem;
  margin: 0.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.key:disabled { opacity: 0.5; }
</style>
