<template>
  <div
    :class="{'cell': true, 'invalid': invalid, 'hint': hint }"
    @click="selectCell(index)"
  >
    {{ board[ index ] }}
  </div>
</template>

<script>
export default {
  name: 'GameBoardCell',
  props: {
    check: String,
    hint: Boolean,
    index: Number,
  },
  data: () => ({
    board: [],
    results: '',
  }),
  watch: {
    '$store.getters.puzzle': {
      handler(puzzle) {
        if (puzzle === null) {
          this.board = []
          this.results = ''
        } else {
          this.board = puzzle.board
          this.results = puzzle.results
          this.$forceUpdate()
        }
      },
      deep: true,
    },
  },
  computed: {
    invalid() {
      const letters = this.check.split('')

      for (let i in letters) {
        if (this.results.indexOf(letters[i]) !== -1) {
          return true
        }
      }

      return false
    },
  },
  methods: {
    selectCell(index) {
      if (this.hint) return
      this.$store.commit('selectCell', index)
    },
  },
}
</script>

<style>
.cell {
  width: 4rem; height: 4rem;
  margin: 0.125rem;

  border: 0.125rem solid #dee2e6;
  border-radius: 0.25rem;

  font-size: 2rem;
  line-height: 3.5rem;
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.cell.hint {
  border-color: #343a40;
  font-weight: bold;
}

.cell.invalid {
  color: #dc3545;
  border-color: #dc3545;
}

</style>
