export default [
  [
    {
      "words": [
        "float",
        "fence",
        "tooth",
        "earth"
      ],
      "hints": [
        "f",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "shirt",
        "still",
        "trail",
        "level"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "i",
        "l",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "often",
        "occur",
        "newly",
        "reply"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "y"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "e",
        "f",
        "l",
        "l",
        "p",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "shine",
        "sweet",
        "elite",
        "those"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "s",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "slide",
        "steal",
        "eager",
        "lover"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "o",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "alone",
        "adopt",
        "entry",
        "today"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "d",
        "l",
        "n",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "given",
        "grass",
        "night",
        "start"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "layer",
        "lunch",
        "range",
        "house"
      ],
      "hints": [
        "l",
        "r",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "n",
        "n",
        "o",
        "s",
        "u",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "dance",
        "depth",
        "every",
        "happy"
      ],
      "hints": [
        "d",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "p",
        "p",
        "p",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "panel",
        "photo",
        "layer",
        "offer"
      ],
      "hints": [
        "p",
        "l",
        "o",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "f",
        "f",
        "h",
        "n",
        "o",
        "t",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "found",
        "fewer",
        "draft",
        "react"
      ],
      "hints": [
        "f",
        "d",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "n",
        "o",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "maker",
        "maybe",
        "rural",
        "equal"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "b",
        "e",
        "k",
        "q",
        "r",
        "u",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "alive",
        "aside",
        "exact",
        "exist"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "i",
        "i",
        "i",
        "l",
        "s",
        "s",
        "v",
        "x",
        "x"
      ]
    },
    {
      "words": [
        "small",
        "share",
        "limit",
        "elect"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "m",
        "r"
      ]
    },
    {
      "words": [
        "angry",
        "adopt",
        "yield",
        "trend"
      ],
      "hints": [
        "a",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "music",
        "moral",
        "coast",
        "light"
      ],
      "hints": [
        "m",
        "c",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "i",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "voice",
        "visit",
        "essay",
        "today"
      ],
      "hints": [
        "v",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "i",
        "i",
        "i",
        "o",
        "o",
        "s",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "found",
        "first",
        "draft",
        "trust"
      ],
      "hints": [
        "f",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "f",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "level",
        "local",
        "learn",
        "lemon"
      ],
      "hints": [
        "l",
        "l",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "m",
        "o",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "offer",
        "occur",
        "refer",
        "river"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "f",
        "i",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "terms",
        "troop",
        "stake",
        "pause"
      ],
      "hints": [
        "t",
        "s",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "k",
        "m",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "legal",
        "lower",
        "least",
        "react"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "g",
        "o",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "price",
        "plant",
        "early",
        "truly"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "l",
        "l",
        "l",
        "n",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "force",
        "fruit",
        "event",
        "treat"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "split",
        "scene",
        "today",
        "early"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "l",
        "l",
        "n",
        "o",
        "p",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "exist",
        "empty",
        "teach",
        "youth"
      ],
      "hints": [
        "e",
        "t",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "m",
        "o",
        "p",
        "s",
        "t",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "river",
        "reach",
        "route",
        "horse"
      ],
      "hints": [
        "r",
        "r",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "field",
        "first",
        "dress",
        "terms"
      ],
      "hints": [
        "f",
        "d",
        "t",
        "s"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "m",
        "r",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "nerve",
        "novel",
        "elite",
        "loose"
      ],
      "hints": [
        "n",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "crime",
        "cheap",
        "enjoy",
        "party"
      ],
      "hints": [
        "c",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "j",
        "m",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "angry",
        "after",
        "youth",
        "rough"
      ],
      "hints": [
        "a",
        "y",
        "r",
        "h"
      ],
      "letters": [
        "e",
        "f",
        "g",
        "g",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "throw",
        "tribe",
        "wheel",
        "equal"
      ],
      "hints": [
        "t",
        "w",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "h",
        "h",
        "i",
        "o",
        "q",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "pride",
        "power",
        "early",
        "ready"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "e",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "works",
        "wound",
        "solve",
        "drive"
      ],
      "hints": [
        "w",
        "s",
        "d",
        "e"
      ],
      "letters": [
        "i",
        "k",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "drive",
        "dream",
        "enemy",
        "marry"
      ],
      "hints": [
        "d",
        "e",
        "m",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "m",
        "n",
        "r",
        "r",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "smart",
        "stage",
        "table",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "g",
        "i",
        "l",
        "l",
        "m",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "start",
        "solar",
        "trace",
        "raise"
      ],
      "hints": [
        "s",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "coast",
        "cheek",
        "taste",
        "knife"
      ],
      "hints": [
        "c",
        "t",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "h",
        "i",
        "n",
        "o",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "frame",
        "fight",
        "event",
        "tight"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "m",
        "n",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "steal",
        "south",
        "lemon",
        "human"
      ],
      "hints": [
        "s",
        "l",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "m",
        "m",
        "o",
        "o",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "tough",
        "theme",
        "humor",
        "eager"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "g",
        "h",
        "m",
        "m",
        "o",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "plate",
        "press",
        "equal",
        "small"
      ],
      "hints": [
        "p",
        "e",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "l",
        "l",
        "m",
        "q",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "grade",
        "giant",
        "every",
        "today"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "d",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "among",
        "angry",
        "guess",
        "yours"
      ],
      "hints": [
        "a",
        "g",
        "y",
        "s"
      ],
      "letters": [
        "e",
        "g",
        "m",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "patch",
        "pitch",
        "house",
        "horse"
      ],
      "hints": [
        "p",
        "h",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "meter",
        "model",
        "river",
        "lower"
      ],
      "hints": [
        "m",
        "r",
        "l",
        "r"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "e",
        "e",
        "e",
        "i",
        "o",
        "o",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "agent",
        "award",
        "trick",
        "drink"
      ],
      "hints": [
        "a",
        "t",
        "d",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "i",
        "n",
        "n",
        "r",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "steel",
        "sweep",
        "limit",
        "plant"
      ],
      "hints": [
        "s",
        "l",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "m",
        "n",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "model",
        "moral",
        "later",
        "labor"
      ],
      "hints": [
        "m",
        "l",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "o",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "given",
        "green",
        "noise",
        "nurse"
      ],
      "hints": [
        "g",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "tower",
        "tired",
        "raise",
        "dance"
      ],
      "hints": [
        "t",
        "r",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "r",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "buyer",
        "bench",
        "radio",
        "hello"
      ],
      "hints": [
        "b",
        "r",
        "h",
        "o"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "n",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "juice",
        "judge",
        "enjoy",
        "empty"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "d",
        "g",
        "i",
        "j",
        "m",
        "n",
        "o",
        "p",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "local",
        "light",
        "lemon",
        "train"
      ],
      "hints": [
        "l",
        "l",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "m",
        "o",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "movie",
        "might",
        "enjoy",
        "truly"
      ],
      "hints": [
        "m",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "g",
        "h",
        "i",
        "i",
        "j",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "smart",
        "shout",
        "trust",
        "tight"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "h",
        "i",
        "m",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "youth",
        "yours",
        "house",
        "scene"
      ],
      "hints": [
        "y",
        "h",
        "s",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "photo",
        "paint",
        "onion",
        "train"
      ],
      "hints": [
        "p",
        "o",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "slave",
        "score",
        "enjoy",
        "enemy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "j",
        "l",
        "m",
        "n",
        "n",
        "o",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "avoid",
        "agent",
        "delay",
        "today"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "o",
        "o",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "grant",
        "ghost",
        "those",
        "theme"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "h",
        "m",
        "n",
        "o",
        "o",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "movie",
        "maybe",
        "every",
        "early"
      ],
      "hints": [
        "m",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "v",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "angry",
        "asset",
        "youth",
        "teach"
      ],
      "hints": [
        "a",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "n",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "trade",
        "twice",
        "exact",
        "elect"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "d",
        "e",
        "i",
        "l",
        "r",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "mount",
        "moral",
        "tribe",
        "loose"
      ],
      "hints": [
        "m",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "i",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "nurse",
        "noise",
        "early",
        "entry"
      ],
      "hints": [
        "n",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "ideal",
        "inner",
        "layer",
        "river"
      ],
      "hints": [
        "i",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "e",
        "i",
        "n",
        "n",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "great",
        "group",
        "total",
        "panel"
      ],
      "hints": [
        "g",
        "t",
        "p",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "found",
        "fresh",
        "drive",
        "house"
      ],
      "hints": [
        "f",
        "d",
        "h",
        "e"
      ],
      "letters": [
        "e",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "dozen",
        "drink",
        "noise",
        "knife"
      ],
      "hints": [
        "d",
        "n",
        "k",
        "e"
      ],
      "letters": [
        "e",
        "f",
        "i",
        "i",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "s",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "serve",
        "strip",
        "enjoy",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "j",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "truck",
        "teach",
        "knife",
        "horse"
      ],
      "hints": [
        "t",
        "k",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "f",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "local",
        "leave",
        "later",
        "error"
      ],
      "hints": [
        "l",
        "l",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "score",
        "slice",
        "enjoy",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "i",
        "j",
        "l",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "tough",
        "theme",
        "house",
        "elite"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "l",
        "m",
        "o",
        "o",
        "s",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "steal",
        "shirt",
        "lemon",
        "train"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "m",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "offer",
        "order",
        "ratio",
        "radio"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "e",
        "e",
        "f",
        "f",
        "i",
        "i",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "space",
        "sugar",
        "earth",
        "rough"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "g",
        "g",
        "o",
        "p",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "admit",
        "apply",
        "terms",
        "yours"
      ],
      "hints": [
        "a",
        "t",
        "y",
        "s"
      ],
      "letters": [
        "d",
        "e",
        "i",
        "l",
        "m",
        "m",
        "o",
        "p",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "route",
        "round",
        "early",
        "dirty"
      ],
      "hints": [
        "r",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "offer",
        "ought",
        "reply",
        "today"
      ],
      "hints": [
        "o",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "f",
        "f",
        "g",
        "h",
        "l",
        "o",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "there",
        "trial",
        "event",
        "light"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "pause",
        "peace",
        "event",
        "exist"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "flame",
        "found",
        "enemy",
        "dirty"
      ],
      "hints": [
        "f",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "m",
        "m",
        "n",
        "n",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "sleep",
        "steal",
        "porch",
        "laugh"
      ],
      "hints": [
        "s",
        "p",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "l",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "super",
        "steal",
        "range",
        "leave"
      ],
      "hints": [
        "s",
        "r",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "n",
        "p",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "sharp",
        "state",
        "plane",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "l",
        "l",
        "n",
        "r",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "cream",
        "cable",
        "model",
        "equal"
      ],
      "hints": [
        "c",
        "m",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "l",
        "o",
        "q",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "order",
        "occur",
        "right",
        "react"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "south",
        "shirt",
        "heart",
        "treat"
      ],
      "hints": [
        "s",
        "h",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "phone",
        "place",
        "eager",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "l",
        "n",
        "n",
        "o",
        "t"
      ]
    },
    {
      "words": [
        "paper",
        "price",
        "raise",
        "elite"
      ],
      "hints": [
        "p",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "p",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "peace",
        "pause",
        "early",
        "essay"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "e",
        "l",
        "r",
        "s",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "pilot",
        "press",
        "teach",
        "south"
      ],
      "hints": [
        "p",
        "t",
        "s",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "start",
        "steal",
        "today",
        "lucky"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "k",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "again",
        "alive",
        "newly",
        "every"
      ],
      "hints": [
        "a",
        "n",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "i",
        "l",
        "l",
        "r",
        "v",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "stage",
        "shape",
        "error",
        "enter"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "yours",
        "yield",
        "short",
        "doubt"
      ],
      "hints": [
        "y",
        "s",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "h",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "proud",
        "plate",
        "depth",
        "earth"
      ],
      "hints": [
        "p",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "dream",
        "dozen",
        "match",
        "north"
      ],
      "hints": [
        "d",
        "m",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "ideal",
        "image",
        "loose",
        "elite"
      ],
      "hints": [
        "i",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "i",
        "l",
        "m",
        "o",
        "o",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "craft",
        "count",
        "track",
        "think"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "f",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "cover",
        "chest",
        "react",
        "tight"
      ],
      "hints": [
        "c",
        "r",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "o",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "essay",
        "enter",
        "yield",
        "rapid"
      ],
      "hints": [
        "e",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "p",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "tight",
        "throw",
        "trace",
        "write"
      ],
      "hints": [
        "t",
        "t",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "r",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "dress",
        "draft",
        "small",
        "trial"
      ],
      "hints": [
        "d",
        "s",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "i",
        "l",
        "m",
        "r",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "anger",
        "adapt",
        "rural",
        "trial"
      ],
      "hints": [
        "a",
        "r",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "g",
        "i",
        "n",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "speed",
        "sugar",
        "delay",
        "ready"
      ],
      "hints": [
        "s",
        "d",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "e",
        "g",
        "l",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "thing",
        "thick",
        "grave",
        "knife"
      ],
      "hints": [
        "t",
        "g",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "drama",
        "draft",
        "arise",
        "taste"
      ],
      "hints": [
        "d",
        "a",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "f",
        "i",
        "m",
        "r",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "scale",
        "south",
        "error",
        "honor"
      ],
      "hints": [
        "s",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "write",
        "water",
        "exact",
        "right"
      ],
      "hints": [
        "w",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "r",
        "t",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "limit",
        "legal",
        "tight",
        "light"
      ],
      "hints": [
        "l",
        "t",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "i",
        "m"
      ]
    },
    {
      "words": [
        "share",
        "solid",
        "eight",
        "draft"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "space",
        "staff",
        "error",
        "fiber"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "f",
        "i",
        "o",
        "p",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "force",
        "first",
        "early",
        "today"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "union",
        "until",
        "north",
        "laugh"
      ],
      "hints": [
        "u",
        "n",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "g",
        "i",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "plane",
        "phone",
        "exact",
        "elect"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "h",
        "l",
        "l",
        "n",
        "n",
        "o",
        "x"
      ]
    },
    {
      "words": [
        "forth",
        "float",
        "happy",
        "today"
      ],
      "hints": [
        "f",
        "h",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "l",
        "o",
        "o",
        "o",
        "p",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "sales",
        "shake",
        "solar",
        "enter"
      ],
      "hints": [
        "s",
        "s",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "h",
        "k",
        "l",
        "l",
        "n",
        "o",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "seize",
        "steel",
        "entry",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "i",
        "k",
        "n",
        "r",
        "t",
        "t",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "seven",
        "shell",
        "novel",
        "local"
      ],
      "hints": [
        "s",
        "n",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "h",
        "l",
        "o",
        "o",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "major",
        "mount",
        "range",
        "twice"
      ],
      "hints": [
        "m",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "i",
        "j",
        "n",
        "n",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "panel",
        "porch",
        "leave",
        "house"
      ],
      "hints": [
        "p",
        "l",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "which",
        "wound",
        "human",
        "dozen"
      ],
      "hints": [
        "w",
        "h",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "m",
        "n",
        "o",
        "o",
        "u",
        "u",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "shift",
        "sales",
        "trial",
        "shall"
      ],
      "hints": [
        "s",
        "t",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r"
      ]
    },
    {
      "words": [
        "carry",
        "cloud",
        "yours",
        "dress"
      ],
      "hints": [
        "c",
        "y",
        "d",
        "s"
      ],
      "letters": [
        "a",
        "e",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "guide",
        "never",
        "error"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "i",
        "i",
        "o",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "happy",
        "heart",
        "yield",
        "trend"
      ],
      "hints": [
        "h",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "l",
        "n",
        "p",
        "p",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "quick",
        "quiet",
        "knife",
        "these"
      ],
      "hints": [
        "q",
        "k",
        "t",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "i",
        "n",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "cabin",
        "cause",
        "novel",
        "equal"
      ],
      "hints": [
        "c",
        "n",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "i",
        "o",
        "q",
        "s",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "adapt",
        "asset",
        "there",
        "trade"
      ],
      "hints": [
        "a",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "e",
        "e",
        "h",
        "p",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "whose",
        "whole",
        "elect",
        "eight"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "g",
        "h",
        "h",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "smell",
        "space",
        "large",
        "elite"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "l",
        "m",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "watch",
        "works",
        "heavy",
        "sorry"
      ],
      "hints": [
        "w",
        "h",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "k",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "along",
        "adapt",
        "guest",
        "treat"
      ],
      "hints": [
        "a",
        "g",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "l",
        "n",
        "o",
        "p",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "youth",
        "yield",
        "heart",
        "doubt"
      ],
      "hints": [
        "y",
        "h",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "trade",
        "trend",
        "elect",
        "draft"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "f",
        "l",
        "n",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "final",
        "fault",
        "level",
        "trail"
      ],
      "hints": [
        "f",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "agent",
        "after",
        "these",
        "range"
      ],
      "hints": [
        "a",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "g",
        "h",
        "n",
        "n",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "juice",
        "judge",
        "every",
        "enjoy"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "g",
        "i",
        "j",
        "n",
        "o",
        "r",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "woman",
        "works",
        "naked",
        "spend"
      ],
      "hints": [
        "w",
        "n",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "k",
        "k",
        "m",
        "n",
        "o",
        "o",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "sight",
        "start",
        "trend",
        "third"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "which",
        "wound",
        "horse",
        "drive"
      ],
      "hints": [
        "w",
        "h",
        "d",
        "e"
      ],
      "letters": [
        "c",
        "h",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "truly",
        "trend",
        "yours",
        "dress"
      ],
      "hints": [
        "t",
        "y",
        "d",
        "s"
      ],
      "letters": [
        "e",
        "e",
        "l",
        "n",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "sense",
        "seven",
        "earth",
        "north"
      ],
      "hints": [
        "s",
        "e",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "trial",
        "truly",
        "laugh",
        "youth"
      ],
      "hints": [
        "t",
        "l",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "i",
        "l",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "slide",
        "shoot",
        "error",
        "their"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "d",
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "juice",
        "judge",
        "eight",
        "exact"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "d",
        "g",
        "g",
        "h",
        "i",
        "i",
        "u",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "coach",
        "crash",
        "honor",
        "humor"
      ],
      "hints": [
        "c",
        "h",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "m",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "small",
        "score",
        "legal",
        "equal"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "g",
        "l",
        "m",
        "o",
        "q",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "piece",
        "place",
        "eager",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "t"
      ]
    },
    {
      "words": [
        "brown",
        "bring",
        "nerve",
        "glove"
      ],
      "hints": [
        "b",
        "n",
        "g",
        "e"
      ],
      "letters": [
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "sweet",
        "speed",
        "teach",
        "depth"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "e",
        "e",
        "p",
        "p",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "sight",
        "shift",
        "third",
        "tired"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "f",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "i",
        "i",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "horse",
        "human",
        "enter",
        "never"
      ],
      "hints": [
        "h",
        "e",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "m",
        "n",
        "o",
        "r",
        "s",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "short",
        "shelf",
        "these",
        "fence"
      ],
      "hints": [
        "s",
        "t",
        "f",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "h",
        "h",
        "h",
        "l",
        "n",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "empty",
        "elect",
        "young",
        "thing"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "l",
        "m",
        "n",
        "n",
        "o",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "three",
        "third",
        "elite",
        "dance"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "abuse",
        "apple",
        "event",
        "exact"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "l",
        "n",
        "p",
        "p",
        "s",
        "u",
        "v",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "steal",
        "shore",
        "local",
        "equal"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "h",
        "o",
        "o",
        "q",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "stage",
        "sleep",
        "empty",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "m",
        "p",
        "r",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "below",
        "brown",
        "watch",
        "north"
      ],
      "hints": [
        "b",
        "w",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "eager",
        "equal",
        "reach",
        "laugh"
      ],
      "hints": [
        "e",
        "r",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "q",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "light",
        "least",
        "tough",
        "teach"
      ],
      "hints": [
        "l",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "i",
        "o",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "since",
        "sweep",
        "elite",
        "plate"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "n",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "order",
        "often",
        "rapid",
        "naked"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "f",
        "i",
        "k",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "couch",
        "chief",
        "honey",
        "funny"
      ],
      "hints": [
        "c",
        "h",
        "f",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "h",
        "i",
        "n",
        "n",
        "n",
        "o",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "clock",
        "chart",
        "knife",
        "table"
      ],
      "hints": [
        "c",
        "k",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "f",
        "h",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "print",
        "prime",
        "tooth",
        "earth"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "m",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "angry",
        "asset",
        "yield",
        "trend"
      ],
      "hints": [
        "a",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "n",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "train",
        "tooth",
        "noise",
        "horse"
      ],
      "hints": [
        "t",
        "n",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "which",
        "write",
        "house",
        "elite"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "image",
        "ideal",
        "essay",
        "lucky"
      ],
      "hints": [
        "i",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "g",
        "k",
        "m",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "cause",
        "chest",
        "early",
        "today"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "h",
        "l",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "brain",
        "blame",
        "nerve",
        "elite"
      ],
      "hints": [
        "b",
        "n",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "m",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "stage",
        "since",
        "exact",
        "elect"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "e",
        "g",
        "i",
        "l",
        "n",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "allow",
        "agent",
        "watch",
        "touch"
      ],
      "hints": [
        "a",
        "w",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "g",
        "l",
        "l",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "admit",
        "avoid",
        "these",
        "drive"
      ],
      "hints": [
        "a",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "d",
        "e",
        "h",
        "i",
        "i",
        "i",
        "m",
        "o",
        "r",
        "s",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "enemy",
        "elite",
        "youth",
        "earth"
      ],
      "hints": [
        "e",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "m",
        "n",
        "o",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "agent",
        "abuse",
        "trail",
        "equal"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "g",
        "i",
        "n",
        "q",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "given",
        "great",
        "naked",
        "trend"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "k",
        "n",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "image",
        "ideal",
        "elect",
        "least"
      ],
      "hints": [
        "i",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "g",
        "l",
        "m",
        "s"
      ]
    },
    {
      "words": [
        "video",
        "voice",
        "offer",
        "error"
      ],
      "hints": [
        "v",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "e",
        "f",
        "f",
        "i",
        "i",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "slide",
        "still",
        "earth",
        "lunch"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "i",
        "i",
        "l",
        "l",
        "n",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "trial",
        "throw",
        "loose",
        "whose"
      ],
      "hints": [
        "t",
        "l",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "h",
        "h",
        "i",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "float",
        "first",
        "trust",
        "tight"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "pound",
        "patch",
        "dozen",
        "human"
      ],
      "hints": [
        "p",
        "d",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "m",
        "n",
        "o",
        "o",
        "t",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "sorry",
        "sheet",
        "yours",
        "terms"
      ],
      "hints": [
        "s",
        "y",
        "t",
        "s"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "h",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "movie",
        "mouse",
        "eager",
        "enter"
      ],
      "hints": [
        "m",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "s",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "court",
        "catch",
        "trail",
        "hotel"
      ],
      "hints": [
        "c",
        "t",
        "h",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "wheel",
        "watch",
        "leave",
        "horse"
      ],
      "hints": [
        "w",
        "l",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "h",
        "o",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "honor",
        "house",
        "route",
        "elite"
      ],
      "hints": [
        "h",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "favor",
        "fifty",
        "rough",
        "youth"
      ],
      "hints": [
        "f",
        "r",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "f",
        "g",
        "i",
        "o",
        "o",
        "o",
        "t",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "trace",
        "those",
        "enemy",
        "entry"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "m",
        "n",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "ahead",
        "agent",
        "death",
        "tough"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "g",
        "h",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "grade",
        "newly",
        "entry"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "staff",
        "sound",
        "fight",
        "doubt"
      ],
      "hints": [
        "s",
        "f",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "f",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "equal",
        "eager",
        "light",
        "right"
      ],
      "hints": [
        "e",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "q",
        "u"
      ]
    },
    {
      "words": [
        "tower",
        "trail",
        "rough",
        "lunch"
      ],
      "hints": [
        "t",
        "r",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "r",
        "u",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "style",
        "sweep",
        "error",
        "prior"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "w",
        "y"
      ]
    },
    {
      "words": [
        "fewer",
        "fifty",
        "round",
        "yield"
      ],
      "hints": [
        "f",
        "r",
        "y",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "f",
        "i",
        "i",
        "l",
        "n",
        "o",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "write",
        "woman",
        "error",
        "never"
      ],
      "hints": [
        "w",
        "e",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "nerve",
        "north",
        "enemy",
        "heavy"
      ],
      "hints": [
        "n",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "m",
        "n",
        "o",
        "r",
        "r",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "works",
        "waste",
        "stone",
        "elite"
      ],
      "hints": [
        "w",
        "s",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "k",
        "l",
        "n",
        "o",
        "o",
        "r",
        "s",
        "t",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "wheel",
        "whose",
        "lower",
        "error"
      ],
      "hints": [
        "w",
        "l",
        "e",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "h",
        "h",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "coast",
        "cabin",
        "tight",
        "night"
      ],
      "hints": [
        "c",
        "t",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "pride",
        "plane",
        "enter",
        "eager"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "n",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "layer",
        "legal",
        "rural",
        "local"
      ],
      "hints": [
        "l",
        "r",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "o",
        "r",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "final",
        "focus",
        "lower",
        "stair"
      ],
      "hints": [
        "f",
        "l",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "t",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "apart",
        "ahead",
        "there",
        "dance"
      ],
      "hints": [
        "a",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "h",
        "n",
        "p",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "lunch",
        "level",
        "happy",
        "lucky"
      ],
      "hints": [
        "l",
        "h",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "k",
        "n",
        "p",
        "p",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "owner",
        "offer",
        "react",
        "right"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "f",
        "g",
        "h",
        "i",
        "n",
        "w"
      ]
    },
    {
      "words": [
        "sweep",
        "still",
        "power",
        "lower"
      ],
      "hints": [
        "s",
        "p",
        "l",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "t",
        "w",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "chief",
        "coast",
        "fresh",
        "tough"
      ],
      "hints": [
        "c",
        "f",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "fresh",
        "force",
        "honey",
        "empty"
      ],
      "hints": [
        "f",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "m",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "favor",
        "fault",
        "refer",
        "tower"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "f",
        "l",
        "o",
        "o",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "house",
        "heart",
        "elect",
        "treat"
      ],
      "hints": [
        "h",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "l",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "crash",
        "class",
        "humor",
        "stair"
      ],
      "hints": [
        "c",
        "h",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "i",
        "l",
        "m",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "enemy",
        "enter",
        "yield",
        "round"
      ],
      "hints": [
        "e",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "n",
        "n",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "mouse",
        "mount",
        "early",
        "truly"
      ],
      "hints": [
        "m",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "l",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "apple",
        "about",
        "earth",
        "touch"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "l",
        "o",
        "o",
        "p",
        "p",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "watch",
        "worth",
        "heavy",
        "honey"
      ],
      "hints": [
        "w",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "horse",
        "habit",
        "event",
        "trust"
      ],
      "hints": [
        "h",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "funny",
        "fight",
        "youth",
        "tough"
      ],
      "hints": [
        "f",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "g",
        "g",
        "h",
        "i",
        "n",
        "n",
        "o",
        "o",
        "t",
        "u",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "naked",
        "north",
        "delay",
        "happy"
      ],
      "hints": [
        "n",
        "d",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "k",
        "l",
        "o",
        "p",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "pause",
        "plate",
        "error",
        "eager"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "l",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "trace",
        "tight",
        "essay",
        "truly"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "h",
        "i",
        "l",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "green",
        "grass",
        "north",
        "south"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "angle",
        "allow",
        "every",
        "worry"
      ],
      "hints": [
        "a",
        "e",
        "w",
        "y"
      ],
      "letters": [
        "e",
        "g",
        "l",
        "l",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "truth",
        "twice",
        "honor",
        "eager"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "prime",
        "patch",
        "error",
        "honor"
      ],
      "hints": [
        "p",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "m",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "pause",
        "plate",
        "enjoy",
        "early"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "j",
        "l",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "after",
        "album",
        "reply",
        "money"
      ],
      "hints": [
        "a",
        "r",
        "m",
        "y"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "e",
        "f",
        "l",
        "l",
        "n",
        "o",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "grand",
        "great",
        "draft",
        "treat"
      ],
      "hints": [
        "g",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "e",
        "e",
        "f",
        "n",
        "r",
        "r",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "small",
        "sales",
        "leave",
        "stare"
      ],
      "hints": [
        "s",
        "l",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "e",
        "e",
        "l",
        "l",
        "m",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "front",
        "focus",
        "terms",
        "sales"
      ],
      "hints": [
        "f",
        "t",
        "s",
        "s"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "l",
        "m",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "cover",
        "chair",
        "ready",
        "reply"
      ],
      "hints": [
        "c",
        "r",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "o",
        "p",
        "v"
      ]
    },
    {
      "words": [
        "their",
        "truly",
        "rough",
        "youth"
      ],
      "hints": [
        "t",
        "r",
        "y",
        "h"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "major",
        "motor",
        "route",
        "range"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "j",
        "n",
        "o",
        "o",
        "o",
        "o",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "anger",
        "alter",
        "refer",
        "river"
      ],
      "hints": [
        "a",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "g",
        "i",
        "l",
        "n",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "serve",
        "sharp",
        "enjoy",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "j",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "event",
        "exist",
        "think",
        "thick"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "start",
        "skill",
        "theme",
        "loose"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "k",
        "l",
        "m",
        "o",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "while",
        "would",
        "exact",
        "doubt"
      ],
      "hints": [
        "w",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "u",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "final",
        "front",
        "leave",
        "theme"
      ],
      "hints": [
        "f",
        "l",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "m",
        "n",
        "n",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "bible",
        "brain",
        "empty",
        "newly"
      ],
      "hints": [
        "b",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "i",
        "l",
        "l",
        "m",
        "p",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "bench",
        "blade",
        "habit",
        "eight"
      ],
      "hints": [
        "b",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "d",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n"
      ]
    },
    {
      "words": [
        "shout",
        "stair",
        "touch",
        "reach"
      ],
      "hints": [
        "s",
        "t",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "h",
        "i",
        "o",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "laugh",
        "labor",
        "hello",
        "radio"
      ],
      "hints": [
        "l",
        "h",
        "r",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "g",
        "i",
        "l",
        "l",
        "o",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "guest",
        "grant",
        "their",
        "tower"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "n",
        "o",
        "r",
        "s",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "swing",
        "shrug",
        "green",
        "grain"
      ],
      "hints": [
        "s",
        "g",
        "g",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "crazy",
        "cloud",
        "youth",
        "death"
      ],
      "hints": [
        "c",
        "y",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "giant",
        "glove",
        "trust",
        "eight"
      ],
      "hints": [
        "g",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shift",
        "sales",
        "trial",
        "shall"
      ],
      "hints": [
        "s",
        "t",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "chase",
        "cabin",
        "eager",
        "never"
      ],
      "hints": [
        "c",
        "e",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "piece",
        "prior",
        "entry",
        "ready"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "cloud",
        "chart",
        "daily",
        "truly"
      ],
      "hints": [
        "c",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "l",
        "l",
        "l",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "laugh",
        "light",
        "honor",
        "tower"
      ],
      "hints": [
        "l",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "humor",
        "habit",
        "ready",
        "truly"
      ],
      "hints": [
        "h",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "i",
        "l",
        "m",
        "o",
        "r",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "still",
        "stuff",
        "laugh",
        "flesh"
      ],
      "hints": [
        "s",
        "l",
        "f",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "g",
        "i",
        "l",
        "l",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "virus",
        "visit",
        "slice",
        "title"
      ],
      "hints": [
        "v",
        "s",
        "t",
        "e"
      ],
      "letters": [
        "c",
        "i",
        "i",
        "i",
        "i",
        "i",
        "l",
        "l",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "limit",
        "laugh",
        "trade",
        "horse"
      ],
      "hints": [
        "l",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "g",
        "i",
        "i",
        "m",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "habit",
        "horse",
        "tribe",
        "elite"
      ],
      "hints": [
        "h",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "b",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "twice",
        "tower",
        "eager",
        "refer"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "f",
        "g",
        "i",
        "o",
        "w",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "large",
        "lower",
        "every",
        "ready"
      ],
      "hints": [
        "l",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "g",
        "o",
        "r",
        "r",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "virus",
        "vital",
        "serve",
        "large"
      ],
      "hints": [
        "v",
        "s",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "i",
        "i",
        "r",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "video",
        "vital",
        "often",
        "learn"
      ],
      "hints": [
        "v",
        "o",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "f",
        "i",
        "i",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "minor",
        "meter",
        "refer",
        "river"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "i",
        "n",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "uncle",
        "urban",
        "every",
        "newly"
      ],
      "hints": [
        "u",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "e",
        "l",
        "l",
        "n",
        "r",
        "r",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "topic",
        "trail",
        "coast",
        "light"
      ],
      "hints": [
        "t",
        "c",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "i",
        "i",
        "i",
        "o",
        "o",
        "p",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "never",
        "novel",
        "refer",
        "labor"
      ],
      "hints": [
        "n",
        "r",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "o",
        "o",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "awful",
        "argue",
        "leave",
        "elite"
      ],
      "hints": [
        "a",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "g",
        "i",
        "l",
        "r",
        "t",
        "u",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "onion",
        "other",
        "north",
        "rough"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "h"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "brush",
        "below",
        "heavy",
        "worry"
      ],
      "hints": [
        "b",
        "h",
        "w",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "plane",
        "peace",
        "entry",
        "empty"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "m",
        "n",
        "n",
        "p",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "heart",
        "honor",
        "touch",
        "reach"
      ],
      "hints": [
        "h",
        "t",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "pilot",
        "proof",
        "these",
        "false"
      ],
      "hints": [
        "p",
        "t",
        "f",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "depth",
        "dress",
        "heavy",
        "sorry"
      ],
      "hints": [
        "d",
        "h",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "o",
        "p",
        "r",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "guide",
        "ghost",
        "exact",
        "trust"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "h",
        "i",
        "o",
        "r",
        "s",
        "s",
        "u",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "swing",
        "shoot",
        "grade",
        "taste"
      ],
      "hints": [
        "s",
        "g",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "naked",
        "nerve",
        "dirty",
        "early"
      ],
      "hints": [
        "n",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "k",
        "l",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "shock",
        "serve",
        "knife",
        "elite"
      ],
      "hints": [
        "s",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "favor",
        "force",
        "river",
        "error"
      ],
      "hints": [
        "f",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "trace",
        "those",
        "empty",
        "enemy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "m",
        "m",
        "n",
        "o",
        "p",
        "r",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "swing",
        "sweep",
        "giant",
        "print"
      ],
      "hints": [
        "s",
        "g",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "i",
        "n",
        "n",
        "n",
        "r",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "later",
        "lemon",
        "refer",
        "never"
      ],
      "hints": [
        "l",
        "r",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "m",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "eight",
        "event",
        "today",
        "truly"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "g",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "buyer",
        "brown",
        "river",
        "never"
      ],
      "hints": [
        "b",
        "r",
        "n",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "i",
        "o",
        "r",
        "u",
        "v",
        "v",
        "w",
        "y"
      ]
    },
    {
      "words": [
        "swear",
        "shirt",
        "refer",
        "their"
      ],
      "hints": [
        "s",
        "r",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "above",
        "adopt",
        "early",
        "truly"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "l",
        "l",
        "o",
        "o",
        "p",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "score",
        "sleep",
        "essay",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "paint",
        "plant",
        "think",
        "truck"
      ],
      "hints": [
        "p",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "n",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "pilot",
        "proud",
        "tribe",
        "drive"
      ],
      "hints": [
        "p",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "b",
        "i",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "sales",
        "swing",
        "space",
        "grade"
      ],
      "hints": [
        "s",
        "s",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "l",
        "n",
        "p",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "honor",
        "humor",
        "right",
        "react"
      ],
      "hints": [
        "h",
        "r",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "m",
        "n",
        "o",
        "o",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "actor",
        "about",
        "refer",
        "their"
      ],
      "hints": [
        "a",
        "r",
        "t",
        "r"
      ],
      "letters": [
        "b",
        "c",
        "e",
        "e",
        "e",
        "f",
        "h",
        "i",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "speed",
        "steal",
        "depth",
        "laugh"
      ],
      "hints": [
        "s",
        "d",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "g",
        "p",
        "p",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "judge",
        "joint",
        "early",
        "today"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "g",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "total",
        "train",
        "layer",
        "never"
      ],
      "hints": [
        "t",
        "l",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "o",
        "r",
        "t",
        "v",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "those",
        "trust",
        "enjoy",
        "truly"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "h",
        "j",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "dress",
        "dozen",
        "sport",
        "night"
      ],
      "hints": [
        "d",
        "s",
        "n",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "o",
        "p",
        "r",
        "r",
        "s",
        "z"
      ]
    },
    {
      "words": [
        "ocean",
        "onion",
        "nurse",
        "nerve"
      ],
      "hints": [
        "o",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "field",
        "final",
        "death",
        "lunch"
      ],
      "hints": [
        "f",
        "d",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "n",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "prime",
        "print",
        "elect",
        "treat"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "l",
        "m",
        "n",
        "r",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "shape",
        "super",
        "empty",
        "reply"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "l",
        "m",
        "p",
        "p",
        "p",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "bring",
        "blood",
        "guest",
        "doubt"
      ],
      "hints": [
        "b",
        "g",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "loose",
        "level",
        "eight",
        "limit"
      ],
      "hints": [
        "l",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "m",
        "o",
        "o",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "usual",
        "under",
        "least",
        "right"
      ],
      "hints": [
        "u",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "bench",
        "brown",
        "honey",
        "newly"
      ],
      "hints": [
        "b",
        "h",
        "n",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "w",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "worry",
        "wound",
        "yours",
        "dress"
      ],
      "hints": [
        "w",
        "y",
        "d",
        "s"
      ],
      "letters": [
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "radio",
        "range",
        "occur",
        "eager"
      ],
      "hints": [
        "r",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "c",
        "d",
        "e",
        "g",
        "g",
        "i",
        "n",
        "u"
      ]
    },
    {
      "words": [
        "other",
        "occur",
        "route",
        "range"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "g",
        "h",
        "n",
        "o",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "solve",
        "sauce",
        "essay",
        "enjoy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "j",
        "l",
        "n",
        "o",
        "o",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "cheap",
        "class",
        "porch",
        "south"
      ],
      "hints": [
        "c",
        "p",
        "s",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "l",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "state",
        "sheet",
        "error",
        "their"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "h",
        "i",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "maybe",
        "meter",
        "exist",
        "react"
      ],
      "hints": [
        "m",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "e",
        "i",
        "s",
        "t",
        "x",
        "y"
      ]
    },
    {
      "words": [
        "smoke",
        "slice",
        "event",
        "eight"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "k",
        "l",
        "m",
        "n",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "point",
        "pound",
        "trace",
        "dance"
      ],
      "hints": [
        "p",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "i",
        "n",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "birth",
        "broad",
        "honey",
        "daily"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "raise",
        "refer",
        "equal",
        "rural"
      ],
      "hints": [
        "r",
        "e",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "f",
        "i",
        "q",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "mount",
        "might",
        "truly",
        "today"
      ],
      "hints": [
        "m",
        "t",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "h",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "horse",
        "hello",
        "event",
        "ought"
      ],
      "hints": [
        "h",
        "e",
        "o",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "l",
        "l",
        "n",
        "o",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "stock",
        "shake",
        "knife",
        "elite"
      ],
      "hints": [
        "s",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "h",
        "i",
        "i",
        "k",
        "l",
        "n",
        "o",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "works",
        "watch",
        "shout",
        "heart"
      ],
      "hints": [
        "w",
        "s",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "k",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "chief",
        "court",
        "force",
        "those"
      ],
      "hints": [
        "c",
        "f",
        "t",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "h",
        "i",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "print",
        "prove",
        "total",
        "equal"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "n",
        "o",
        "o",
        "q",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "blind",
        "below",
        "dozen",
        "woman"
      ],
      "hints": [
        "b",
        "d",
        "w",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "o",
        "z"
      ]
    },
    {
      "words": [
        "sweep",
        "stage",
        "party",
        "enjoy"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "j",
        "n",
        "o",
        "r",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "sweep",
        "storm",
        "prior",
        "minor"
      ],
      "hints": [
        "s",
        "p",
        "m",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "ought",
        "ocean",
        "treat",
        "night"
      ],
      "hints": [
        "o",
        "t",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "bench",
        "bring",
        "human",
        "grain"
      ],
      "hints": [
        "b",
        "h",
        "g",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "m",
        "n",
        "n",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "guard",
        "glove",
        "dirty",
        "every"
      ],
      "hints": [
        "g",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "awful",
        "angle",
        "local",
        "equal"
      ],
      "hints": [
        "a",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "f",
        "g",
        "l",
        "n",
        "o",
        "q",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "grain",
        "guest",
        "noise",
        "there"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "avoid",
        "agree",
        "dirty",
        "essay"
      ],
      "hints": [
        "a",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "slide",
        "score",
        "eight",
        "exist"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "d",
        "g",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "begin",
        "build",
        "nerve",
        "drive"
      ],
      "hints": [
        "b",
        "n",
        "d",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "i",
        "i",
        "i",
        "l",
        "r",
        "r",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "issue",
        "inner",
        "equal",
        "rural"
      ],
      "hints": [
        "i",
        "e",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "n",
        "n",
        "q",
        "r",
        "s",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "tight",
        "total",
        "truth",
        "laugh"
      ],
      "hints": [
        "t",
        "t",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "g",
        "h",
        "i",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "cream",
        "close",
        "moral",
        "equal"
      ],
      "hints": [
        "c",
        "m",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "l",
        "o",
        "o",
        "q",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "grave",
        "grain",
        "early",
        "newly"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "l",
        "l",
        "r",
        "r",
        "r",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "video",
        "voice",
        "ought",
        "eight"
      ],
      "hints": [
        "v",
        "o",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "count",
        "civil",
        "train",
        "learn"
      ],
      "hints": [
        "c",
        "t",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shout",
        "stand",
        "taste",
        "dance"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "h",
        "n",
        "n",
        "o",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "blood",
        "basis",
        "draft",
        "short"
      ],
      "hints": [
        "b",
        "d",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "early",
        "extra",
        "yield",
        "ahead"
      ],
      "hints": [
        "e",
        "y",
        "a",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "r",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "sales",
        "skill",
        "shine",
        "loose"
      ],
      "hints": [
        "s",
        "s",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "k",
        "l",
        "l",
        "n",
        "o",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "alone",
        "apple",
        "every",
        "enemy"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "l",
        "l",
        "m",
        "n",
        "n",
        "o",
        "p",
        "p",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "twice",
        "those",
        "early",
        "entry"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "ratio",
        "right",
        "occur",
        "their"
      ],
      "hints": [
        "r",
        "o",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "empty",
        "exact",
        "youth",
        "tooth"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "m",
        "o",
        "o",
        "o",
        "p",
        "t",
        "t",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "ahead",
        "album",
        "drive",
        "mouse"
      ],
      "hints": [
        "a",
        "d",
        "m",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "h",
        "i",
        "l",
        "o",
        "r",
        "s",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "month",
        "major",
        "heart",
        "right"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "j",
        "n",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "check",
        "claim",
        "knife",
        "mouse"
      ],
      "hints": [
        "c",
        "k",
        "m",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "laugh",
        "lemon",
        "happy",
        "newly"
      ],
      "hints": [
        "l",
        "h",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "m",
        "o",
        "p",
        "p",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "first",
        "fence",
        "title",
        "elite"
      ],
      "hints": [
        "f",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "l",
        "n",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "seize",
        "style",
        "error",
        "eager"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "o",
        "r",
        "r",
        "t",
        "y",
        "z"
      ]
    },
    {
      "words": [
        "cream",
        "child",
        "marry",
        "daily"
      ],
      "hints": [
        "c",
        "m",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "stuff",
        "swing",
        "flame",
        "glove"
      ],
      "hints": [
        "s",
        "f",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "f",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "t",
        "u",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "blind",
        "build",
        "draft",
        "doubt"
      ],
      "hints": [
        "b",
        "d",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "f",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "trend",
        "taste",
        "dirty",
        "entry"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "n",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "solar",
        "shore",
        "reply",
        "enjoy"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "j",
        "l",
        "l",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "tribe",
        "these",
        "entry",
        "early"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "h",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "until",
        "urban",
        "limit",
        "night"
      ],
      "hints": [
        "u",
        "l",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "g",
        "h",
        "i",
        "i",
        "i",
        "i",
        "m",
        "n",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "guard",
        "guest",
        "doubt",
        "trust"
      ],
      "hints": [
        "g",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "flesh",
        "first",
        "humor",
        "their"
      ],
      "hints": [
        "f",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "m",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "guard",
        "grain",
        "dirty",
        "newly"
      ],
      "hints": [
        "g",
        "d",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "r",
        "r",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "drama",
        "doubt",
        "admit",
        "tight"
      ],
      "hints": [
        "d",
        "a",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "g",
        "h",
        "i",
        "i",
        "m",
        "m",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "shout",
        "sense",
        "these",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "h",
        "i",
        "l",
        "n",
        "o",
        "s",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "metal",
        "mouse",
        "lucky",
        "enemy"
      ],
      "hints": [
        "m",
        "l",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "k",
        "m",
        "n",
        "o",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "rough",
        "react",
        "horse",
        "title"
      ],
      "hints": [
        "r",
        "h",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "point",
        "paper",
        "tight",
        "react"
      ],
      "hints": [
        "p",
        "t",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "n",
        "o",
        "p"
      ]
    },
    {
      "words": [
        "trial",
        "trend",
        "laugh",
        "death"
      ],
      "hints": [
        "t",
        "l",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "i",
        "n",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "uncle",
        "usual",
        "essay",
        "lucky"
      ],
      "hints": [
        "u",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "k",
        "l",
        "n",
        "s",
        "s",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "pride",
        "plane",
        "essay",
        "empty"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "i",
        "l",
        "m",
        "n",
        "p",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "onion",
        "order",
        "never",
        "refer"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "r"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "n",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "throw",
        "thing",
        "woman",
        "given"
      ],
      "hints": [
        "t",
        "w",
        "g",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "i",
        "i",
        "m",
        "n",
        "o",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "adapt",
        "aware",
        "trial",
        "equal"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "d",
        "i",
        "p",
        "q",
        "r",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "cabin",
        "civil",
        "never",
        "lower"
      ],
      "hints": [
        "c",
        "n",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "e",
        "i",
        "i",
        "i",
        "o",
        "v",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "stick",
        "stake",
        "knife",
        "elite"
      ],
      "hints": [
        "s",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "i",
        "i",
        "i",
        "k",
        "l",
        "n",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "bench",
        "below",
        "house",
        "whose"
      ],
      "hints": [
        "b",
        "h",
        "w",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "h",
        "l",
        "n",
        "o",
        "o",
        "o",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "sense",
        "strip",
        "enemy",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "m",
        "n",
        "n",
        "r",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "ahead",
        "alter",
        "daily",
        "reply"
      ],
      "hints": [
        "a",
        "d",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "l",
        "p",
        "t"
      ]
    },
    {
      "words": [
        "birth",
        "basis",
        "human",
        "seven"
      ],
      "hints": [
        "b",
        "h",
        "s",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "m",
        "r",
        "s",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "civil",
        "crime",
        "loose",
        "elite"
      ],
      "hints": [
        "c",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "i",
        "i",
        "i",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "theme",
        "tower",
        "essay",
        "reply"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "l",
        "m",
        "o",
        "p",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "clear",
        "cause",
        "react",
        "event"
      ],
      "hints": [
        "c",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "l",
        "n",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "photo",
        "plate",
        "order",
        "error"
      ],
      "hints": [
        "p",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "h",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "ready",
        "range",
        "youth",
        "earth"
      ],
      "hints": [
        "r",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "g",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "yield",
        "yours",
        "drive",
        "stare"
      ],
      "hints": [
        "y",
        "d",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "visit",
        "rural",
        "trial"
      ],
      "hints": [
        "v",
        "r",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "ideal",
        "issue",
        "lover",
        "error"
      ],
      "hints": [
        "i",
        "l",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "worth",
        "wheel",
        "honor",
        "lover"
      ],
      "hints": [
        "w",
        "h",
        "l",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "h",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "leave",
        "later",
        "eager",
        "refer"
      ],
      "hints": [
        "l",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "g",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "fault",
        "focus",
        "trial",
        "shall"
      ],
      "hints": [
        "f",
        "t",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "h",
        "i",
        "l",
        "l",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "marry",
        "metal",
        "youth",
        "laugh"
      ],
      "hints": [
        "m",
        "y",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "leave",
        "legal",
        "elite",
        "large"
      ],
      "hints": [
        "l",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "g",
        "i",
        "l",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "alive",
        "apart",
        "error",
        "tower"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "o",
        "o",
        "p",
        "r",
        "r",
        "r",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "terms",
        "tribe",
        "solar",
        "enter"
      ],
      "hints": [
        "t",
        "s",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "scope",
        "shine",
        "elect",
        "exact"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "p",
        "x"
      ]
    },
    {
      "words": [
        "build",
        "brief",
        "draft",
        "fault"
      ],
      "hints": [
        "b",
        "d",
        "f",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "i",
        "i",
        "l",
        "l",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "drama",
        "drink",
        "aside",
        "knife"
      ],
      "hints": [
        "d",
        "a",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "f",
        "i",
        "i",
        "i",
        "m",
        "n",
        "n",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "dream",
        "drive",
        "movie",
        "elite"
      ],
      "hints": [
        "d",
        "m",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "party",
        "piece",
        "youth",
        "earth"
      ],
      "hints": [
        "p",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "r",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "nerve",
        "nurse",
        "enjoy",
        "enemy"
      ],
      "hints": [
        "n",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "j",
        "m",
        "n",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "prove",
        "prior",
        "error",
        "river"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "i",
        "i",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "sharp",
        "stake",
        "prove",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "k",
        "l",
        "o",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "stake",
        "salad",
        "elect",
        "doubt"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "k",
        "l",
        "l",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "abuse",
        "alive",
        "enter",
        "eager"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "s",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "sales",
        "style",
        "space",
        "elite"
      ],
      "hints": [
        "s",
        "s",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "l",
        "p",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "below",
        "basis",
        "waste",
        "scene"
      ],
      "hints": [
        "b",
        "w",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "giant",
        "guide",
        "today",
        "early"
      ],
      "hints": [
        "g",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "d",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "scale",
        "staff",
        "eight",
        "fight"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "t"
      ]
    },
    {
      "words": [
        "trend",
        "treat",
        "doubt",
        "trust"
      ],
      "hints": [
        "t",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "voter",
        "visit",
        "round",
        "tired"
      ],
      "hints": [
        "v",
        "r",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "cycle",
        "catch",
        "exist",
        "heart"
      ],
      "hints": [
        "c",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "i",
        "l",
        "r",
        "s",
        "t",
        "x",
        "y"
      ]
    },
    {
      "words": [
        "quote",
        "quite",
        "elect",
        "event"
      ],
      "hints": [
        "q",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "t",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "party",
        "plant",
        "yield",
        "third"
      ],
      "hints": [
        "p",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "n",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "craft",
        "crash",
        "tribe",
        "house"
      ],
      "hints": [
        "c",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "f",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "thick",
        "trend",
        "knife",
        "drive"
      ],
      "hints": [
        "t",
        "k",
        "d",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "whose",
        "waste",
        "every",
        "entry"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "speed",
        "smile",
        "drama",
        "extra"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "a"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "m",
        "m",
        "p",
        "r",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "earth",
        "elite",
        "heavy",
        "essay"
      ],
      "hints": [
        "e",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "l",
        "r",
        "s",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "adopt",
        "apart",
        "track",
        "thick"
      ],
      "hints": [
        "a",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "d",
        "h",
        "i",
        "o",
        "p",
        "p",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "stage",
        "sweep",
        "entry",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "n",
        "r",
        "r",
        "t",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "cover",
        "chest",
        "reach",
        "touch"
      ],
      "hints": [
        "c",
        "r",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "h",
        "o",
        "o",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "stick",
        "skill",
        "knife",
        "leave"
      ],
      "hints": [
        "s",
        "k",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "i",
        "i",
        "i",
        "k",
        "l",
        "n",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "shrug",
        "solid",
        "given",
        "dozen"
      ],
      "hints": [
        "s",
        "g",
        "d",
        "n"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "u",
        "v",
        "z"
      ]
    },
    {
      "words": [
        "event",
        "exist",
        "their",
        "tower"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "h",
        "i",
        "i",
        "n",
        "o",
        "s",
        "v",
        "w",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "thank",
        "tired",
        "knock",
        "drink"
      ],
      "hints": [
        "t",
        "k",
        "d",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "i",
        "n",
        "n",
        "n",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "glove",
        "giant",
        "exist",
        "trust"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "match",
        "major",
        "hotel",
        "rural"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "j",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shake",
        "shelf",
        "eight",
        "first"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "k",
        "l",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "clock",
        "clear",
        "knife",
        "route"
      ],
      "hints": [
        "c",
        "k",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "i",
        "l",
        "l",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "heart",
        "house",
        "trail",
        "equal"
      ],
      "hints": [
        "h",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "o",
        "q",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "money",
        "media",
        "yield",
        "avoid"
      ],
      "hints": [
        "m",
        "y",
        "a",
        "d"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "e",
        "i",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "apart",
        "again",
        "teach",
        "north"
      ],
      "hints": [
        "a",
        "t",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "g",
        "i",
        "o",
        "p",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "scope",
        "serve",
        "empty",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "l",
        "m",
        "o",
        "p",
        "p",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "score",
        "swear",
        "empty",
        "reply"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "l",
        "m",
        "o",
        "p",
        "p",
        "r",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "strip",
        "stone",
        "party",
        "every"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "sheet",
        "super",
        "table",
        "route"
      ],
      "hints": [
        "s",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "e",
        "h",
        "l",
        "o",
        "p",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "again",
        "after",
        "never",
        "refer"
      ],
      "hints": [
        "a",
        "n",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "g",
        "i",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "elect",
        "enter",
        "teach",
        "reach"
      ],
      "hints": [
        "e",
        "t",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "e",
        "e",
        "e",
        "e",
        "l",
        "n",
        "t"
      ]
    },
    {
      "words": [
        "label",
        "layer",
        "limit",
        "react"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "e",
        "i",
        "i",
        "m",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "style",
        "sweet",
        "enjoy",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "j",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "u",
        "w",
        "y"
      ]
    },
    {
      "words": [
        "metal",
        "model",
        "level",
        "legal"
      ],
      "hints": [
        "m",
        "l",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "e",
        "e",
        "g",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "fight",
        "flame",
        "trace",
        "elite"
      ],
      "hints": [
        "f",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "fault",
        "faith",
        "train",
        "human"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "i",
        "i",
        "l",
        "m",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "scene",
        "shelf",
        "exist",
        "fruit"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "n",
        "r",
        "s",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "third",
        "thing",
        "drive",
        "grave"
      ],
      "hints": [
        "t",
        "d",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "trace",
        "truth",
        "exist",
        "habit"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "i",
        "i",
        "r",
        "r",
        "s",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "woman",
        "wound",
        "nurse",
        "dance"
      ],
      "hints": [
        "w",
        "n",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "m",
        "n",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "three",
        "touch",
        "every",
        "heavy"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "h",
        "o",
        "r",
        "r",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "cloud",
        "close",
        "depth",
        "earth"
      ],
      "hints": [
        "c",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "l",
        "l",
        "o",
        "o",
        "p",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "nerve",
        "novel",
        "elite",
        "loose"
      ],
      "hints": [
        "n",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "cycle",
        "cross",
        "exact",
        "split"
      ],
      "hints": [
        "c",
        "e",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "i",
        "l",
        "l",
        "o",
        "p",
        "r",
        "s",
        "x",
        "y"
      ]
    },
    {
      "words": [
        "tooth",
        "taste",
        "humor",
        "enter"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "m",
        "n",
        "o",
        "o",
        "o",
        "s",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "strip",
        "steal",
        "panel",
        "level"
      ],
      "hints": [
        "s",
        "p",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "n",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "blade",
        "broad",
        "exist",
        "draft"
      ],
      "hints": [
        "b",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "f",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "month",
        "mayor",
        "happy",
        "ready"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "n",
        "o",
        "o",
        "p",
        "p",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "enemy",
        "extra",
        "young",
        "along"
      ],
      "hints": [
        "e",
        "y",
        "a",
        "g"
      ],
      "letters": [
        "e",
        "l",
        "m",
        "n",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "chart",
        "cable",
        "their",
        "error"
      ],
      "hints": [
        "c",
        "t",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "h",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "plate",
        "press",
        "every",
        "study"
      ],
      "hints": [
        "p",
        "e",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "l",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "human",
        "habit",
        "naked",
        "tired"
      ],
      "hints": [
        "h",
        "n",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "i",
        "k",
        "m",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "third",
        "trace",
        "delay",
        "entry"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "happy",
        "honor",
        "yield",
        "rapid"
      ],
      "hints": [
        "h",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "p",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "ghost",
        "great",
        "trend",
        "third"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "pride",
        "plate",
        "every",
        "enjoy"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "j",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "cream",
        "couch",
        "marry",
        "happy"
      ],
      "hints": [
        "c",
        "m",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "o",
        "p",
        "p",
        "r",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "since",
        "score",
        "exist",
        "event"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "first",
        "floor",
        "tight",
        "right"
      ],
      "hints": [
        "f",
        "t",
        "r",
        "t"
      ],
      "letters": [
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "sport",
        "shade",
        "twice",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "h",
        "i",
        "i",
        "l",
        "o",
        "p",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "argue",
        "agent",
        "enjoy",
        "today"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "g",
        "g",
        "j",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "plate",
        "piano",
        "exact",
        "ought"
      ],
      "hints": [
        "p",
        "e",
        "o",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "g",
        "h",
        "i",
        "l",
        "n",
        "t",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "trade",
        "train",
        "every",
        "newly"
      ],
      "hints": [
        "t",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "i",
        "l",
        "r",
        "r",
        "r",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "extra",
        "exact",
        "alive",
        "those"
      ],
      "hints": [
        "e",
        "a",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "l",
        "o",
        "r",
        "s",
        "t",
        "v",
        "x",
        "x"
      ]
    },
    {
      "words": [
        "mouth",
        "moral",
        "honor",
        "lover"
      ],
      "hints": [
        "m",
        "h",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "ratio",
        "rural",
        "often",
        "learn"
      ],
      "hints": [
        "r",
        "o",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "f",
        "i",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "prime",
        "phone",
        "eager",
        "error"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "i",
        "m",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "tooth",
        "troop",
        "habit",
        "pilot"
      ],
      "hints": [
        "t",
        "h",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "i",
        "i",
        "l",
        "o",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "skill",
        "style",
        "label",
        "equal"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "i",
        "k",
        "l",
        "l",
        "q",
        "t",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "cabin",
        "coach",
        "newly",
        "heavy"
      ],
      "hints": [
        "c",
        "n",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "i",
        "l",
        "o",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "great",
        "guest",
        "teach",
        "touch"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "draft",
        "drama",
        "third",
        "avoid"
      ],
      "hints": [
        "d",
        "t",
        "a",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "i",
        "m",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "sweep",
        "since",
        "print",
        "exact"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "i",
        "i",
        "n",
        "n",
        "r",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "style",
        "shout",
        "enjoy",
        "today"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "h",
        "j",
        "l",
        "n",
        "o",
        "o",
        "o",
        "t",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "basic",
        "build",
        "claim",
        "dream"
      ],
      "hints": [
        "b",
        "c",
        "d",
        "m"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "i",
        "i",
        "l",
        "l",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "store",
        "sheet",
        "enjoy",
        "today"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "h",
        "j",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "trade",
        "troop",
        "eight",
        "point"
      ],
      "hints": [
        "t",
        "e",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "h",
        "i",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "slave",
        "stake",
        "eager",
        "enter"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "k",
        "l",
        "n",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "glove",
        "giant",
        "elite",
        "trade"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "bench",
        "birth",
        "humor",
        "honor"
      ],
      "hints": [
        "b",
        "h",
        "h",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "m",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "rural",
        "river",
        "layer",
        "refer"
      ],
      "hints": [
        "r",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "r",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "story",
        "shall",
        "youth",
        "laugh"
      ],
      "hints": [
        "s",
        "y",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "l",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "drama",
        "dream",
        "alive",
        "mouse"
      ],
      "hints": [
        "d",
        "a",
        "m",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "m",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "sorry",
        "shall",
        "youth",
        "lunch"
      ],
      "hints": [
        "s",
        "y",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "reach",
        "raise",
        "hotel",
        "equal"
      ],
      "hints": [
        "r",
        "h",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "o",
        "q",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "favor",
        "fence",
        "rural",
        "equal"
      ],
      "hints": [
        "f",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "n",
        "o",
        "q",
        "r",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "these",
        "trail",
        "early",
        "lucky"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "k",
        "l",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "sorry",
        "shell",
        "youth",
        "lunch"
      ],
      "hints": [
        "s",
        "y",
        "l",
        "h"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "print",
        "patch",
        "three",
        "horse"
      ],
      "hints": [
        "p",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "lover",
        "labor",
        "radio",
        "ratio"
      ],
      "hints": [
        "l",
        "r",
        "r",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "i",
        "i",
        "o",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "fewer",
        "first",
        "rapid",
        "tired"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "i",
        "i",
        "i",
        "p",
        "r",
        "r",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "might",
        "mount",
        "track",
        "thank"
      ],
      "hints": [
        "m",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "h",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "value",
        "voice",
        "eager",
        "error"
      ],
      "hints": [
        "v",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "shift",
        "sound",
        "trust",
        "doubt"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "f",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "juice",
        "judge",
        "error",
        "enter"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "g",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "blind",
        "blame",
        "drive",
        "elite"
      ],
      "hints": [
        "b",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "i",
        "l",
        "l",
        "l",
        "m",
        "n",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "clear",
        "color",
        "refer",
        "river"
      ],
      "hints": [
        "c",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "l",
        "l",
        "o",
        "o",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "those",
        "tooth",
        "exact",
        "habit"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "h",
        "i",
        "o",
        "o",
        "o",
        "s",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "child",
        "clock",
        "dance",
        "knife"
      ],
      "hints": [
        "c",
        "d",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "f",
        "h",
        "i",
        "i",
        "l",
        "l",
        "n",
        "n",
        "o"
      ]
    },
    {
      "words": [
        "mayor",
        "might",
        "rough",
        "teach"
      ],
      "hints": [
        "m",
        "r",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "g",
        "h",
        "i",
        "o",
        "o",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "these",
        "trust",
        "essay",
        "today"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "h",
        "o",
        "r",
        "s",
        "s",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "panel",
        "plate",
        "legal",
        "equal"
      ],
      "hints": [
        "p",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "n",
        "q",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "stare",
        "speak",
        "elite",
        "knife"
      ],
      "hints": [
        "s",
        "e",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "i",
        "i",
        "l",
        "n",
        "p",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "shape",
        "sweet",
        "eager",
        "their"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "adapt",
        "album",
        "trial",
        "metal"
      ],
      "hints": [
        "a",
        "t",
        "m",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "i",
        "l",
        "p",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "quote",
        "quick",
        "elite",
        "knife"
      ],
      "hints": [
        "q",
        "e",
        "k",
        "e"
      ],
      "letters": [
        "c",
        "f",
        "i",
        "i",
        "i",
        "l",
        "n",
        "o",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "plane",
        "pride",
        "enemy",
        "entry"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "l",
        "m",
        "n",
        "n",
        "n",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "close",
        "chase",
        "elect",
        "event"
      ],
      "hints": [
        "c",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "h",
        "l",
        "l",
        "n",
        "o",
        "s",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "agent",
        "alter",
        "taste",
        "range"
      ],
      "hints": [
        "a",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "g",
        "l",
        "n",
        "n",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "laugh",
        "labor",
        "heart",
        "react"
      ],
      "hints": [
        "l",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "g",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "reach",
        "raise",
        "heavy",
        "early"
      ],
      "hints": [
        "r",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "table",
        "taste",
        "enjoy",
        "entry"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "j",
        "l",
        "n",
        "n",
        "o",
        "r",
        "s",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "brush",
        "board",
        "honey",
        "dirty"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "ratio",
        "radio",
        "owner",
        "other"
      ],
      "hints": [
        "r",
        "o",
        "o",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "h",
        "i",
        "i",
        "n",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "white",
        "worth",
        "elite",
        "horse"
      ],
      "hints": [
        "w",
        "e",
        "h",
        "e"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "their",
        "truth",
        "ready",
        "happy"
      ],
      "hints": [
        "t",
        "r",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "h",
        "i",
        "p",
        "p",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "range",
        "react",
        "eager",
        "tower"
      ],
      "hints": [
        "r",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "g",
        "n",
        "o",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "naked",
        "newly",
        "depth",
        "youth"
      ],
      "hints": [
        "n",
        "d",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "k",
        "l",
        "o",
        "p",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "crime",
        "claim",
        "every",
        "marry"
      ],
      "hints": [
        "c",
        "e",
        "m",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "m",
        "r",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "never",
        "noise",
        "refer",
        "enter"
      ],
      "hints": [
        "n",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "n",
        "o",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "board",
        "blade",
        "drive",
        "elite"
      ],
      "hints": [
        "b",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "trial",
        "title",
        "laugh",
        "earth"
      ],
      "hints": [
        "t",
        "l",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "g",
        "i",
        "i",
        "l",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "floor",
        "fresh",
        "ready",
        "honey"
      ],
      "hints": [
        "f",
        "r",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "e",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "since",
        "smoke",
        "enemy",
        "essay"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "k",
        "m",
        "m",
        "n",
        "n",
        "o",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "tough",
        "terms",
        "humor",
        "super"
      ],
      "hints": [
        "t",
        "h",
        "s",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "m",
        "m",
        "o",
        "o",
        "p",
        "r",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "major",
        "mount",
        "round",
        "tired"
      ],
      "hints": [
        "m",
        "r",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "j",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "lover",
        "leave",
        "range",
        "elite"
      ],
      "hints": [
        "l",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "o",
        "t",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "issue",
        "image",
        "essay",
        "every"
      ],
      "hints": [
        "i",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "m",
        "r",
        "s",
        "s",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "first",
        "frame",
        "taste",
        "elite"
      ],
      "hints": [
        "f",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "i",
        "l",
        "m",
        "r",
        "r",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "print",
        "point",
        "these",
        "trace"
      ],
      "hints": [
        "p",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "reply",
        "round",
        "youth",
        "depth"
      ],
      "hints": [
        "r",
        "y",
        "d",
        "h"
      ],
      "letters": [
        "e",
        "e",
        "l",
        "n",
        "o",
        "o",
        "p",
        "p",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "block",
        "board",
        "knife",
        "dance"
      ],
      "hints": [
        "b",
        "k",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "f",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "aware",
        "agent",
        "event",
        "treat"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "n",
        "n",
        "r",
        "r",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "split",
        "shake",
        "trail",
        "equal"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "i",
        "k",
        "l",
        "p",
        "q",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "spend",
        "score",
        "draft",
        "event"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "f",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "shade",
        "sport",
        "earth",
        "touch"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "h",
        "o",
        "o",
        "p",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "whose",
        "which",
        "equal",
        "hotel"
      ],
      "hints": [
        "w",
        "e",
        "h",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "h",
        "i",
        "o",
        "o",
        "q",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "there",
        "train",
        "eight",
        "night"
      ],
      "hints": [
        "t",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "i",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "cycle",
        "could",
        "enemy",
        "daily"
      ],
      "hints": [
        "c",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "l",
        "m",
        "n",
        "o",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "urban",
        "until",
        "nerve",
        "leave"
      ],
      "hints": [
        "u",
        "n",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "n",
        "r",
        "r",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "metal",
        "mayor",
        "labor",
        "refer"
      ],
      "hints": [
        "m",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "f",
        "o",
        "o",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "ghost",
        "grain",
        "trust",
        "night"
      ],
      "hints": [
        "g",
        "t",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "solar",
        "sauce",
        "rough",
        "earth"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "g",
        "l",
        "o",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "fewer",
        "field",
        "react",
        "doubt"
      ],
      "hints": [
        "f",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "given",
        "grass",
        "noise",
        "stone"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "craft",
        "crime",
        "trust",
        "elect"
      ],
      "hints": [
        "c",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "i",
        "l",
        "m",
        "r",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "route",
        "rough",
        "empty",
        "happy"
      ],
      "hints": [
        "r",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "g",
        "m",
        "o",
        "o",
        "p",
        "p",
        "p",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "solid",
        "stare",
        "drama",
        "extra"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "a"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "l",
        "m",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "seize",
        "slave",
        "exact",
        "exist"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "l",
        "s",
        "v",
        "x",
        "x",
        "z"
      ]
    },
    {
      "words": [
        "usual",
        "under",
        "layer",
        "river"
      ],
      "hints": [
        "u",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "i",
        "n",
        "s",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "train",
        "teach",
        "noise",
        "house"
      ],
      "hints": [
        "t",
        "n",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "among",
        "above",
        "guest",
        "exist"
      ],
      "hints": [
        "a",
        "g",
        "e",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "i",
        "m",
        "n",
        "o",
        "o",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "found",
        "fruit",
        "draft",
        "trust"
      ],
      "hints": [
        "f",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "f",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "error",
        "essay",
        "round",
        "yield"
      ],
      "hints": [
        "e",
        "r",
        "y",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "reply",
        "river",
        "yield",
        "round"
      ],
      "hints": [
        "r",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "p",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "fault",
        "front",
        "these",
        "twice"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "solve",
        "split",
        "elite",
        "table"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "i",
        "i",
        "l",
        "l",
        "l",
        "l",
        "o",
        "p",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "grain",
        "guide",
        "night",
        "exact"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "g",
        "h",
        "i",
        "i",
        "i",
        "r",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "hello",
        "heart",
        "ocean",
        "train"
      ],
      "hints": [
        "h",
        "o",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "brush",
        "brand",
        "human",
        "dozen"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "m",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "tight",
        "topic",
        "these",
        "cable"
      ],
      "hints": [
        "t",
        "t",
        "c",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "p",
        "s"
      ]
    },
    {
      "words": [
        "sight",
        "smoke",
        "three",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "k",
        "l",
        "m",
        "o",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "fruit",
        "false",
        "theme",
        "elite"
      ],
      "hints": [
        "f",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "track",
        "there",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "until",
        "uncle",
        "level",
        "equal"
      ],
      "hints": [
        "u",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "q",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "guess",
        "grade",
        "sense",
        "elite"
      ],
      "hints": [
        "g",
        "s",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "i",
        "l",
        "n",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "photo",
        "pound",
        "ocean",
        "dozen"
      ],
      "hints": [
        "p",
        "o",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "h",
        "n",
        "o",
        "o",
        "o",
        "t",
        "u",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "frame",
        "fiber",
        "eight",
        "right"
      ],
      "hints": [
        "f",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "m",
        "r"
      ]
    },
    {
      "words": [
        "novel",
        "north",
        "learn",
        "human"
      ],
      "hints": [
        "n",
        "l",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "m",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "giant",
        "green",
        "trend",
        "naked"
      ],
      "hints": [
        "g",
        "t",
        "n",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "k",
        "n",
        "n",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "solid",
        "shape",
        "death",
        "earth"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "o",
        "p",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "touch",
        "trace",
        "heavy",
        "every"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "o",
        "r",
        "r",
        "u",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "yield",
        "yours",
        "depth",
        "south"
      ],
      "hints": [
        "y",
        "d",
        "s",
        "h"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "p",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "mouse",
        "moral",
        "elite",
        "loose"
      ],
      "hints": [
        "m",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "o",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "month",
        "model",
        "house",
        "leave"
      ],
      "hints": [
        "m",
        "h",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "n",
        "o",
        "o",
        "o",
        "s",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "error",
        "elect",
        "ready",
        "today"
      ],
      "hints": [
        "e",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "d",
        "e",
        "e",
        "l",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "nurse",
        "night",
        "early",
        "today"
      ],
      "hints": [
        "n",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "g",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "shape",
        "shore",
        "essay",
        "entry"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "h",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "large",
        "loose",
        "essay",
        "enjoy"
      ],
      "hints": [
        "l",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "j",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "error",
        "earth",
        "river",
        "honor"
      ],
      "hints": [
        "e",
        "r",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "story",
        "staff",
        "yield",
        "found"
      ],
      "hints": [
        "s",
        "y",
        "f",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "onion",
        "other",
        "night",
        "react"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "o",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "panel",
        "press",
        "local",
        "steel"
      ],
      "hints": [
        "p",
        "l",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "n",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "clean",
        "could",
        "noise",
        "drive"
      ],
      "hints": [
        "c",
        "n",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "lunch",
        "label",
        "humor",
        "lower"
      ],
      "hints": [
        "l",
        "h",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "e",
        "m",
        "n",
        "o",
        "o",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "swear",
        "scale",
        "route",
        "elite"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "o",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "fence",
        "force",
        "empty",
        "early"
      ],
      "hints": [
        "f",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "l",
        "m",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "stuff",
        "shall",
        "fence",
        "loose"
      ],
      "hints": [
        "s",
        "f",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "l",
        "n",
        "o",
        "o",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "power",
        "pound",
        "rough",
        "depth"
      ],
      "hints": [
        "p",
        "r",
        "d",
        "h"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "n",
        "o",
        "o",
        "o",
        "p",
        "t",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "worth",
        "where",
        "hotel",
        "equal"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "o",
        "o",
        "q",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "stock",
        "small",
        "knife",
        "leave"
      ],
      "hints": [
        "s",
        "k",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "f",
        "i",
        "l",
        "m",
        "n",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "stone",
        "stair",
        "elect",
        "right"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "death",
        "dress",
        "heart",
        "shoot"
      ],
      "hints": [
        "d",
        "h",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "h",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "vital",
        "voter",
        "lover",
        "refer"
      ],
      "hints": [
        "v",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "o",
        "o",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "speak",
        "style",
        "knife",
        "elite"
      ],
      "hints": [
        "s",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "i",
        "i",
        "l",
        "l",
        "n",
        "p",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "dream",
        "depth",
        "mount",
        "habit"
      ],
      "hints": [
        "d",
        "m",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "n",
        "o",
        "p",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "rural",
        "route",
        "level",
        "equal"
      ],
      "hints": [
        "r",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "o",
        "q",
        "r",
        "t",
        "u",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "imply",
        "inner",
        "yield",
        "rapid"
      ],
      "hints": [
        "i",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "m",
        "n",
        "n",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "youth",
        "yours",
        "hotel",
        "small"
      ],
      "hints": [
        "y",
        "h",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "e",
        "l",
        "m",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "maybe",
        "motor",
        "early",
        "ready"
      ],
      "hints": [
        "m",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "l",
        "o",
        "o",
        "r",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "drink",
        "draft",
        "knock",
        "trick"
      ],
      "hints": [
        "d",
        "k",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "f",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "found",
        "force",
        "dance",
        "elite"
      ],
      "hints": [
        "f",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "tough",
        "trust",
        "honor",
        "their"
      ],
      "hints": [
        "t",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "joint",
        "judge",
        "tight",
        "eight"
      ],
      "hints": [
        "j",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "d",
        "g",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "floor",
        "front",
        "reply",
        "today"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "l",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "shout",
        "salad",
        "trace",
        "drive"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "essay",
        "eight",
        "youth",
        "tough"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "g",
        "g",
        "h",
        "i",
        "o",
        "o",
        "s",
        "s",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "phone",
        "panel",
        "eager",
        "layer"
      ],
      "hints": [
        "p",
        "e",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "n",
        "n",
        "o",
        "y"
      ]
    },
    {
      "words": [
        "shift",
        "swing",
        "title",
        "grade"
      ],
      "hints": [
        "s",
        "t",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "f",
        "h",
        "i",
        "i",
        "i",
        "l",
        "n",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "thank",
        "trace",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "porch",
        "proud",
        "happy",
        "dirty"
      ],
      "hints": [
        "p",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "o",
        "o",
        "p",
        "p",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "reach",
        "route",
        "honey",
        "early"
      ],
      "hints": [
        "r",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "stair",
        "shake",
        "react",
        "event"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "i",
        "k",
        "n",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "cabin",
        "clean",
        "noise",
        "nurse"
      ],
      "hints": [
        "c",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "i",
        "i",
        "l",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "ratio",
        "refer",
        "offer",
        "river"
      ],
      "hints": [
        "r",
        "o",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "f",
        "i",
        "i",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "throw",
        "terms",
        "white",
        "shade"
      ],
      "hints": [
        "t",
        "w",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "h",
        "h",
        "h",
        "i",
        "m",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "abuse",
        "agent",
        "elect",
        "trust"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "b",
        "c",
        "e",
        "e",
        "g",
        "l",
        "n",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "honor",
        "hello",
        "refer",
        "owner"
      ],
      "hints": [
        "h",
        "r",
        "o",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "f",
        "l",
        "l",
        "n",
        "n",
        "o",
        "o",
        "w"
      ]
    },
    {
      "words": [
        "might",
        "model",
        "table",
        "leave"
      ],
      "hints": [
        "m",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "craft",
        "court",
        "total",
        "trial"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "f",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "false",
        "forth",
        "entry",
        "happy"
      ],
      "hints": [
        "f",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "l",
        "n",
        "o",
        "p",
        "p",
        "r",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "grant",
        "grave",
        "trust",
        "elect"
      ],
      "hints": [
        "g",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "n",
        "r",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "craft",
        "carry",
        "trend",
        "yield"
      ],
      "hints": [
        "c",
        "t",
        "y",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "glove",
        "given",
        "enemy",
        "newly"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "v",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "award",
        "alter",
        "dance",
        "raise"
      ],
      "hints": [
        "a",
        "d",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "n",
        "r",
        "s",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "hello",
        "heart",
        "occur",
        "their"
      ],
      "hints": [
        "h",
        "o",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "union",
        "under",
        "nerve",
        "route"
      ],
      "hints": [
        "u",
        "n",
        "r",
        "e"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "agree",
        "alter",
        "elite",
        "route"
      ],
      "hints": [
        "a",
        "e",
        "r",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "i",
        "l",
        "l",
        "o",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "enjoy",
        "eight",
        "yield",
        "trend"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "j",
        "l",
        "n",
        "n",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "phone",
        "prior",
        "eight",
        "right"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "g",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "shall",
        "speed",
        "limit",
        "draft"
      ],
      "hints": [
        "s",
        "l",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "m",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "staff",
        "seven",
        "fight",
        "night"
      ],
      "hints": [
        "s",
        "f",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "habit",
        "honor",
        "today",
        "reply"
      ],
      "hints": [
        "h",
        "t",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "p"
      ]
    },
    {
      "words": [
        "maybe",
        "month",
        "every",
        "happy"
      ],
      "hints": [
        "m",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "n",
        "o",
        "p",
        "p",
        "r",
        "t",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "found",
        "first",
        "dress",
        "terms"
      ],
      "hints": [
        "f",
        "d",
        "t",
        "s"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "m",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "alive",
        "about",
        "elite",
        "theme"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "o",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "aside",
        "album",
        "error",
        "maker"
      ],
      "hints": [
        "a",
        "e",
        "m",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "e",
        "i",
        "k",
        "l",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "their",
        "theme",
        "reach",
        "earth"
      ],
      "hints": [
        "t",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "h",
        "h",
        "i",
        "m",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "ought",
        "owner",
        "teach",
        "rough"
      ],
      "hints": [
        "o",
        "t",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "n",
        "o",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "small",
        "score",
        "leave",
        "elite"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "m",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "split",
        "small",
        "treat",
        "least"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "p",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "fence",
        "fruit",
        "every",
        "truly"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "since",
        "smile",
        "event",
        "exist"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "m",
        "n",
        "n",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "shape",
        "smart",
        "entry",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "l",
        "m",
        "n",
        "p",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "short",
        "split",
        "treat",
        "trust"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "l",
        "o",
        "p",
        "r",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "civil",
        "could",
        "limit",
        "doubt"
      ],
      "hints": [
        "c",
        "l",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "i",
        "i",
        "i",
        "i",
        "l",
        "m",
        "o",
        "o",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "could",
        "coach",
        "drive",
        "house"
      ],
      "hints": [
        "c",
        "d",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "chair",
        "cycle",
        "raise",
        "elite"
      ],
      "hints": [
        "c",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "i",
        "i",
        "i",
        "l",
        "l",
        "s",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "moral",
        "metal",
        "lemon",
        "learn"
      ],
      "hints": [
        "m",
        "l",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "m",
        "o",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "reach",
        "rough",
        "happy",
        "honey"
      ],
      "hints": [
        "r",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "n",
        "o",
        "o",
        "p",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "slide",
        "south",
        "entry",
        "heavy"
      ],
      "hints": [
        "s",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "terms",
        "trick",
        "since",
        "knife"
      ],
      "hints": [
        "t",
        "s",
        "k",
        "e"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "f",
        "i",
        "i",
        "i",
        "m",
        "n",
        "n",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "juice",
        "joint",
        "every",
        "today"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shirt",
        "shoot",
        "three",
        "tribe"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "h",
        "h",
        "h",
        "i",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "label",
        "layer",
        "legal",
        "rural"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "r",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "house",
        "humor",
        "every",
        "reply"
      ],
      "hints": [
        "h",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "l",
        "m",
        "o",
        "o",
        "p",
        "r",
        "s",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "write",
        "watch",
        "exist",
        "heart"
      ],
      "hints": [
        "w",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "r",
        "r",
        "s",
        "t",
        "t",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "cycle",
        "cabin",
        "event",
        "night"
      ],
      "hints": [
        "c",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "alter",
        "anger",
        "refer",
        "river"
      ],
      "hints": [
        "a",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "g",
        "i",
        "l",
        "n",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "dream",
        "dance",
        "month",
        "earth"
      ],
      "hints": [
        "d",
        "m",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "chain",
        "cheap",
        "naked",
        "proud"
      ],
      "hints": [
        "c",
        "n",
        "p",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "k",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "owner",
        "occur",
        "range",
        "raise"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "g",
        "i",
        "n",
        "n",
        "s",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "brand",
        "buyer",
        "draft",
        "react"
      ],
      "hints": [
        "b",
        "d",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "f",
        "n",
        "r",
        "r",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "spend",
        "sound",
        "dance",
        "drive"
      ],
      "hints": [
        "s",
        "d",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "n",
        "n",
        "n",
        "o",
        "p",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "those",
        "tribe",
        "eager",
        "error"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "wrong",
        "water",
        "grave",
        "range"
      ],
      "hints": [
        "w",
        "g",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "arise",
        "aside",
        "enter",
        "eager"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "i",
        "i",
        "n",
        "r",
        "s",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "coast",
        "couch",
        "tribe",
        "house"
      ],
      "hints": [
        "c",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "i",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "grass",
        "nerve",
        "score"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "stone",
        "scale",
        "exist",
        "event"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "s",
        "t",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "staff",
        "split",
        "fault",
        "treat"
      ],
      "hints": [
        "s",
        "f",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "i",
        "l",
        "l",
        "p",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "piano",
        "patch",
        "order",
        "honor"
      ],
      "hints": [
        "p",
        "o",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "quite",
        "quiet",
        "entry",
        "truly"
      ],
      "hints": [
        "q",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "water",
        "wound",
        "react",
        "doubt"
      ],
      "hints": [
        "w",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "n",
        "o",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "voter",
        "virus",
        "ready",
        "sorry"
      ],
      "hints": [
        "v",
        "r",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "cycle",
        "catch",
        "elite",
        "horse"
      ],
      "hints": [
        "c",
        "e",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "i",
        "l",
        "l",
        "o",
        "r",
        "s",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "tooth",
        "trail",
        "happy",
        "lucky"
      ],
      "hints": [
        "t",
        "h",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "k",
        "o",
        "o",
        "p",
        "p",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "stake",
        "small",
        "elect",
        "light"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "k",
        "l",
        "l",
        "m",
        "t"
      ]
    },
    {
      "words": [
        "frame",
        "floor",
        "exist",
        "right"
      ],
      "hints": [
        "f",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "cable",
        "chain",
        "exist",
        "night"
      ],
      "hints": [
        "c",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "lower",
        "leave",
        "react",
        "eight"
      ],
      "hints": [
        "l",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "fully",
        "fence",
        "youth",
        "earth"
      ],
      "hints": [
        "f",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "three",
        "tower",
        "error",
        "refer"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "f",
        "h",
        "o",
        "o",
        "r",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "couch",
        "claim",
        "hotel",
        "model"
      ],
      "hints": [
        "c",
        "h",
        "m",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "guide",
        "grass",
        "equal",
        "small"
      ],
      "hints": [
        "g",
        "e",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "i",
        "l",
        "m",
        "q",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "teach",
        "troop",
        "house",
        "plane"
      ],
      "hints": [
        "t",
        "h",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "shelf",
        "small",
        "fifty",
        "lucky"
      ],
      "hints": [
        "s",
        "f",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "k",
        "l",
        "l",
        "m",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "seize",
        "stage",
        "every",
        "enemy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "i",
        "m",
        "n",
        "r",
        "t",
        "v",
        "z"
      ]
    },
    {
      "words": [
        "group",
        "grand",
        "patch",
        "depth"
      ],
      "hints": [
        "g",
        "p",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "earth",
        "extra",
        "horse",
        "above"
      ],
      "hints": [
        "e",
        "h",
        "a",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "clock",
        "count",
        "knock",
        "track"
      ],
      "hints": [
        "c",
        "k",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "l",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "ahead",
        "again",
        "draft",
        "night"
      ],
      "hints": [
        "a",
        "d",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "allow",
        "asset",
        "whose",
        "taste"
      ],
      "hints": [
        "a",
        "w",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "l",
        "l",
        "o",
        "o",
        "s",
        "s",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "skill",
        "stand",
        "large",
        "drive"
      ],
      "hints": [
        "s",
        "l",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "i",
        "i",
        "k",
        "l",
        "n",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "style",
        "shirt",
        "equal",
        "trail"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "l",
        "q",
        "r",
        "r",
        "t",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "where",
        "write",
        "eight",
        "exact"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "r",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "month",
        "minor",
        "humor",
        "river"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "i",
        "i",
        "m",
        "n",
        "n",
        "o",
        "o",
        "o",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "quite",
        "quiet",
        "event",
        "tight"
      ],
      "hints": [
        "q",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "n",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "paint",
        "phase",
        "taste",
        "elite"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "i",
        "l",
        "n",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "water",
        "woman",
        "right",
        "night"
      ],
      "hints": [
        "w",
        "r",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "m",
        "o",
        "t"
      ]
    },
    {
      "words": [
        "basis",
        "bring",
        "start",
        "ghost"
      ],
      "hints": [
        "b",
        "s",
        "g",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "patch",
        "plane",
        "horse",
        "elite"
      ],
      "hints": [
        "p",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "world",
        "worry",
        "depth",
        "youth"
      ],
      "hints": [
        "w",
        "d",
        "y",
        "h"
      ],
      "letters": [
        "e",
        "l",
        "o",
        "o",
        "o",
        "p",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "avoid",
        "alive",
        "dance",
        "elite"
      ],
      "hints": [
        "a",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "tight",
        "there",
        "table",
        "elite"
      ],
      "hints": [
        "t",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "thing",
        "these",
        "guide",
        "elite"
      ],
      "hints": [
        "t",
        "g",
        "e",
        "e"
      ],
      "letters": [
        "d",
        "e",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "n",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "hotel",
        "honor",
        "lucky",
        "reply"
      ],
      "hints": [
        "h",
        "l",
        "r",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "k",
        "l",
        "n",
        "o",
        "o",
        "o",
        "p",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "being",
        "blind",
        "grave",
        "drive"
      ],
      "hints": [
        "b",
        "g",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "brain",
        "break",
        "noise",
        "knife"
      ],
      "hints": [
        "b",
        "n",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "i",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "pilot",
        "place",
        "tower",
        "error"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "close",
        "crash",
        "every",
        "heavy"
      ],
      "hints": [
        "c",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "where",
        "world",
        "empty",
        "dirty"
      ],
      "hints": [
        "w",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "e",
        "h",
        "i",
        "l",
        "m",
        "o",
        "p",
        "r",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "rural",
        "right",
        "light",
        "trust"
      ],
      "hints": [
        "r",
        "l",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "favor",
        "funny",
        "rapid",
        "yield"
      ],
      "hints": [
        "f",
        "r",
        "y",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "p",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "field",
        "fully",
        "dress",
        "yours"
      ],
      "hints": [
        "f",
        "d",
        "y",
        "s"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "l",
        "l",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "trick",
        "throw",
        "knife",
        "whole"
      ],
      "hints": [
        "t",
        "k",
        "w",
        "e"
      ],
      "letters": [
        "c",
        "f",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "aware",
        "award",
        "elite",
        "drive"
      ],
      "hints": [
        "a",
        "e",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "i",
        "l",
        "r",
        "r",
        "r",
        "t",
        "v",
        "w",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "yield",
        "yours",
        "daily",
        "study"
      ],
      "hints": [
        "y",
        "d",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "sweep",
        "smoke",
        "pride",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "i",
        "i",
        "k",
        "l",
        "m",
        "o",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "solid",
        "solve",
        "death",
        "earth"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "guide",
        "given",
        "earth",
        "north"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "i",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "plant",
        "party",
        "teach",
        "youth"
      ],
      "hints": [
        "p",
        "t",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "start",
        "scale",
        "treat",
        "event"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "n",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "angry",
        "apart",
        "young",
        "thing"
      ],
      "hints": [
        "a",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "n",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "board",
        "bread",
        "dance",
        "drive"
      ],
      "hints": [
        "b",
        "d",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "order",
        "often",
        "rough",
        "north"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "h"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "f",
        "g",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "salad",
        "smart",
        "dozen",
        "train"
      ],
      "hints": [
        "s",
        "d",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "e",
        "i",
        "l",
        "m",
        "o",
        "r",
        "r",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "union",
        "under",
        "nurse",
        "raise"
      ],
      "hints": [
        "u",
        "n",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "shall",
        "shift",
        "loose",
        "those"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "f",
        "h",
        "h",
        "h",
        "i",
        "l",
        "o",
        "o",
        "o",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "grass",
        "grain",
        "shore",
        "nerve"
      ],
      "hints": [
        "g",
        "s",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "world",
        "watch",
        "doubt",
        "habit"
      ],
      "hints": [
        "w",
        "d",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "b",
        "c",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "rural",
        "river",
        "large",
        "raise"
      ],
      "hints": [
        "r",
        "l",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "i",
        "i",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "group",
        "glass",
        "press",
        "sales"
      ],
      "hints": [
        "g",
        "p",
        "s",
        "s"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "l",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "chase",
        "claim",
        "enjoy",
        "marry"
      ],
      "hints": [
        "c",
        "e",
        "m",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "j",
        "l",
        "n",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "cycle",
        "child",
        "every",
        "dirty"
      ],
      "hints": [
        "c",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r",
        "r",
        "t",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "again",
        "anger",
        "newly",
        "reply"
      ],
      "hints": [
        "a",
        "n",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "g",
        "i",
        "l",
        "l",
        "n",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "after",
        "arise",
        "rural",
        "equal"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "i",
        "q",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "green",
        "glass",
        "naked",
        "speed"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "k",
        "l",
        "p",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "panel",
        "patch",
        "loose",
        "horse"
      ],
      "hints": [
        "p",
        "l",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "after",
        "argue",
        "route",
        "elite"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "f",
        "g",
        "i",
        "l",
        "o",
        "r",
        "t",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "stock",
        "shake",
        "knife",
        "elite"
      ],
      "hints": [
        "s",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "h",
        "i",
        "i",
        "k",
        "l",
        "n",
        "o",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "score",
        "split",
        "equal",
        "trial"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "i",
        "l",
        "o",
        "p",
        "q",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "juice",
        "joint",
        "equal",
        "trail"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "i",
        "i",
        "n",
        "o",
        "q",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "front",
        "fight",
        "taste",
        "title"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "small",
        "seize",
        "light",
        "exact"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "m",
        "x",
        "z"
      ]
    },
    {
      "words": [
        "train",
        "these",
        "noise",
        "elite"
      ],
      "hints": [
        "t",
        "n",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "claim",
        "chart",
        "marry",
        "truly"
      ],
      "hints": [
        "c",
        "m",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "l",
        "l",
        "r",
        "r",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "count",
        "court",
        "trial",
        "trail"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "lower",
        "leave",
        "range",
        "elite"
      ],
      "hints": [
        "l",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "o",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "solid",
        "state",
        "drama",
        "extra"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "a"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "l",
        "m",
        "o",
        "r",
        "r",
        "t",
        "t",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "focus",
        "floor",
        "scope",
        "route"
      ],
      "hints": [
        "f",
        "s",
        "r",
        "e"
      ],
      "letters": [
        "c",
        "c",
        "l",
        "o",
        "o",
        "o",
        "o",
        "o",
        "p",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "react",
        "refer",
        "trail",
        "rural"
      ],
      "hints": [
        "r",
        "t",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "i",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "phone",
        "piece",
        "eager",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "n",
        "o",
        "t"
      ]
    },
    {
      "words": [
        "reply",
        "react",
        "youth",
        "touch"
      ],
      "hints": [
        "r",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "l",
        "o",
        "o",
        "p",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "theme",
        "trial",
        "eight",
        "limit"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "i",
        "m",
        "m",
        "r"
      ]
    },
    {
      "words": [
        "naked",
        "novel",
        "dozen",
        "lemon"
      ],
      "hints": [
        "n",
        "d",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "k",
        "m",
        "o",
        "o",
        "o",
        "v",
        "z"
      ]
    },
    {
      "words": [
        "third",
        "trade",
        "depth",
        "earth"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "h",
        "i",
        "p",
        "r",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "serve",
        "skill",
        "early",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "k",
        "k",
        "l",
        "l",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "large",
        "lover",
        "empty",
        "reply"
      ],
      "hints": [
        "l",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "l",
        "m",
        "o",
        "p",
        "p",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "prime",
        "photo",
        "eager",
        "order"
      ],
      "hints": [
        "p",
        "e",
        "o",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "m",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "birth",
        "blind",
        "happy",
        "delay"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "n",
        "p",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "south",
        "solve",
        "honor",
        "error"
      ],
      "hints": [
        "s",
        "h",
        "e",
        "r"
      ],
      "letters": [
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "three",
        "terms",
        "enter",
        "sugar"
      ],
      "hints": [
        "t",
        "e",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "m",
        "n",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "actor",
        "above",
        "rough",
        "earth"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "g",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "vital",
        "refer",
        "labor"
      ],
      "hints": [
        "v",
        "r",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "f",
        "i",
        "o",
        "o",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "world",
        "would",
        "dirty",
        "delay"
      ],
      "hints": [
        "w",
        "d",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "climb",
        "check",
        "blade",
        "knife"
      ],
      "hints": [
        "c",
        "b",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "n"
      ]
    }
  ],
  [
    {
      "words": [
        "dance",
        "depth",
        "enter",
        "humor"
      ],
      "hints": [
        "d",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "m",
        "n",
        "n",
        "o",
        "p",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "fight",
        "final",
        "tribe",
        "loose"
      ],
      "hints": [
        "f",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "g",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "slave",
        "solar",
        "eager",
        "refer"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "l",
        "l",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "doubt",
        "drink",
        "trace",
        "knife"
      ],
      "hints": [
        "d",
        "t",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "f",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "pride",
        "prime",
        "enter",
        "eager"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "i",
        "i",
        "m",
        "n",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "steel",
        "shirt",
        "legal",
        "trail"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "fight",
        "faith",
        "truly",
        "heavy"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shift",
        "swear",
        "total",
        "rural"
      ],
      "hints": [
        "s",
        "t",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "h",
        "i",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "maker",
        "minor",
        "round",
        "rapid"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "k",
        "n",
        "n",
        "o",
        "o",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "trust",
        "trail",
        "table",
        "leave"
      ],
      "hints": [
        "t",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "i",
        "l",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "clock",
        "cloud",
        "knife",
        "drive"
      ],
      "hints": [
        "c",
        "k",
        "d",
        "e"
      ],
      "letters": [
        "c",
        "f",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "label",
        "leave",
        "loose",
        "elite"
      ],
      "hints": [
        "l",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "couch",
        "crash",
        "happy",
        "honey"
      ],
      "hints": [
        "c",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "n",
        "o",
        "o",
        "p",
        "p",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "while",
        "works",
        "every",
        "sorry"
      ],
      "hints": [
        "w",
        "e",
        "s",
        "y"
      ],
      "letters": [
        "e",
        "h",
        "i",
        "k",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "shoot",
        "style",
        "tower",
        "error"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "r"
      ],
      "letters": [
        "e",
        "h",
        "l",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "w",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "arise",
        "agent",
        "eager",
        "tower"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "g",
        "i",
        "n",
        "o",
        "r",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "brush",
        "basic",
        "horse",
        "crime"
      ],
      "hints": [
        "b",
        "h",
        "c",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "m",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "faith",
        "favor",
        "heart",
        "right"
      ],
      "hints": [
        "f",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "teach",
        "throw",
        "horse",
        "write"
      ],
      "hints": [
        "t",
        "h",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "apple",
        "about",
        "empty",
        "today"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "l",
        "m",
        "o",
        "o",
        "p",
        "p",
        "p",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "brief",
        "badly",
        "faith",
        "youth"
      ],
      "hints": [
        "b",
        "f",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "i",
        "i",
        "l",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "above",
        "again",
        "error",
        "never"
      ],
      "hints": [
        "a",
        "e",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "g",
        "i",
        "o",
        "o",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "young",
        "yield",
        "grade",
        "dance"
      ],
      "hints": [
        "y",
        "g",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "faith",
        "fresh",
        "house",
        "horse"
      ],
      "hints": [
        "f",
        "h",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "above",
        "aware",
        "every",
        "enjoy"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "j",
        "n",
        "o",
        "o",
        "r",
        "r",
        "v",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "honor",
        "house",
        "rural",
        "equal"
      ],
      "hints": [
        "h",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "n",
        "o",
        "o",
        "o",
        "q",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "shape",
        "staff",
        "every",
        "funny"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "h",
        "n",
        "n",
        "p",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "alone",
        "ahead",
        "enjoy",
        "delay"
      ],
      "hints": [
        "a",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "j",
        "l",
        "l",
        "n",
        "n",
        "o",
        "o"
      ]
    },
    {
      "words": [
        "swear",
        "smart",
        "rough",
        "teach"
      ],
      "hints": [
        "s",
        "r",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "m",
        "o",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "uncle",
        "union",
        "exact",
        "night"
      ],
      "hints": [
        "u",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "g",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "anger",
        "allow",
        "route",
        "waste"
      ],
      "hints": [
        "a",
        "r",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "l",
        "l",
        "n",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "stock",
        "speed",
        "knock",
        "drink"
      ],
      "hints": [
        "s",
        "k",
        "d",
        "k"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "stone",
        "spend",
        "essay",
        "daily"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "p",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "porch",
        "paint",
        "honor",
        "tower"
      ],
      "hints": [
        "p",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "later",
        "light",
        "route",
        "there"
      ],
      "hints": [
        "l",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "blind",
        "bench",
        "doubt",
        "habit"
      ],
      "hints": [
        "b",
        "d",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "b",
        "c",
        "e",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "speed",
        "shelf",
        "dress",
        "focus"
      ],
      "hints": [
        "s",
        "d",
        "f",
        "s"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "e",
        "h",
        "l",
        "o",
        "p",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "focus",
        "float",
        "swear",
        "tower"
      ],
      "hints": [
        "f",
        "s",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "o",
        "o",
        "o",
        "u",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "sharp",
        "slide",
        "place",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "h",
        "i",
        "i",
        "l",
        "l",
        "l",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "porch",
        "proud",
        "habit",
        "draft"
      ],
      "hints": [
        "p",
        "h",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "f",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "ahead",
        "about",
        "drink",
        "trick"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "proof",
        "paper",
        "final",
        "rural"
      ],
      "hints": [
        "p",
        "f",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "favor",
        "float",
        "range",
        "there"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "h",
        "l",
        "n",
        "o",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "uncle",
        "upper",
        "every",
        "reply"
      ],
      "hints": [
        "u",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "l",
        "l",
        "n",
        "p",
        "p",
        "p",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "staff",
        "shade",
        "fault",
        "exist"
      ],
      "hints": [
        "s",
        "f",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "f",
        "h",
        "i",
        "l",
        "s",
        "t",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "ready",
        "river",
        "yield",
        "round"
      ],
      "hints": [
        "r",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shore",
        "still",
        "enter",
        "later"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "minor",
        "mouse",
        "rural",
        "equal"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "n",
        "o",
        "o",
        "q",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "below",
        "begin",
        "wheel",
        "novel"
      ],
      "hints": [
        "b",
        "w",
        "n",
        "l"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "ocean",
        "order",
        "novel",
        "rural"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "o",
        "r",
        "r",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "three",
        "twice",
        "early",
        "essay"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "r",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "prime",
        "press",
        "enter",
        "stair"
      ],
      "hints": [
        "p",
        "e",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "m",
        "n",
        "r",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "works",
        "worth",
        "slide",
        "house"
      ],
      "hints": [
        "w",
        "s",
        "h",
        "e"
      ],
      "letters": [
        "d",
        "i",
        "k",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "match",
        "might",
        "human",
        "train"
      ],
      "hints": [
        "m",
        "h",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "g",
        "h",
        "i",
        "i",
        "m",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "eager",
        "eight",
        "rural",
        "total"
      ],
      "hints": [
        "e",
        "r",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "g",
        "h",
        "i",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "movie",
        "minor",
        "elite",
        "range"
      ],
      "hints": [
        "m",
        "e",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "g",
        "i",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "rural",
        "raise",
        "local",
        "equal"
      ],
      "hints": [
        "r",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "i",
        "o",
        "q",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "guide",
        "grain",
        "elite",
        "nurse"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "i",
        "i",
        "l",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "sharp",
        "stare",
        "prove",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "glove",
        "giant",
        "event",
        "treat"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "check",
        "child",
        "knock",
        "drink"
      ],
      "hints": [
        "c",
        "k",
        "d",
        "k"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "loose",
        "large",
        "exist",
        "elect"
      ],
      "hints": [
        "l",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "trend",
        "thick",
        "drink",
        "knock"
      ],
      "hints": [
        "t",
        "d",
        "k",
        "k"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "h",
        "i",
        "i",
        "n",
        "n",
        "n",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "these",
        "trail",
        "early",
        "lucky"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "k",
        "l",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "pause",
        "porch",
        "exist",
        "habit"
      ],
      "hints": [
        "p",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "i",
        "i",
        "o",
        "r",
        "s",
        "s",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "leave",
        "least",
        "every",
        "today"
      ],
      "hints": [
        "l",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "o",
        "r",
        "s",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "apart",
        "arise",
        "twice",
        "elite"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "i",
        "i",
        "l",
        "p",
        "r",
        "r",
        "s",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "crash",
        "coach",
        "heart",
        "habit"
      ],
      "hints": [
        "c",
        "h",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "their",
        "there",
        "route",
        "elite"
      ],
      "hints": [
        "t",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "topic",
        "these",
        "clear",
        "enter"
      ],
      "hints": [
        "t",
        "c",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "p",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "north",
        "never",
        "honey",
        "reply"
      ],
      "hints": [
        "n",
        "h",
        "r",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "l",
        "n",
        "o",
        "o",
        "p",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "meter",
        "maker",
        "rough",
        "reach"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "g",
        "k",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "upper",
        "usual",
        "rural",
        "legal"
      ],
      "hints": [
        "u",
        "r",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "p",
        "p",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "newly",
        "noise",
        "youth",
        "earth"
      ],
      "hints": [
        "n",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "these",
        "taste",
        "eight",
        "exact"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "s",
        "s",
        "t",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "quite",
        "quiet",
        "enter",
        "their"
      ],
      "hints": [
        "q",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "h",
        "i",
        "i",
        "i",
        "n",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "pilot",
        "phase",
        "trade",
        "elite"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "h",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "cross",
        "crash",
        "sweet",
        "habit"
      ],
      "hints": [
        "c",
        "s",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "brief",
        "brown",
        "flesh",
        "north"
      ],
      "hints": [
        "b",
        "f",
        "n",
        "h"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "super",
        "shrug",
        "range",
        "grade"
      ],
      "hints": [
        "s",
        "r",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "h",
        "n",
        "p",
        "r",
        "r",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "dozen",
        "draft",
        "night",
        "tight"
      ],
      "hints": [
        "d",
        "n",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "r",
        "z"
      ]
    },
    {
      "words": [
        "worry",
        "whole",
        "youth",
        "earth"
      ],
      "hints": [
        "w",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "h",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "carry",
        "craft",
        "young",
        "thing"
      ],
      "hints": [
        "c",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "world",
        "white",
        "drive",
        "elite"
      ],
      "hints": [
        "w",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "trust",
        "total",
        "table",
        "loose"
      ],
      "hints": [
        "t",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "prime",
        "photo",
        "elect",
        "ought"
      ],
      "hints": [
        "p",
        "e",
        "o",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "l",
        "m",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "scope",
        "serve",
        "essay",
        "entry"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "laugh",
        "least",
        "happy",
        "truly"
      ],
      "hints": [
        "l",
        "h",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "l",
        "p",
        "p",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "juice",
        "judge",
        "elect",
        "exist"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "c",
        "d",
        "e",
        "g",
        "i",
        "i",
        "l",
        "s",
        "u",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "prime",
        "plant",
        "elect",
        "tight"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "n",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "trail",
        "thing",
        "large",
        "grade"
      ],
      "hints": [
        "t",
        "l",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "g",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "basis",
        "birth",
        "sorry",
        "heavy"
      ],
      "hints": [
        "b",
        "s",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "third",
        "taste",
        "daily",
        "empty"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "l",
        "m",
        "p",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "truth",
        "tower",
        "honor",
        "refer"
      ],
      "hints": [
        "t",
        "h",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "f",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "shoot",
        "sweet",
        "there",
        "tribe"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "e",
        "h",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "flesh",
        "flame",
        "heavy",
        "early"
      ],
      "hints": [
        "f",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "l",
        "l",
        "l",
        "m",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "sound",
        "smell",
        "daily",
        "lucky"
      ],
      "hints": [
        "s",
        "d",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "k",
        "l",
        "l",
        "m",
        "n",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "catch",
        "close",
        "heavy",
        "essay"
      ],
      "hints": [
        "c",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "l",
        "o",
        "s",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "solve",
        "scale",
        "elect",
        "event"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "l",
        "l",
        "l",
        "n",
        "o",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "novel",
        "night",
        "limit",
        "tight"
      ],
      "hints": [
        "n",
        "l",
        "t",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "i",
        "m",
        "o",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "pride",
        "press",
        "every",
        "sorry"
      ],
      "hints": [
        "p",
        "e",
        "s",
        "y"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "i",
        "o",
        "r",
        "r",
        "r",
        "r",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "total",
        "terms",
        "labor",
        "swear"
      ],
      "hints": [
        "t",
        "l",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "m",
        "o",
        "o",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "refer",
        "rough",
        "range",
        "horse"
      ],
      "hints": [
        "r",
        "r",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "g",
        "g",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "uncle",
        "union",
        "eager",
        "never"
      ],
      "hints": [
        "u",
        "e",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "n",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "drive",
        "death",
        "entry",
        "heavy"
      ],
      "hints": [
        "d",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "n",
        "r",
        "r",
        "t",
        "t",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "title",
        "tribe",
        "exact",
        "event"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "i",
        "i",
        "l",
        "n",
        "r",
        "t",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "about",
        "adapt",
        "tough",
        "truth"
      ],
      "hints": [
        "a",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "g",
        "o",
        "o",
        "p",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "yours",
        "yield",
        "since",
        "drive"
      ],
      "hints": [
        "y",
        "s",
        "d",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "learn",
        "local",
        "novel",
        "level"
      ],
      "hints": [
        "l",
        "n",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "o",
        "o",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "phone",
        "paper",
        "exact",
        "right"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "n",
        "o",
        "p",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "scope",
        "sense",
        "enter",
        "eager"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "n",
        "n",
        "o",
        "p",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "those",
        "tower",
        "essay",
        "ready"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "h",
        "o",
        "o",
        "s",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "score",
        "shift",
        "error",
        "tower"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "f",
        "h",
        "i",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "react",
        "raise",
        "trust",
        "event"
      ],
      "hints": [
        "r",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "below",
        "badly",
        "works",
        "yours"
      ],
      "hints": [
        "b",
        "w",
        "y",
        "s"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "k",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "tribe",
        "these",
        "enter",
        "eager"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "match",
        "mayor",
        "hotel",
        "rural"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "avoid",
        "agent",
        "delay",
        "truly"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "whose",
        "white",
        "exist",
        "eight"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "i",
        "o",
        "s",
        "s",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "flesh",
        "fence",
        "heart",
        "elect"
      ],
      "hints": [
        "f",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "e",
        "l",
        "l",
        "n",
        "r",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "basis",
        "brain",
        "short",
        "night"
      ],
      "hints": [
        "b",
        "s",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "panel",
        "print",
        "least",
        "tight"
      ],
      "hints": [
        "p",
        "l",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "n",
        "n",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "final",
        "flesh",
        "leave",
        "house"
      ],
      "hints": [
        "f",
        "l",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "pitch",
        "plate",
        "heavy",
        "essay"
      ],
      "hints": [
        "p",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "s",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "shell",
        "stage",
        "loose",
        "elite"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "s",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "hotel",
        "honor",
        "level",
        "rural"
      ],
      "hints": [
        "h",
        "l",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "mount",
        "mayor",
        "table",
        "range"
      ],
      "hints": [
        "m",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "g",
        "l",
        "n",
        "n",
        "o",
        "o",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "sight",
        "split",
        "theme",
        "those"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "l",
        "m",
        "o",
        "p",
        "s"
      ]
    },
    {
      "words": [
        "scale",
        "sweep",
        "eager",
        "power"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "g",
        "l",
        "o",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "fight",
        "front",
        "trust",
        "tight"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "t"
      ],
      "letters": [
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "troop",
        "title",
        "piece",
        "elite"
      ],
      "hints": [
        "t",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "grant",
        "glass",
        "track",
        "stick"
      ],
      "hints": [
        "g",
        "t",
        "s",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "c",
        "i",
        "l",
        "n",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "tight",
        "there",
        "touch",
        "earth"
      ],
      "hints": [
        "t",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "mouse",
        "music",
        "entry",
        "crazy"
      ],
      "hints": [
        "m",
        "e",
        "c",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "thank",
        "these",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "sleep",
        "smile",
        "patch",
        "earth"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "shelf",
        "sport",
        "floor",
        "tower"
      ],
      "hints": [
        "s",
        "f",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "l",
        "l",
        "o",
        "o",
        "o",
        "o",
        "p",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "react",
        "round",
        "title",
        "dance"
      ],
      "hints": [
        "r",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "argue",
        "above",
        "eager",
        "error"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "g",
        "o",
        "o",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "stake",
        "sweet",
        "exact",
        "tight"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "k",
        "t",
        "w",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "carry",
        "chain",
        "yield",
        "naked"
      ],
      "hints": [
        "c",
        "y",
        "n",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "k",
        "l",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "those",
        "these",
        "every",
        "essay"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "o",
        "r",
        "s",
        "s",
        "s",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "photo",
        "pitch",
        "order",
        "humor"
      ],
      "hints": [
        "p",
        "o",
        "h",
        "r"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "h",
        "i",
        "m",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "chief",
        "catch",
        "favor",
        "honor"
      ],
      "hints": [
        "c",
        "f",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "value",
        "right",
        "eight"
      ],
      "hints": [
        "v",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "beach",
        "blame",
        "house",
        "elite"
      ],
      "hints": [
        "b",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "m",
        "o",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "trend",
        "third",
        "daily",
        "dirty"
      ],
      "hints": [
        "t",
        "d",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "order",
        "often",
        "reply",
        "newly"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "y"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "e",
        "e",
        "f",
        "l",
        "l",
        "p",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "phone",
        "prior",
        "error",
        "river"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "h",
        "i",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "union",
        "urban",
        "nerve",
        "noise"
      ],
      "hints": [
        "u",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "spend",
        "smart",
        "drink",
        "thank"
      ],
      "hints": [
        "s",
        "d",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "m",
        "n",
        "n",
        "n",
        "p",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "troop",
        "track",
        "prove",
        "knife"
      ],
      "hints": [
        "t",
        "p",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "argue",
        "after",
        "error",
        "refer"
      ],
      "hints": [
        "a",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "f",
        "f",
        "g",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "forth",
        "fiber",
        "honor",
        "river"
      ],
      "hints": [
        "f",
        "h",
        "r",
        "r"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "cheek",
        "chart",
        "knife",
        "taste"
      ],
      "hints": [
        "c",
        "k",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "h",
        "h",
        "i",
        "n",
        "r",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "movie",
        "maybe",
        "entry",
        "empty"
      ],
      "hints": [
        "m",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "i",
        "m",
        "n",
        "o",
        "p",
        "r",
        "t",
        "t",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "trial",
        "trail",
        "legal",
        "level"
      ],
      "hints": [
        "t",
        "l",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "i",
        "i",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "motor",
        "marry",
        "rapid",
        "yield"
      ],
      "hints": [
        "m",
        "r",
        "y",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "o",
        "o",
        "p",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "yours",
        "youth",
        "sweet",
        "heart"
      ],
      "hints": [
        "y",
        "s",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "check",
        "close",
        "knife",
        "elite"
      ],
      "hints": [
        "c",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "elite",
        "error",
        "enter",
        "refer"
      ],
      "hints": [
        "e",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "f",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "heart",
        "human",
        "tooth",
        "north"
      ],
      "hints": [
        "h",
        "t",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "m",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "crash",
        "count",
        "humor",
        "their"
      ],
      "hints": [
        "c",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "m",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "fight",
        "first",
        "these",
        "trade"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "bible",
        "blade",
        "enter",
        "eager"
      ],
      "hints": [
        "b",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "g",
        "i",
        "l",
        "l",
        "n",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "grand",
        "glove",
        "death",
        "earth"
      ],
      "hints": [
        "g",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "local",
        "laugh",
        "lower",
        "honor"
      ],
      "hints": [
        "l",
        "l",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "n",
        "o",
        "o",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "pause",
        "porch",
        "eight",
        "heart"
      ],
      "hints": [
        "p",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "close",
        "climb",
        "empty",
        "badly"
      ],
      "hints": [
        "c",
        "e",
        "b",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "l",
        "l",
        "l",
        "m",
        "m",
        "o",
        "p",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "these",
        "taste",
        "exist",
        "exact"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "s",
        "s",
        "s",
        "t",
        "x",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "photo",
        "pitch",
        "owner",
        "honor"
      ],
      "hints": [
        "p",
        "o",
        "h",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "aside",
        "apple",
        "enjoy",
        "entry"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "d",
        "i",
        "j",
        "l",
        "n",
        "n",
        "o",
        "p",
        "p",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "great",
        "glass",
        "tired",
        "spend"
      ],
      "hints": [
        "g",
        "t",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "l",
        "n",
        "p",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "abuse",
        "angle",
        "elect",
        "exact"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "c",
        "e",
        "g",
        "l",
        "l",
        "n",
        "s",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "cheap",
        "class",
        "print",
        "shift"
      ],
      "hints": [
        "c",
        "p",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "l",
        "n",
        "r",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "judge",
        "joint",
        "every",
        "today"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "d",
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "stake",
        "solar",
        "empty",
        "reply"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "k",
        "l",
        "l",
        "m",
        "o",
        "p",
        "p",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "pride",
        "plant",
        "enjoy",
        "truly"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "j",
        "l",
        "l",
        "n",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "allow",
        "about",
        "write",
        "tribe"
      ],
      "hints": [
        "a",
        "w",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "b",
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shall",
        "start",
        "light",
        "tight"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "g",
        "h",
        "h",
        "h",
        "i",
        "i",
        "l",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "begin",
        "basic",
        "north",
        "crash"
      ],
      "hints": [
        "b",
        "n",
        "c",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "elect",
        "eager",
        "these",
        "raise"
      ],
      "hints": [
        "e",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "ahead",
        "admit",
        "delay",
        "truly"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "m",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "board",
        "bread",
        "death",
        "depth"
      ],
      "hints": [
        "b",
        "d",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "craft",
        "cycle",
        "truly",
        "empty"
      ],
      "hints": [
        "c",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "l",
        "l",
        "m",
        "p",
        "r",
        "r",
        "t",
        "u",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "storm",
        "stock",
        "maybe",
        "knife"
      ],
      "hints": [
        "s",
        "m",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "f",
        "i",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "class",
        "coast",
        "since",
        "there"
      ],
      "hints": [
        "c",
        "s",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "could",
        "catch",
        "dozen",
        "human"
      ],
      "hints": [
        "c",
        "d",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "m",
        "o",
        "o",
        "t",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "price",
        "power",
        "essay",
        "ready"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "i",
        "o",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "twice",
        "these",
        "empty",
        "enjoy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "j",
        "m",
        "n",
        "o",
        "p",
        "s",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "teach",
        "throw",
        "heavy",
        "worry"
      ],
      "hints": [
        "t",
        "h",
        "w",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "lover",
        "legal",
        "ready",
        "lucky"
      ],
      "hints": [
        "l",
        "r",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "g",
        "k",
        "o",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "write",
        "water",
        "early",
        "reply"
      ],
      "hints": [
        "w",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "l",
        "p",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "floor",
        "flame",
        "river",
        "error"
      ],
      "hints": [
        "f",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "l",
        "m",
        "o",
        "o",
        "o",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "cheek",
        "cross",
        "knife",
        "scope"
      ],
      "hints": [
        "c",
        "k",
        "s",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "f",
        "h",
        "i",
        "n",
        "o",
        "o",
        "p",
        "r",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "light",
        "lemon",
        "third",
        "naked"
      ],
      "hints": [
        "l",
        "t",
        "n",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "k",
        "m",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "adopt",
        "again",
        "today",
        "newly"
      ],
      "hints": [
        "a",
        "t",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "cabin",
        "couch",
        "newly",
        "honey"
      ],
      "hints": [
        "c",
        "n",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "ocean",
        "offer",
        "nurse",
        "route"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "f",
        "f",
        "o",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "trial",
        "throw",
        "learn",
        "woman"
      ],
      "hints": [
        "t",
        "l",
        "w",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "wound",
        "wrong",
        "dozen",
        "grain"
      ],
      "hints": [
        "w",
        "d",
        "g",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "shout",
        "sound",
        "taste",
        "drive"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "sheet",
        "shall",
        "these",
        "loose"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "h",
        "h",
        "l",
        "o",
        "o",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "guard",
        "guess",
        "daily",
        "story"
      ],
      "hints": [
        "g",
        "d",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "glove",
        "grass",
        "entry",
        "story"
      ],
      "hints": [
        "g",
        "e",
        "s",
        "y"
      ],
      "letters": [
        "a",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "human",
        "heart",
        "newly",
        "today"
      ],
      "hints": [
        "h",
        "n",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "l",
        "m",
        "o",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "tooth",
        "twice",
        "heart",
        "exist"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "i",
        "o",
        "o",
        "r",
        "s",
        "t",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "chair",
        "clean",
        "refer",
        "never"
      ],
      "hints": [
        "c",
        "r",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "h",
        "i",
        "l",
        "v"
      ]
    },
    {
      "words": [
        "scope",
        "solid",
        "enjoy",
        "dirty"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "c",
        "i",
        "i",
        "j",
        "l",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "cheek",
        "cream",
        "knife",
        "movie"
      ],
      "hints": [
        "c",
        "k",
        "m",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "which",
        "worth",
        "heart",
        "habit"
      ],
      "hints": [
        "w",
        "h",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "h",
        "i",
        "i",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "model",
        "match",
        "lucky",
        "heavy"
      ],
      "hints": [
        "m",
        "l",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "d",
        "e",
        "e",
        "k",
        "o",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "being",
        "build",
        "grade",
        "dance"
      ],
      "hints": [
        "b",
        "g",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "refer",
        "round",
        "right",
        "draft"
      ],
      "hints": [
        "r",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "f",
        "g",
        "h",
        "i",
        "n",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "final",
        "fewer",
        "labor",
        "river"
      ],
      "hints": [
        "f",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "field",
        "fewer",
        "dirty",
        "ready"
      ],
      "hints": [
        "f",
        "d",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "uncle",
        "under",
        "enemy",
        "ready"
      ],
      "hints": [
        "u",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "d",
        "e",
        "e",
        "e",
        "l",
        "m",
        "n",
        "n",
        "n"
      ]
    },
    {
      "words": [
        "guess",
        "giant",
        "split",
        "trust"
      ],
      "hints": [
        "g",
        "s",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "l",
        "n",
        "p",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "stare",
        "split",
        "enjoy",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "j",
        "l",
        "l",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "worth",
        "watch",
        "heart",
        "habit"
      ],
      "hints": [
        "w",
        "h",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "court",
        "child",
        "trace",
        "drive"
      ],
      "hints": [
        "c",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "phase",
        "prime",
        "early",
        "entry"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "l",
        "m",
        "n",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "piece",
        "phase",
        "exact",
        "elect"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "e",
        "e",
        "h",
        "i",
        "l",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "group",
        "glove",
        "price",
        "elite"
      ],
      "hints": [
        "g",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "sport",
        "scope",
        "tight",
        "elect"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "p",
        "p",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "album",
        "aware",
        "might",
        "event"
      ],
      "hints": [
        "a",
        "m",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "l",
        "n",
        "r",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "claim",
        "clear",
        "mouse",
        "route"
      ],
      "hints": [
        "c",
        "m",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "given",
        "guide",
        "night",
        "elect"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "l",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "fewer",
        "flame",
        "rough",
        "earth"
      ],
      "hints": [
        "f",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "m",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "upper",
        "union",
        "reach",
        "north"
      ],
      "hints": [
        "u",
        "r",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "o",
        "p",
        "p",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "count",
        "coach",
        "treat",
        "heart"
      ],
      "hints": [
        "c",
        "t",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "clean",
        "cycle",
        "novel",
        "equal"
      ],
      "hints": [
        "c",
        "n",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "l",
        "o",
        "q",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "sugar",
        "small",
        "raise",
        "loose"
      ],
      "hints": [
        "s",
        "r",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "g",
        "i",
        "l",
        "m",
        "o",
        "o",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "peace",
        "print",
        "eager",
        "their"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "n",
        "r"
      ]
    },
    {
      "words": [
        "scale",
        "shell",
        "enemy",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "h",
        "k",
        "l",
        "l",
        "m",
        "n",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "strip",
        "stuff",
        "proud",
        "field"
      ],
      "hints": [
        "s",
        "p",
        "f",
        "d"
      ],
      "letters": [
        "e",
        "f",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "shall",
        "smell",
        "light",
        "least"
      ],
      "hints": [
        "s",
        "l",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "l",
        "l",
        "m",
        "s"
      ]
    },
    {
      "words": [
        "layer",
        "lemon",
        "reach",
        "north"
      ],
      "hints": [
        "l",
        "r",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "m",
        "o",
        "o",
        "r",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "twice",
        "teach",
        "error",
        "honor"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "speed",
        "sweep",
        "dirty",
        "party"
      ],
      "hints": [
        "s",
        "d",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "p",
        "r",
        "r",
        "t",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "whose",
        "write",
        "essay",
        "enemy"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "m",
        "n",
        "o",
        "r",
        "s",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "stage",
        "steel",
        "earth",
        "lunch"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "n",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "guide",
        "north",
        "earth"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "i",
        "i",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "brief",
        "below",
        "false",
        "waste"
      ],
      "hints": [
        "b",
        "f",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "l",
        "o",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "bench",
        "brief",
        "humor",
        "floor"
      ],
      "hints": [
        "b",
        "h",
        "f",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "pilot",
        "proof",
        "tired",
        "field"
      ],
      "hints": [
        "p",
        "t",
        "f",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "brown",
        "broad",
        "newly",
        "daily"
      ],
      "hints": [
        "b",
        "n",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "dress",
        "draft",
        "stand",
        "third"
      ],
      "hints": [
        "d",
        "s",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "h",
        "i",
        "n",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "label",
        "lower",
        "legal",
        "rural"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "o",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "twice",
        "third",
        "event",
        "draft"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "might",
        "mount",
        "these",
        "tribe"
      ],
      "hints": [
        "m",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "dance",
        "dream",
        "exact",
        "might"
      ],
      "hints": [
        "d",
        "e",
        "m",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "c",
        "e",
        "g",
        "h",
        "i",
        "n",
        "r",
        "x"
      ]
    },
    {
      "words": [
        "glove",
        "grand",
        "earth",
        "depth"
      ],
      "hints": [
        "g",
        "e",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "adopt",
        "along",
        "troop",
        "group"
      ],
      "hints": [
        "a",
        "t",
        "g",
        "p"
      ],
      "letters": [
        "d",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "alive",
        "angry",
        "earth",
        "youth"
      ],
      "hints": [
        "a",
        "e",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "g",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "price",
        "phone",
        "essay",
        "enjoy"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "j",
        "n",
        "n",
        "o",
        "o",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "state",
        "slide",
        "enemy",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "shirt",
        "scene",
        "touch",
        "earth"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "scale",
        "smart",
        "event",
        "tight"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "l",
        "m",
        "n",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "yours",
        "youth",
        "seize",
        "horse"
      ],
      "hints": [
        "y",
        "s",
        "h",
        "e"
      ],
      "letters": [
        "e",
        "i",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "metal",
        "mouth",
        "label",
        "hotel"
      ],
      "hints": [
        "m",
        "l",
        "h",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "o",
        "o",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "theme",
        "trace",
        "essay",
        "enjoy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "j",
        "m",
        "n",
        "o",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "about",
        "alter",
        "theme",
        "range"
      ],
      "hints": [
        "a",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "g",
        "h",
        "l",
        "m",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "forth",
        "fresh",
        "honey",
        "heavy"
      ],
      "hints": [
        "f",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "ratio",
        "route",
        "other",
        "enter"
      ],
      "hints": [
        "r",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "n",
        "o",
        "t",
        "t",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "apple",
        "alone",
        "essay",
        "early"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "l",
        "l",
        "l",
        "n",
        "o",
        "p",
        "p",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "essay",
        "error",
        "yield",
        "rapid"
      ],
      "hints": [
        "e",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "o",
        "p",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "river",
        "react",
        "ready",
        "truly"
      ],
      "hints": [
        "r",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "i",
        "l",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "truck",
        "those",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "below",
        "blood",
        "where",
        "drive"
      ],
      "hints": [
        "b",
        "w",
        "d",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "media",
        "metal",
        "arise",
        "leave"
      ],
      "hints": [
        "m",
        "a",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "i",
        "i",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "shift",
        "smile",
        "teach",
        "earth"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "m",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "thick",
        "treat",
        "knife",
        "trace"
      ],
      "hints": [
        "t",
        "k",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "f",
        "h",
        "i",
        "i",
        "n",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "float",
        "fresh",
        "their",
        "humor"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "virus",
        "visit",
        "steel",
        "total"
      ],
      "hints": [
        "v",
        "s",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "i",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "chief",
        "civil",
        "front",
        "light"
      ],
      "hints": [
        "c",
        "f",
        "l",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "i",
        "n",
        "o",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "month",
        "motor",
        "heart",
        "right"
      ],
      "hints": [
        "m",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "error",
        "eager",
        "raise",
        "range"
      ],
      "hints": [
        "e",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "g",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "elect",
        "exact",
        "trade",
        "title"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "d",
        "e",
        "i",
        "l",
        "l",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "alive",
        "arise",
        "enemy",
        "every"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "i",
        "l",
        "m",
        "n",
        "r",
        "r",
        "s",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "sleep",
        "smoke",
        "pride",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "i",
        "i",
        "k",
        "l",
        "l",
        "m",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "sharp",
        "shoot",
        "piece",
        "trace"
      ],
      "hints": [
        "s",
        "p",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "h",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "twice",
        "title",
        "enemy",
        "enjoy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "i",
        "j",
        "l",
        "m",
        "n",
        "n",
        "o",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "drama",
        "draft",
        "among",
        "thing"
      ],
      "hints": [
        "d",
        "a",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "m",
        "m",
        "n",
        "n",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "favor",
        "first",
        "round",
        "trend"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "glass",
        "group",
        "stone",
        "pause"
      ],
      "hints": [
        "g",
        "s",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "l",
        "n",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "count",
        "chief",
        "tribe",
        "force"
      ],
      "hints": [
        "c",
        "t",
        "f",
        "e"
      ],
      "letters": [
        "b",
        "c",
        "e",
        "h",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "habit",
        "hotel",
        "trust",
        "least"
      ],
      "hints": [
        "h",
        "t",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "crack",
        "class",
        "knock",
        "stick"
      ],
      "hints": [
        "c",
        "k",
        "s",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "stage",
        "sight",
        "early",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "g",
        "h",
        "i",
        "l",
        "l",
        "r",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "motor",
        "mouth",
        "range",
        "horse"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "g",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "worth",
        "whole",
        "habit",
        "eight"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "scale",
        "steel",
        "eight",
        "light"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "t"
      ]
    },
    {
      "words": [
        "paint",
        "prime",
        "those",
        "elite"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "h",
        "i",
        "i",
        "i",
        "l",
        "m",
        "n",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "model",
        "maker",
        "lunch",
        "rough"
      ],
      "hints": [
        "m",
        "l",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "g",
        "k",
        "n",
        "o",
        "o",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "spend",
        "smile",
        "drive",
        "elite"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "i",
        "i",
        "i",
        "l",
        "l",
        "m",
        "n",
        "p",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "trade",
        "tight",
        "earth",
        "touch"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "g",
        "h",
        "i",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "angle",
        "agent",
        "enemy",
        "today"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "g",
        "l",
        "m",
        "n",
        "n",
        "n",
        "o"
      ]
    },
    {
      "words": [
        "trust",
        "tight",
        "track",
        "thank"
      ],
      "hints": [
        "t",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "h",
        "h",
        "i",
        "n",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "lover",
        "labor",
        "rough",
        "reach"
      ],
      "hints": [
        "l",
        "r",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "g",
        "o",
        "o",
        "o",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "brain",
        "buyer",
        "night",
        "react"
      ],
      "hints": [
        "b",
        "n",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "r",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "coach",
        "cream",
        "honor",
        "meter"
      ],
      "hints": [
        "c",
        "h",
        "m",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "treat",
        "tired",
        "truck",
        "drink"
      ],
      "hints": [
        "t",
        "t",
        "d",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "metal",
        "maker",
        "lunch",
        "rough"
      ],
      "hints": [
        "m",
        "l",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "k",
        "n",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "proud",
        "plant",
        "doubt",
        "treat"
      ],
      "hints": [
        "p",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "those",
        "taste",
        "event",
        "eight"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "h",
        "i",
        "n",
        "o",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "cover",
        "cross",
        "rough",
        "south"
      ],
      "hints": [
        "c",
        "r",
        "s",
        "h"
      ],
      "letters": [
        "e",
        "g",
        "o",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "reach",
        "round",
        "house",
        "drive"
      ],
      "hints": [
        "r",
        "h",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "worth",
        "waste",
        "house",
        "elite"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "catch",
        "cause",
        "heavy",
        "essay"
      ],
      "hints": [
        "c",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "e",
        "s",
        "s",
        "s",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "sound",
        "smoke",
        "death",
        "earth"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "k",
        "m",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "tired",
        "twice",
        "depth",
        "earth"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "p",
        "r",
        "r",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "clean",
        "color",
        "novel",
        "rural"
      ],
      "hints": [
        "c",
        "n",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shore",
        "solid",
        "extra",
        "drama"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "a"
      ],
      "letters": [
        "a",
        "h",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "north",
        "nurse",
        "honey",
        "essay"
      ],
      "hints": [
        "n",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "there",
        "trail",
        "every",
        "lucky"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "h",
        "i",
        "k",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shift",
        "state",
        "tribe",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "f",
        "h",
        "i",
        "i",
        "i",
        "l",
        "r",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "allow",
        "avoid",
        "worry",
        "dirty"
      ],
      "hints": [
        "a",
        "w",
        "d",
        "y"
      ],
      "letters": [
        "i",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "truck",
        "tight",
        "knock",
        "track"
      ],
      "hints": [
        "t",
        "k",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "g",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "shake",
        "stage",
        "early",
        "enjoy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "g",
        "h",
        "j",
        "k",
        "l",
        "n",
        "o",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "arise",
        "ahead",
        "entry",
        "daily"
      ],
      "hints": [
        "a",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "river",
        "route",
        "right",
        "elect"
      ],
      "hints": [
        "r",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "o",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "quiet",
        "quite",
        "tough",
        "earth"
      ],
      "hints": [
        "q",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "i",
        "i",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "ghost",
        "grass",
        "trend",
        "spend"
      ],
      "hints": [
        "g",
        "t",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "green",
        "ghost",
        "nurse",
        "tribe"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "h",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "human",
        "hotel",
        "noise",
        "loose"
      ],
      "hints": [
        "h",
        "n",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "m",
        "o",
        "o",
        "o",
        "o",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "habit",
        "heart",
        "trick",
        "track"
      ],
      "hints": [
        "h",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "c",
        "e",
        "i",
        "i",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "stuff",
        "shrug",
        "frame",
        "guide"
      ],
      "hints": [
        "s",
        "f",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "f",
        "h",
        "i",
        "m",
        "r",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "brush",
        "blood",
        "horse",
        "drive"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "e"
      ],
      "letters": [
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "board",
        "brief",
        "delay",
        "funny"
      ],
      "hints": [
        "b",
        "d",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "those",
        "tribe",
        "early",
        "enjoy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "h",
        "i",
        "j",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "weigh",
        "world",
        "human",
        "dozen"
      ],
      "hints": [
        "w",
        "h",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "twice",
        "truth",
        "exact",
        "habit"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "c",
        "i",
        "i",
        "r",
        "t",
        "u",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "sleep",
        "swear",
        "pound",
        "round"
      ],
      "hints": [
        "s",
        "p",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "l",
        "n",
        "n",
        "o",
        "o",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "youth",
        "yield",
        "heavy",
        "daily"
      ],
      "hints": [
        "y",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "o",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "adapt",
        "alive",
        "today",
        "essay"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "d",
        "i",
        "l",
        "o",
        "p",
        "s",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "angle",
        "album",
        "eight",
        "might"
      ],
      "hints": [
        "a",
        "e",
        "m",
        "t"
      ],
      "letters": [
        "b",
        "g",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "n",
        "u"
      ]
    },
    {
      "words": [
        "under",
        "usual",
        "rough",
        "laugh"
      ],
      "hints": [
        "u",
        "r",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "g",
        "n",
        "o",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "these",
        "trust",
        "enemy",
        "truly"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "l",
        "m",
        "n",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "habit",
        "house",
        "treat",
        "exist"
      ],
      "hints": [
        "h",
        "t",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "i",
        "i",
        "o",
        "r",
        "s",
        "s",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "photo",
        "panel",
        "occur",
        "labor"
      ],
      "hints": [
        "p",
        "o",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "c",
        "e",
        "h",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "wound",
        "wheel",
        "death",
        "lunch"
      ],
      "hints": [
        "w",
        "d",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "h",
        "n",
        "n",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "slice",
        "sound",
        "every",
        "daily"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "prove",
        "phase",
        "entry",
        "essay"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "legal",
        "lunch",
        "level",
        "hotel"
      ],
      "hints": [
        "l",
        "l",
        "h",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "g",
        "n",
        "o",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "coach",
        "cover",
        "hotel",
        "rural"
      ],
      "hints": [
        "c",
        "h",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "solve",
        "stuff",
        "enter",
        "fiber"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "r"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "f",
        "i",
        "l",
        "n",
        "o",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "mayor",
        "mouse",
        "river",
        "enter"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "n",
        "o",
        "o",
        "s",
        "t",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "build",
        "blade",
        "death",
        "earth"
      ],
      "hints": [
        "b",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "i",
        "l",
        "l",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "clock",
        "could",
        "knock",
        "drink"
      ],
      "hints": [
        "c",
        "k",
        "d",
        "k"
      ],
      "letters": [
        "c",
        "c",
        "i",
        "l",
        "l",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "naked",
        "nurse",
        "drama",
        "extra"
      ],
      "hints": [
        "n",
        "d",
        "e",
        "a"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "k",
        "m",
        "r",
        "r",
        "r",
        "s",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "craft",
        "cycle",
        "today",
        "empty"
      ],
      "hints": [
        "c",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "f",
        "l",
        "m",
        "o",
        "p",
        "r",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "solid",
        "sharp",
        "delay",
        "party"
      ],
      "hints": [
        "s",
        "d",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "l",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "issue",
        "inner",
        "enter",
        "refer"
      ],
      "hints": [
        "i",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "f",
        "n",
        "n",
        "n",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "house",
        "heart",
        "eager",
        "their"
      ],
      "hints": [
        "h",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "train",
        "trust",
        "novel",
        "trail"
      ],
      "hints": [
        "t",
        "n",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "since",
        "start",
        "enter",
        "their"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "h",
        "i",
        "i",
        "n",
        "n",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "green",
        "grand",
        "nerve",
        "dance"
      ],
      "hints": [
        "g",
        "n",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "n",
        "n",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "guest",
        "giant",
        "three",
        "those"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "n",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "stock",
        "shall",
        "knife",
        "leave"
      ],
      "hints": [
        "s",
        "k",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "l",
        "n",
        "o",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "floor",
        "faith",
        "refer",
        "humor"
      ],
      "hints": [
        "f",
        "r",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "i",
        "l",
        "m",
        "o",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "urban",
        "until",
        "novel",
        "legal"
      ],
      "hints": [
        "u",
        "n",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "g",
        "i",
        "n",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "level",
        "loose",
        "lover",
        "eager"
      ],
      "hints": [
        "l",
        "l",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "g",
        "o",
        "o",
        "o",
        "s",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "serve",
        "stone",
        "enjoy",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "j",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "sugar",
        "solar",
        "radio",
        "ratio"
      ],
      "hints": [
        "s",
        "r",
        "r",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "d",
        "g",
        "i",
        "i",
        "l",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "anger",
        "above",
        "range",
        "elite"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "g",
        "i",
        "l",
        "n",
        "n",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "owner",
        "other",
        "range",
        "route"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "n",
        "n",
        "o",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "death",
        "dozen",
        "humor",
        "never"
      ],
      "hints": [
        "d",
        "h",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "m",
        "o",
        "o",
        "t",
        "u",
        "v",
        "z"
      ]
    },
    {
      "words": [
        "force",
        "flame",
        "every",
        "entry"
      ],
      "hints": [
        "f",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "l",
        "m",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "maybe",
        "metal",
        "entry",
        "lucky"
      ],
      "hints": [
        "m",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "k",
        "n",
        "r",
        "t",
        "t",
        "u",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "title",
        "trial",
        "event",
        "limit"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "i",
        "i",
        "l",
        "m",
        "n",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "labor",
        "limit",
        "route",
        "theme"
      ],
      "hints": [
        "l",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "h",
        "i",
        "i",
        "m",
        "m",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "agree",
        "award",
        "every",
        "dirty"
      ],
      "hints": [
        "a",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "r",
        "r",
        "r",
        "r",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "bench",
        "brand",
        "human",
        "dozen"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "m",
        "n",
        "n",
        "o",
        "r",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "aware",
        "agree",
        "early",
        "every"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "r",
        "r",
        "r",
        "r",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "early",
        "extra",
        "young",
        "among"
      ],
      "hints": [
        "e",
        "y",
        "a",
        "g"
      ],
      "letters": [
        "a",
        "l",
        "m",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "dance",
        "dozen",
        "elite",
        "nurse"
      ],
      "hints": [
        "d",
        "e",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "child",
        "crack",
        "dance",
        "knife"
      ],
      "hints": [
        "c",
        "d",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r"
      ]
    },
    {
      "words": [
        "clear",
        "coast",
        "raise",
        "title"
      ],
      "hints": [
        "c",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "o",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "place",
        "pride",
        "event",
        "elect"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "d",
        "e",
        "e",
        "i",
        "l",
        "l",
        "n",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "carry",
        "craft",
        "young",
        "thing"
      ],
      "hints": [
        "c",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "solar",
        "smoke",
        "range",
        "elite"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "i",
        "k",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "t"
      ]
    },
    {
      "words": [
        "first",
        "faith",
        "twice",
        "house"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "i",
        "i",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "agree",
        "about",
        "enjoy",
        "truly"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "b",
        "e",
        "g",
        "j",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "pitch",
        "porch",
        "humor",
        "honor"
      ],
      "hints": [
        "p",
        "h",
        "h",
        "r"
      ],
      "letters": [
        "c",
        "c",
        "i",
        "m",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "green",
        "grave",
        "north",
        "earth"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "o",
        "r",
        "r",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "visit",
        "vital",
        "these",
        "large"
      ],
      "hints": [
        "v",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "trend",
        "tooth",
        "daily",
        "honey"
      ],
      "hints": [
        "t",
        "d",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "clean",
        "climb",
        "nerve",
        "bible"
      ],
      "hints": [
        "c",
        "n",
        "b",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "l",
        "m",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "vital",
        "visit",
        "local",
        "trail"
      ],
      "hints": [
        "v",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "i",
        "i",
        "i",
        "i",
        "o",
        "r",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "clean",
        "coast",
        "never",
        "tower"
      ],
      "hints": [
        "c",
        "n",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "l",
        "o",
        "o",
        "s",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "mouth",
        "metal",
        "human",
        "learn"
      ],
      "hints": [
        "m",
        "h",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "m",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "terms",
        "tribe",
        "start",
        "eight"
      ],
      "hints": [
        "t",
        "s",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "i",
        "m",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "brief",
        "brain",
        "floor",
        "never"
      ],
      "hints": [
        "b",
        "f",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "sales",
        "shoot",
        "steel",
        "trial"
      ],
      "hints": [
        "s",
        "s",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "crime",
        "count",
        "every",
        "truly"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "i",
        "l",
        "m",
        "n",
        "o",
        "r",
        "r",
        "r",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "vital",
        "ready",
        "lucky"
      ],
      "hints": [
        "v",
        "r",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "i",
        "k",
        "o",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "steal",
        "shake",
        "large",
        "elite"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "k",
        "l",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "basis",
        "break",
        "scale",
        "knife"
      ],
      "hints": [
        "b",
        "s",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "f",
        "i",
        "i",
        "l",
        "n",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "since",
        "sport",
        "enjoy",
        "today"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "i",
        "j",
        "n",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "staff",
        "slice",
        "funny",
        "entry"
      ],
      "hints": [
        "s",
        "f",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "i",
        "l",
        "n",
        "n",
        "n",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "prove",
        "pilot",
        "essay",
        "truly"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "share",
        "small",
        "every",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "k",
        "l",
        "m",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "least",
        "lower",
        "three",
        "raise"
      ],
      "hints": [
        "l",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "o",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "skill",
        "serve",
        "lucky",
        "enemy"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "k",
        "k",
        "l",
        "m",
        "n",
        "r",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "maybe",
        "motor",
        "entry",
        "ready"
      ],
      "hints": [
        "m",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "label",
        "leave",
        "legal",
        "equal"
      ],
      "hints": [
        "l",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "q",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "train",
        "throw",
        "nerve",
        "where"
      ],
      "hints": [
        "t",
        "n",
        "w",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "o",
        "r",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "route",
        "refer",
        "event",
        "react"
      ],
      "hints": [
        "r",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "f",
        "n",
        "o",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "reach",
        "route",
        "heavy",
        "entry"
      ],
      "hints": [
        "r",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "grave",
        "group",
        "exact",
        "point"
      ],
      "hints": [
        "g",
        "e",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "twice",
        "third",
        "enjoy",
        "dirty"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "c",
        "h",
        "i",
        "i",
        "i",
        "j",
        "n",
        "o",
        "r",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "offer",
        "often",
        "route",
        "nerve"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "f",
        "f",
        "f",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "split",
        "shake",
        "taste",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "k",
        "l",
        "l",
        "p",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "swing",
        "stone",
        "grade",
        "elite"
      ],
      "hints": [
        "s",
        "g",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "t",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "least",
        "lemon",
        "these",
        "noise"
      ],
      "hints": [
        "l",
        "t",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "m",
        "o",
        "o",
        "s",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "third",
        "tight",
        "daily",
        "today"
      ],
      "hints": [
        "t",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "whole",
        "white",
        "enemy",
        "essay"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "i",
        "l",
        "m",
        "n",
        "o",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "sleep",
        "sweep",
        "plant",
        "pilot"
      ],
      "hints": [
        "s",
        "p",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "l",
        "n",
        "o",
        "w"
      ]
    },
    {
      "words": [
        "final",
        "first",
        "learn",
        "train"
      ],
      "hints": [
        "f",
        "l",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "grain",
        "guest",
        "north",
        "touch"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "plane",
        "photo",
        "eight",
        "ought"
      ],
      "hints": [
        "p",
        "e",
        "o",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "g",
        "h",
        "h",
        "h",
        "i",
        "l",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "water",
        "would",
        "right",
        "draft"
      ],
      "hints": [
        "w",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "g",
        "h",
        "i",
        "l",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "angry",
        "aware",
        "youth",
        "earth"
      ],
      "hints": [
        "a",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "share",
        "sauce",
        "every",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "h",
        "l",
        "r",
        "r",
        "r",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "smell",
        "start",
        "lucky",
        "truly"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "k",
        "l",
        "l",
        "m",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "peace",
        "price",
        "exist",
        "exact"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "c",
        "e",
        "i",
        "i",
        "r",
        "s",
        "x",
        "x"
      ]
    },
    {
      "words": [
        "owner",
        "often",
        "refer",
        "never"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "n",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "porch",
        "proof",
        "hotel",
        "final"
      ],
      "hints": [
        "p",
        "h",
        "f",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "major",
        "mouth",
        "ready",
        "honey"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "j",
        "n",
        "o",
        "o",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "point",
        "plant",
        "tough",
        "truth"
      ],
      "hints": [
        "p",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "g",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "vital",
        "value",
        "level",
        "equal"
      ],
      "hints": [
        "v",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "q",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "fruit",
        "focus",
        "tough",
        "south"
      ],
      "hints": [
        "f",
        "t",
        "s",
        "h"
      ],
      "letters": [
        "c",
        "g",
        "i",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "brown",
        "being",
        "naked",
        "grand"
      ],
      "hints": [
        "b",
        "n",
        "g",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "k",
        "n",
        "n",
        "o",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "climb",
        "coast",
        "blade",
        "these"
      ],
      "hints": [
        "c",
        "b",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "h",
        "i",
        "l",
        "l",
        "m",
        "o",
        "s",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "visit",
        "vital",
        "their",
        "labor"
      ],
      "hints": [
        "v",
        "t",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "h",
        "i",
        "i",
        "i",
        "i",
        "o",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "smile",
        "sweet",
        "entry",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "r",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "tribe",
        "those",
        "enemy",
        "early"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "h",
        "i",
        "l",
        "m",
        "n",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "share",
        "shine",
        "early",
        "enjoy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "h",
        "i",
        "j",
        "l",
        "n",
        "n",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "sugar",
        "shirt",
        "reply",
        "today"
      ],
      "hints": [
        "s",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "p",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "right",
        "reach",
        "total",
        "hotel"
      ],
      "hints": [
        "r",
        "t",
        "h",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "o",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "juice",
        "joint",
        "elite",
        "theme"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "i",
        "i",
        "l",
        "m",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "every",
        "event",
        "youth",
        "truth"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "e",
        "e",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "shirt",
        "stair",
        "today",
        "ready"
      ],
      "hints": [
        "s",
        "t",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "d",
        "e",
        "h",
        "i",
        "i",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "those",
        "tooth",
        "enjoy",
        "honey"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "e",
        "h",
        "j",
        "n",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "close",
        "coach",
        "error",
        "honor"
      ],
      "hints": [
        "c",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "chase",
        "court",
        "eager",
        "their"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "reach",
        "route",
        "honey",
        "entry"
      ],
      "hints": [
        "r",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "major",
        "moral",
        "rough",
        "lunch"
      ],
      "hints": [
        "m",
        "r",
        "l",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "j",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "store",
        "spend",
        "exist",
        "doubt"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "i",
        "n",
        "o",
        "o",
        "p",
        "r",
        "s",
        "t",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "clear",
        "clock",
        "range",
        "knife"
      ],
      "hints": [
        "c",
        "r",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "f",
        "g",
        "i",
        "l",
        "l",
        "n",
        "n",
        "o"
      ]
    },
    {
      "words": [
        "agree",
        "aside",
        "eager",
        "error"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "g",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "study",
        "shirt",
        "young",
        "thing"
      ],
      "hints": [
        "s",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "d",
        "h",
        "h",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "cycle",
        "clear",
        "enjoy",
        "ready"
      ],
      "hints": [
        "c",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "j",
        "l",
        "l",
        "n",
        "o",
        "y"
      ]
    },
    {
      "words": [
        "phase",
        "paper",
        "empty",
        "ready"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "h",
        "m",
        "p",
        "p",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "chest",
        "cross",
        "third",
        "stand"
      ],
      "hints": [
        "c",
        "t",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "react",
        "rough",
        "trace",
        "house"
      ],
      "hints": [
        "r",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "g",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "juice",
        "judge",
        "essay",
        "entry"
      ],
      "hints": [
        "j",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "g",
        "i",
        "n",
        "r",
        "s",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "given",
        "glass",
        "nurse",
        "smoke"
      ],
      "hints": [
        "g",
        "n",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "k",
        "l",
        "m",
        "o",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "stuff",
        "strip",
        "force",
        "prove"
      ],
      "hints": [
        "s",
        "f",
        "p",
        "e"
      ],
      "letters": [
        "c",
        "f",
        "i",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "sound",
        "sport",
        "delay",
        "truly"
      ],
      "hints": [
        "s",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "l",
        "l",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "mouse",
        "minor",
        "equal",
        "rural"
      ],
      "hints": [
        "m",
        "e",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "n",
        "o",
        "o",
        "q",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "after",
        "actor",
        "refer",
        "river"
      ],
      "hints": [
        "a",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "i",
        "o",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "spend",
        "since",
        "dirty",
        "enemy"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "i",
        "m",
        "n",
        "n",
        "n",
        "p",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "event",
        "equal",
        "twice",
        "leave"
      ],
      "hints": [
        "e",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "q",
        "u",
        "v",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "alone",
        "agent",
        "eight",
        "treat"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "g",
        "h",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "glass",
        "grass",
        "shine",
        "since"
      ],
      "hints": [
        "g",
        "s",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "thing",
        "troop",
        "great",
        "pilot"
      ],
      "hints": [
        "t",
        "g",
        "p",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "brain",
        "block",
        "nurse",
        "knife"
      ],
      "hints": [
        "b",
        "n",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "shine",
        "staff",
        "enjoy",
        "fully"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "f",
        "h",
        "i",
        "j",
        "l",
        "l",
        "n",
        "n",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "swear",
        "staff",
        "river",
        "fewer"
      ],
      "hints": [
        "s",
        "r",
        "f",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "t",
        "v",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "ghost",
        "giant",
        "truck",
        "track"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "brown",
        "basic",
        "newly",
        "carry"
      ],
      "hints": [
        "b",
        "n",
        "c",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "s",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "paint",
        "print",
        "tower",
        "their"
      ],
      "hints": [
        "p",
        "t",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "dozen",
        "dress",
        "night",
        "sweet"
      ],
      "hints": [
        "d",
        "n",
        "s",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "r",
        "s",
        "w",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "birth",
        "below",
        "honor",
        "water"
      ],
      "hints": [
        "b",
        "h",
        "w",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "below",
        "blade",
        "where",
        "elite"
      ],
      "hints": [
        "b",
        "w",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "l",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "pilot",
        "power",
        "total",
        "rural"
      ],
      "hints": [
        "p",
        "t",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "fight",
        "fruit",
        "trial",
        "total"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "i",
        "i",
        "i",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "agent",
        "alive",
        "trade",
        "elite"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "g",
        "i",
        "i",
        "l",
        "l",
        "n",
        "r",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "found",
        "fiber",
        "daily",
        "ready"
      ],
      "hints": [
        "f",
        "d",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "offer",
        "order",
        "reach",
        "rough"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "f",
        "f",
        "g",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "often",
        "order",
        "north",
        "reach"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "f",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "sleep",
        "sales",
        "paint",
        "sight"
      ],
      "hints": [
        "s",
        "p",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "l",
        "n"
      ]
    },
    {
      "words": [
        "funny",
        "float",
        "young",
        "thing"
      ],
      "hints": [
        "f",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "h",
        "i",
        "l",
        "n",
        "n",
        "n",
        "n",
        "o",
        "o",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "joint",
        "judge",
        "table",
        "elite"
      ],
      "hints": [
        "j",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "d",
        "g",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "visit",
        "virus",
        "third",
        "spend"
      ],
      "hints": [
        "v",
        "t",
        "s",
        "d"
      ],
      "letters": [
        "e",
        "h",
        "i",
        "i",
        "i",
        "i",
        "n",
        "p",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "final",
        "flesh",
        "lower",
        "humor"
      ],
      "hints": [
        "f",
        "l",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "o",
        "o",
        "s",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "short",
        "shock",
        "think",
        "knock"
      ],
      "hints": [
        "s",
        "t",
        "k",
        "k"
      ],
      "letters": [
        "c",
        "c",
        "h",
        "h",
        "h",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "apart",
        "abuse",
        "truth",
        "earth"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "p",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "equal",
        "exist",
        "light",
        "trust"
      ],
      "hints": [
        "e",
        "l",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "q",
        "r",
        "s",
        "s",
        "u",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "clean",
        "craft",
        "newly",
        "today"
      ],
      "hints": [
        "c",
        "n",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "f",
        "l",
        "l",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "cause",
        "chair",
        "eager",
        "refer"
      ],
      "hints": [
        "c",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "h",
        "i",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "three",
        "train",
        "essay",
        "newly"
      ],
      "hints": [
        "t",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "r",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "chase",
        "chest",
        "error",
        "tower"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "o",
        "o",
        "r",
        "r",
        "s",
        "s",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "shift",
        "shout",
        "table",
        "trace"
      ],
      "hints": [
        "s",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "f",
        "h",
        "h",
        "i",
        "l",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "actor",
        "about",
        "ready",
        "today"
      ],
      "hints": [
        "a",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "d",
        "d",
        "e",
        "o",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "cross",
        "couch",
        "share",
        "horse"
      ],
      "hints": [
        "c",
        "s",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "sense",
        "sugar",
        "early",
        "reply"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "l",
        "l",
        "n",
        "p",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "value",
        "virus",
        "equal",
        "steal"
      ],
      "hints": [
        "v",
        "e",
        "s",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "l",
        "q",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "plane",
        "prior",
        "enjoy",
        "ready"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "i",
        "j",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "after",
        "anger",
        "round",
        "rapid"
      ],
      "hints": [
        "a",
        "r",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "g",
        "i",
        "n",
        "n",
        "o",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "which",
        "would",
        "heart",
        "draft"
      ],
      "hints": [
        "w",
        "h",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "f",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "civil",
        "cable",
        "local",
        "equal"
      ],
      "hints": [
        "c",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "i",
        "i",
        "l",
        "o",
        "q",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shell",
        "sauce",
        "lunch",
        "earth"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "h",
        "l",
        "n",
        "r",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "tower",
        "treat",
        "reply",
        "today"
      ],
      "hints": [
        "t",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "l",
        "o",
        "o",
        "p",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "ocean",
        "other",
        "nerve",
        "range"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "n",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "forth",
        "favor",
        "heart",
        "react"
      ],
      "hints": [
        "f",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "o",
        "o",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "avoid",
        "about",
        "dress",
        "terms"
      ],
      "hints": [
        "a",
        "d",
        "t",
        "s"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "i",
        "m",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "hotel",
        "horse",
        "light",
        "exist"
      ],
      "hints": [
        "h",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "motor",
        "mouth",
        "radio",
        "hello"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "o"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "fresh",
        "field",
        "heavy",
        "delay"
      ],
      "hints": [
        "f",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "watch",
        "write",
        "heavy",
        "enjoy"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "j",
        "n",
        "o",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "beach",
        "begin",
        "habit",
        "night"
      ],
      "hints": [
        "b",
        "h",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "i",
        "i",
        "i"
      ]
    },
    {
      "words": [
        "quite",
        "quote",
        "error",
        "eager"
      ],
      "hints": [
        "q",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "i",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "touch",
        "their",
        "happy",
        "ready"
      ],
      "hints": [
        "t",
        "h",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "h",
        "i",
        "o",
        "p",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "first",
        "floor",
        "trace",
        "route"
      ],
      "hints": [
        "f",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "grave",
        "grade",
        "early",
        "entry"
      ],
      "hints": [
        "g",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "l",
        "n",
        "r",
        "r",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "final",
        "fault",
        "level",
        "trial"
      ],
      "hints": [
        "f",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "story",
        "slide",
        "youth",
        "earth"
      ],
      "hints": [
        "s",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "tough",
        "trade",
        "habit",
        "eight"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "g",
        "g",
        "h",
        "i",
        "i",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "death",
        "dance",
        "house",
        "elite"
      ],
      "hints": [
        "d",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "l",
        "n",
        "o",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "dance",
        "dozen",
        "elect",
        "night"
      ],
      "hints": [
        "d",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "n",
        "o",
        "z"
      ]
    },
    {
      "words": [
        "chase",
        "chief",
        "enemy",
        "funny"
      ],
      "hints": [
        "c",
        "e",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "m",
        "n",
        "n",
        "n",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "front",
        "first",
        "teach",
        "touch"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "share",
        "scope",
        "eight",
        "exact"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "g",
        "h",
        "h",
        "i",
        "o",
        "p",
        "r",
        "x"
      ]
    },
    {
      "words": [
        "among",
        "ahead",
        "grain",
        "dozen"
      ],
      "hints": [
        "a",
        "g",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "m",
        "n",
        "o",
        "o",
        "r",
        "z"
      ]
    },
    {
      "words": [
        "tribe",
        "taste",
        "every",
        "empty"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "m",
        "p",
        "r",
        "r",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "wound",
        "woman",
        "daily",
        "newly"
      ],
      "hints": [
        "w",
        "d",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "child",
        "craft",
        "dirty",
        "truly"
      ],
      "hints": [
        "c",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "f",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "super",
        "scale",
        "river",
        "error"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "o",
        "p",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "style",
        "sharp",
        "enjoy",
        "party"
      ],
      "hints": [
        "s",
        "e",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "j",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "tower",
        "throw",
        "rough",
        "which"
      ],
      "hints": [
        "t",
        "r",
        "w",
        "h"
      ],
      "letters": [
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "o",
        "o",
        "o",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "hello",
        "hotel",
        "onion",
        "lemon"
      ],
      "hints": [
        "h",
        "o",
        "l",
        "n"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "o",
        "t"
      ]
    },
    {
      "words": [
        "score",
        "split",
        "eager",
        "tower"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "p",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "blame",
        "bread",
        "elect",
        "doubt"
      ],
      "hints": [
        "b",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "l",
        "l",
        "m",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "buyer",
        "being",
        "range",
        "glove"
      ],
      "hints": [
        "b",
        "r",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "n",
        "o",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "image",
        "ideal",
        "equal",
        "local"
      ],
      "hints": [
        "i",
        "e",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "g",
        "m",
        "o",
        "q",
        "u"
      ]
    },
    {
      "words": [
        "urban",
        "union",
        "nerve",
        "nurse"
      ],
      "hints": [
        "u",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "bench",
        "brain",
        "honey",
        "newly"
      ],
      "hints": [
        "b",
        "h",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "taste",
        "third",
        "extra",
        "drama"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "a"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "m",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "novel",
        "noise",
        "leave",
        "elite"
      ],
      "hints": [
        "n",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "o",
        "o",
        "s",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "night",
        "nerve",
        "truly",
        "enemy"
      ],
      "hints": [
        "n",
        "t",
        "e",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "m",
        "n",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "these",
        "tribe",
        "elect",
        "eight"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "b",
        "c",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "trust",
        "trade",
        "those",
        "elite"
      ],
      "hints": [
        "t",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "young",
        "yours",
        "guest",
        "sight"
      ],
      "hints": [
        "y",
        "g",
        "s",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "thank",
        "third",
        "knife",
        "drive"
      ],
      "hints": [
        "t",
        "k",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "f",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "n",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "sweep",
        "stare",
        "phase",
        "elite"
      ],
      "hints": [
        "s",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "r",
        "s",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "tired",
        "train",
        "dance",
        "nerve"
      ],
      "hints": [
        "t",
        "d",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "n",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "local",
        "labor",
        "loose",
        "range"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "g",
        "n",
        "o",
        "o",
        "o",
        "o",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "major",
        "mouth",
        "react",
        "heart"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "j",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "quote",
        "quiet",
        "equal",
        "trail"
      ],
      "hints": [
        "q",
        "e",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "o",
        "q",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "guide",
        "grave",
        "eager",
        "enter"
      ],
      "hints": [
        "g",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "i",
        "n",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "honey",
        "human",
        "youth",
        "north"
      ],
      "hints": [
        "h",
        "y",
        "n",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "m",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "brain",
        "buyer",
        "noise",
        "raise"
      ],
      "hints": [
        "b",
        "n",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "i",
        "i",
        "i",
        "o",
        "r",
        "s",
        "s",
        "u",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "label",
        "lemon",
        "least",
        "night"
      ],
      "hints": [
        "l",
        "l",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "m",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "scope",
        "seize",
        "error",
        "enter"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r",
        "t",
        "z"
      ]
    },
    {
      "words": [
        "steel",
        "scope",
        "level",
        "equal"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "o",
        "p",
        "q",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "alive",
        "aware",
        "elect",
        "exact"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "i",
        "l",
        "l",
        "r",
        "v",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "refer",
        "rapid",
        "react",
        "draft"
      ],
      "hints": [
        "r",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "f",
        "i",
        "p",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "virus",
        "voice",
        "share",
        "elite"
      ],
      "hints": [
        "v",
        "s",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "civil",
        "cycle",
        "limit",
        "exist"
      ],
      "hints": [
        "c",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "i",
        "i",
        "i",
        "i",
        "i",
        "l",
        "m",
        "s",
        "v",
        "x",
        "y"
      ]
    },
    {
      "words": [
        "salad",
        "short",
        "daily",
        "truly"
      ],
      "hints": [
        "s",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "h",
        "i",
        "l",
        "l",
        "l",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "piece",
        "plate",
        "every",
        "empty"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "m",
        "p",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "rapid",
        "react",
        "dance",
        "tribe"
      ],
      "hints": [
        "r",
        "d",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "c",
        "e",
        "i",
        "i",
        "n",
        "p",
        "r"
      ]
    }
  ],
  [
    {
      "words": [
        "ought",
        "onion",
        "trade",
        "nurse"
      ],
      "hints": [
        "o",
        "t",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "never",
        "noise",
        "range",
        "elite"
      ],
      "hints": [
        "n",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "i",
        "l",
        "n",
        "o",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "music",
        "major",
        "crash",
        "rough"
      ],
      "hints": [
        "m",
        "c",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "i",
        "j",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "money",
        "motor",
        "yield",
        "rapid"
      ],
      "hints": [
        "m",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "p",
        "t"
      ]
    },
    {
      "words": [
        "scene",
        "state",
        "empty",
        "enjoy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "j",
        "m",
        "n",
        "n",
        "o",
        "p",
        "t",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "found",
        "favor",
        "drive",
        "range"
      ],
      "hints": [
        "f",
        "d",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "false",
        "flame",
        "every",
        "entry"
      ],
      "hints": [
        "f",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "l",
        "m",
        "n",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "alter",
        "apart",
        "raise",
        "taste"
      ],
      "hints": [
        "a",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "l",
        "p",
        "r",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "drink",
        "dress",
        "knock",
        "speak"
      ],
      "hints": [
        "d",
        "k",
        "s",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "waste",
        "white",
        "enter",
        "error"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "place",
        "phase",
        "error",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "l",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "track",
        "trade",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "f",
        "i",
        "i",
        "l",
        "n",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "floor",
        "force",
        "river",
        "enter"
      ],
      "hints": [
        "f",
        "r",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "buyer",
        "bible",
        "react",
        "elect"
      ],
      "hints": [
        "b",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "c",
        "e",
        "e",
        "e",
        "i",
        "l",
        "l",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "album",
        "after",
        "might",
        "right"
      ],
      "hints": [
        "a",
        "m",
        "r",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "crazy",
        "climb",
        "yield",
        "blood"
      ],
      "hints": [
        "c",
        "y",
        "b",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "l",
        "m",
        "o",
        "o",
        "r",
        "z"
      ]
    },
    {
      "words": [
        "share",
        "steel",
        "elect",
        "least"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "h",
        "l",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "power",
        "plate",
        "ready",
        "empty"
      ],
      "hints": [
        "p",
        "r",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "l",
        "m",
        "o",
        "p",
        "t",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "table",
        "topic",
        "eager",
        "cover"
      ],
      "hints": [
        "t",
        "e",
        "c",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "g",
        "i",
        "l",
        "o",
        "o",
        "p",
        "v"
      ]
    },
    {
      "words": [
        "space",
        "shore",
        "entry",
        "essay"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "laugh",
        "later",
        "house",
        "route"
      ],
      "hints": [
        "l",
        "h",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "prior",
        "paint",
        "right",
        "treat"
      ],
      "hints": [
        "p",
        "r",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "terms",
        "topic",
        "share",
        "cause"
      ],
      "hints": [
        "t",
        "s",
        "c",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "m",
        "o",
        "p",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "share",
        "slide",
        "eager",
        "error"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "adapt",
        "aside",
        "trail",
        "equal"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "d",
        "i",
        "i",
        "p",
        "q",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "block",
        "blood",
        "knife",
        "dance"
      ],
      "hints": [
        "b",
        "k",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "f",
        "i",
        "l",
        "l",
        "n",
        "n",
        "o",
        "o",
        "o"
      ]
    },
    {
      "words": [
        "novel",
        "nurse",
        "laugh",
        "earth"
      ],
      "hints": [
        "n",
        "l",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "labor",
        "later",
        "ready",
        "reply"
      ],
      "hints": [
        "l",
        "r",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "e",
        "e",
        "e",
        "l",
        "o",
        "p",
        "t"
      ]
    },
    {
      "words": [
        "cover",
        "could",
        "right",
        "draft"
      ],
      "hints": [
        "c",
        "r",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "given",
        "ghost",
        "night",
        "treat"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "r",
        "s",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "honor",
        "horse",
        "route",
        "elite"
      ],
      "hints": [
        "h",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "chase",
        "civil",
        "exist",
        "least"
      ],
      "hints": [
        "c",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "s",
        "s",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "under",
        "upper",
        "right",
        "react"
      ],
      "hints": [
        "u",
        "r",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "stand",
        "share",
        "doubt",
        "exact"
      ],
      "hints": [
        "s",
        "d",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "h",
        "n",
        "o",
        "r",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "chest",
        "child",
        "these",
        "drive"
      ],
      "hints": [
        "c",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "h",
        "h",
        "i",
        "i",
        "l",
        "r",
        "s",
        "s",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "elect",
        "elite",
        "tough",
        "earth"
      ],
      "hints": [
        "e",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "l",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "enter",
        "extra",
        "river",
        "anger"
      ],
      "hints": [
        "e",
        "r",
        "a",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "g",
        "i",
        "n",
        "n",
        "r",
        "t",
        "t",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "newly",
        "nerve",
        "youth",
        "earth"
      ],
      "hints": [
        "n",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "l",
        "o",
        "r",
        "r",
        "t",
        "t",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "store",
        "stuff",
        "elect",
        "float"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "l",
        "l",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "today",
        "trend",
        "youth",
        "depth"
      ],
      "hints": [
        "t",
        "y",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "n",
        "o",
        "o",
        "p",
        "r",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "begin",
        "bench",
        "newly",
        "happy"
      ],
      "hints": [
        "b",
        "n",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "n",
        "p",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "throw",
        "track",
        "where",
        "knife"
      ],
      "hints": [
        "t",
        "w",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "aside",
        "abuse",
        "enjoy",
        "entry"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "b",
        "d",
        "i",
        "j",
        "n",
        "n",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shoot",
        "sales",
        "table",
        "stare"
      ],
      "hints": [
        "s",
        "t",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "h",
        "l",
        "l",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "treat",
        "tough",
        "trade",
        "house"
      ],
      "hints": [
        "t",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "g",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "terms",
        "tight",
        "sharp",
        "troop"
      ],
      "hints": [
        "t",
        "s",
        "t",
        "p"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "h",
        "h",
        "i",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "thick",
        "topic",
        "knock",
        "crack"
      ],
      "hints": [
        "t",
        "k",
        "c",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "h",
        "i",
        "i",
        "n",
        "o",
        "o",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "alone",
        "admit",
        "earth",
        "tough"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "i",
        "l",
        "m",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "dance",
        "depth",
        "essay",
        "honey"
      ],
      "hints": [
        "d",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "n",
        "n",
        "o",
        "p",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "start",
        "shock",
        "twice",
        "knife"
      ],
      "hints": [
        "s",
        "t",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "f",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "price",
        "prime",
        "enjoy",
        "early"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "i",
        "j",
        "l",
        "m",
        "n",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "plane",
        "pause",
        "eager",
        "error"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "l",
        "n",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "waste",
        "water",
        "elect",
        "right"
      ],
      "hints": [
        "w",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "found",
        "fresh",
        "doubt",
        "habit"
      ],
      "hints": [
        "f",
        "d",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "b",
        "e",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "pause",
        "prior",
        "entry",
        "reply"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "l",
        "n",
        "o",
        "p",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "flesh",
        "fight",
        "honey",
        "truly"
      ],
      "hints": [
        "f",
        "h",
        "t",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "fault",
        "flesh",
        "three",
        "horse"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "l",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "coach",
        "couch",
        "happy",
        "heavy"
      ],
      "hints": [
        "c",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "c",
        "e",
        "o",
        "o",
        "p",
        "p",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "waste",
        "whole",
        "every",
        "enemy"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "l",
        "m",
        "n",
        "o",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "serve",
        "speed",
        "enemy",
        "daily"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "p",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "guess",
        "group",
        "since",
        "peace"
      ],
      "hints": [
        "g",
        "s",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "begin",
        "brush",
        "novel",
        "hotel"
      ],
      "hints": [
        "b",
        "n",
        "h",
        "l"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "g",
        "i",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "right",
        "rapid",
        "touch",
        "depth"
      ],
      "hints": [
        "r",
        "t",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "o",
        "p",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "hotel",
        "heart",
        "loose",
        "taste"
      ],
      "hints": [
        "h",
        "l",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "o",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "nurse",
        "north",
        "error",
        "humor"
      ],
      "hints": [
        "n",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "m",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "woman",
        "write",
        "night",
        "elect"
      ],
      "hints": [
        "w",
        "n",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "m",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "treat",
        "three",
        "twice",
        "elite"
      ],
      "hints": [
        "t",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "r",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "occur",
        "offer",
        "range",
        "route"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "f",
        "f",
        "g",
        "n",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "proof",
        "point",
        "found",
        "tired"
      ],
      "hints": [
        "p",
        "f",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "i",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "force",
        "float",
        "exact",
        "tight"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "r",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "works",
        "world",
        "sauce",
        "dance"
      ],
      "hints": [
        "w",
        "s",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "k",
        "l",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "thick",
        "table",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "f",
        "h",
        "i",
        "i",
        "i",
        "l",
        "l",
        "n",
        "t"
      ]
    },
    {
      "words": [
        "guide",
        "given",
        "early",
        "newly"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "i",
        "i",
        "l",
        "l",
        "r",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "theme",
        "tower",
        "early",
        "ready"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "h",
        "l",
        "m",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "serve",
        "sauce",
        "early",
        "enjoy"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "j",
        "l",
        "n",
        "o",
        "r",
        "r",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "exist",
        "earth",
        "today",
        "happy"
      ],
      "hints": [
        "e",
        "t",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "i",
        "o",
        "p",
        "p",
        "r",
        "s",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "phone",
        "patch",
        "enemy",
        "heavy"
      ],
      "hints": [
        "p",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "m",
        "n",
        "n",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "label",
        "lover",
        "leave",
        "range"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "e",
        "g",
        "n",
        "o",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "chain",
        "clear",
        "newly",
        "reply"
      ],
      "hints": [
        "c",
        "n",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "h",
        "i",
        "l",
        "l",
        "l",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "fight",
        "float",
        "truth",
        "teach"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "r",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "color",
        "court",
        "raise",
        "table"
      ],
      "hints": [
        "c",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "abuse",
        "about",
        "exist",
        "treat"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "b",
        "e",
        "i",
        "o",
        "r",
        "s",
        "s",
        "u",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "large",
        "loose",
        "event",
        "eight"
      ],
      "hints": [
        "l",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "often",
        "occur",
        "naked",
        "rapid"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "f",
        "i",
        "k",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "cream",
        "chair",
        "match",
        "reach"
      ],
      "hints": [
        "c",
        "m",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "h",
        "i",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "coach",
        "cabin",
        "happy",
        "newly"
      ],
      "hints": [
        "c",
        "h",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "l",
        "o",
        "p",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "world",
        "write",
        "dance",
        "elite"
      ],
      "hints": [
        "w",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "above",
        "alone",
        "essay",
        "entry"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "bible",
        "beach",
        "early",
        "happy"
      ],
      "hints": [
        "b",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "l",
        "l",
        "p",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "light",
        "lover",
        "tooth",
        "rough"
      ],
      "hints": [
        "l",
        "t",
        "r",
        "h"
      ],
      "letters": [
        "e",
        "g",
        "g",
        "h",
        "i",
        "o",
        "o",
        "o",
        "o",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "glove",
        "grade",
        "enemy",
        "essay"
      ],
      "hints": [
        "g",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "l",
        "m",
        "n",
        "o",
        "r",
        "s",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "naked",
        "never",
        "drive",
        "raise"
      ],
      "hints": [
        "n",
        "d",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "i",
        "k",
        "r",
        "s",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "crack",
        "court",
        "knife",
        "twice"
      ],
      "hints": [
        "c",
        "k",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "f",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "value",
        "vital",
        "elite",
        "leave"
      ],
      "hints": [
        "v",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "i",
        "l",
        "l",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "pause",
        "panel",
        "enemy",
        "lucky"
      ],
      "hints": [
        "p",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "k",
        "m",
        "n",
        "n",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "cover",
        "carry",
        "rough",
        "youth"
      ],
      "hints": [
        "c",
        "r",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "world",
        "works",
        "drive",
        "shade"
      ],
      "hints": [
        "w",
        "d",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "h",
        "i",
        "k",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "urban",
        "usual",
        "newly",
        "lucky"
      ],
      "hints": [
        "u",
        "n",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "k",
        "l",
        "r",
        "s",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "agree",
        "avoid",
        "essay",
        "daily"
      ],
      "hints": [
        "a",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "i",
        "i",
        "l",
        "o",
        "r",
        "s",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "salad",
        "skill",
        "drive",
        "leave"
      ],
      "hints": [
        "s",
        "d",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "i",
        "i",
        "k",
        "l",
        "l",
        "r",
        "v",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "major",
        "mouse",
        "react",
        "elect"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "j",
        "l",
        "o",
        "o",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "cable",
        "cream",
        "entry",
        "money"
      ],
      "hints": [
        "c",
        "e",
        "m",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "l",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "where",
        "write",
        "enjoy",
        "enemy"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "i",
        "j",
        "m",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "shrug",
        "smile",
        "glove",
        "elite"
      ],
      "hints": [
        "s",
        "g",
        "e",
        "e"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "l",
        "l",
        "l",
        "m",
        "o",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "group",
        "guess",
        "press",
        "sales"
      ],
      "hints": [
        "g",
        "p",
        "s",
        "s"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "shout",
        "shake",
        "trace",
        "elite"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "h",
        "i",
        "k",
        "l",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "trust",
        "terms",
        "truth",
        "south"
      ],
      "hints": [
        "t",
        "t",
        "s",
        "h"
      ],
      "letters": [
        "e",
        "m",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "slide",
        "shade",
        "entry",
        "essay"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "h",
        "i",
        "l",
        "n",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "since",
        "storm",
        "earth",
        "month"
      ],
      "hints": [
        "s",
        "e",
        "m",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "light",
        "lower",
        "tight",
        "react"
      ],
      "hints": [
        "l",
        "t",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "tribe",
        "there",
        "every",
        "enemy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "e",
        "h",
        "i",
        "m",
        "n",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "light",
        "local",
        "trail",
        "label"
      ],
      "hints": [
        "l",
        "t",
        "l",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "while",
        "whole",
        "early",
        "enjoy"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "h",
        "h",
        "i",
        "j",
        "l",
        "l",
        "l",
        "n",
        "o",
        "o",
        "r"
      ]
    },
    {
      "words": [
        "mayor",
        "movie",
        "reach",
        "earth"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "o",
        "r",
        "t",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "whose",
        "works",
        "every",
        "sorry"
      ],
      "hints": [
        "w",
        "e",
        "s",
        "y"
      ],
      "letters": [
        "e",
        "h",
        "k",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "found",
        "fruit",
        "daily",
        "truly"
      ],
      "hints": [
        "f",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "r",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "which",
        "would",
        "human",
        "dozen"
      ],
      "hints": [
        "w",
        "h",
        "d",
        "n"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "m",
        "o",
        "o",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "vital",
        "visit",
        "legal",
        "trial"
      ],
      "hints": [
        "v",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "i",
        "i",
        "i",
        "i",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "awful",
        "agent",
        "leave",
        "these"
      ],
      "hints": [
        "a",
        "l",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "h",
        "n",
        "s",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "local",
        "lower",
        "leave",
        "range"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "n",
        "o",
        "o",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "craft",
        "chart",
        "tight",
        "treat"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "f",
        "g",
        "h",
        "h",
        "i",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "delay",
        "draft",
        "yours",
        "terms"
      ],
      "hints": [
        "d",
        "y",
        "t",
        "s"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "l",
        "m",
        "o",
        "r",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "chart",
        "crash",
        "twice",
        "horse"
      ],
      "hints": [
        "c",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "i",
        "o",
        "r",
        "r",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "award",
        "abuse",
        "dirty",
        "enjoy"
      ],
      "hints": [
        "a",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "i",
        "j",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "scale",
        "super",
        "early",
        "ready"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "l",
        "l",
        "p",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "coast",
        "chart",
        "trial",
        "total"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "a",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "again",
        "apple",
        "nurse",
        "elite"
      ],
      "hints": [
        "a",
        "n",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "g",
        "i",
        "i",
        "l",
        "l",
        "p",
        "p",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "apply",
        "asset",
        "young",
        "thing"
      ],
      "hints": [
        "a",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "e",
        "h",
        "i",
        "l",
        "n",
        "n",
        "o",
        "p",
        "p",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "front",
        "focus",
        "trade",
        "sense"
      ],
      "hints": [
        "f",
        "t",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "uncle",
        "union",
        "empty",
        "newly"
      ],
      "hints": [
        "u",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "l",
        "l",
        "m",
        "n",
        "n",
        "o",
        "p",
        "t",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "stuff",
        "shelf",
        "fault",
        "front"
      ],
      "hints": [
        "s",
        "f",
        "f",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "h",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "wound",
        "whose",
        "depth",
        "earth"
      ],
      "hints": [
        "w",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "n",
        "o",
        "o",
        "p",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shelf",
        "solve",
        "final",
        "equal"
      ],
      "hints": [
        "s",
        "f",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "l",
        "n",
        "o",
        "q",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "alone",
        "album",
        "earth",
        "match"
      ],
      "hints": [
        "a",
        "e",
        "m",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "dozen",
        "dress",
        "never",
        "swear"
      ],
      "hints": [
        "d",
        "n",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "o",
        "r",
        "s",
        "v",
        "w",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "tribe",
        "trace",
        "essay",
        "enemy"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "m",
        "n",
        "r",
        "r",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "heart",
        "horse",
        "tooth",
        "earth"
      ],
      "hints": [
        "h",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "o",
        "o",
        "o",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "movie",
        "match",
        "error",
        "humor"
      ],
      "hints": [
        "m",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "m",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "voice",
        "visit",
        "early",
        "today"
      ],
      "hints": [
        "v",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "i",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "could",
        "coast",
        "death",
        "tooth"
      ],
      "hints": [
        "c",
        "d",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "o",
        "o",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "funny",
        "focus",
        "yield",
        "speed"
      ],
      "hints": [
        "f",
        "y",
        "s",
        "d"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "i",
        "l",
        "n",
        "n",
        "o",
        "p",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "lemon",
        "level",
        "nerve",
        "leave"
      ],
      "hints": [
        "l",
        "n",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "e",
        "m",
        "o",
        "r",
        "v",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "guide",
        "grant",
        "eight",
        "tight"
      ],
      "hints": [
        "g",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "visit",
        "voice",
        "teach",
        "earth"
      ],
      "hints": [
        "v",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "i",
        "i",
        "i",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "among",
        "apart",
        "group",
        "troop"
      ],
      "hints": [
        "a",
        "g",
        "t",
        "p"
      ],
      "letters": [
        "a",
        "m",
        "n",
        "o",
        "o",
        "o",
        "o",
        "p",
        "r",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "judge",
        "joint",
        "early",
        "today"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "d",
        "g",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "moral",
        "maker",
        "lucky",
        "reply"
      ],
      "hints": [
        "m",
        "l",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "k",
        "k",
        "l",
        "o",
        "p",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "south",
        "shake",
        "house",
        "elite"
      ],
      "hints": [
        "s",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "h",
        "i",
        "k",
        "l",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "train",
        "trust",
        "north",
        "truth"
      ],
      "hints": [
        "t",
        "n",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "i",
        "o",
        "r",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "glove",
        "green",
        "elect",
        "night"
      ],
      "hints": [
        "g",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "l",
        "o",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "stone",
        "space",
        "entry",
        "every"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "n",
        "n",
        "o",
        "p",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "climb",
        "chart",
        "brand",
        "tired"
      ],
      "hints": [
        "c",
        "b",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "m",
        "n",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "drama",
        "death",
        "angry",
        "heavy"
      ],
      "hints": [
        "d",
        "a",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "m",
        "n",
        "r",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "angle",
        "adopt",
        "error",
        "their"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "d",
        "e",
        "g",
        "h",
        "i",
        "l",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "there",
        "tight",
        "exist",
        "trust"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "r",
        "r",
        "s",
        "s",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "reply",
        "rapid",
        "yours",
        "dress"
      ],
      "hints": [
        "r",
        "y",
        "d",
        "s"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "o",
        "p",
        "p",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "allow",
        "among",
        "whole",
        "glove"
      ],
      "hints": [
        "a",
        "w",
        "g",
        "e"
      ],
      "letters": [
        "h",
        "l",
        "l",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "o",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "video",
        "value",
        "occur",
        "error"
      ],
      "hints": [
        "v",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "d",
        "e",
        "i",
        "l",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "pause",
        "phase",
        "eager",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "h",
        "n",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "piece",
        "prime",
        "exact",
        "eight"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "m",
        "r",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "prior",
        "pound",
        "rough",
        "depth"
      ],
      "hints": [
        "p",
        "r",
        "d",
        "h"
      ],
      "letters": [
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "range",
        "radio",
        "enter",
        "other"
      ],
      "hints": [
        "r",
        "e",
        "o",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "n",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "horse",
        "humor",
        "earth",
        "rough"
      ],
      "hints": [
        "h",
        "e",
        "r",
        "h"
      ],
      "letters": [
        "a",
        "g",
        "m",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "tired",
        "trust",
        "doubt",
        "tight"
      ],
      "hints": [
        "t",
        "d",
        "t",
        "t"
      ],
      "letters": [
        "b",
        "e",
        "g",
        "h",
        "i",
        "i",
        "o",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "hotel",
        "human",
        "least",
        "night"
      ],
      "hints": [
        "h",
        "l",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "h",
        "i",
        "m",
        "o",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "event",
        "equal",
        "train",
        "learn"
      ],
      "hints": [
        "e",
        "t",
        "l",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "i",
        "n",
        "q",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "south",
        "still",
        "happy",
        "lucky"
      ],
      "hints": [
        "s",
        "h",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "k",
        "l",
        "o",
        "p",
        "p",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "sense",
        "stand",
        "eight",
        "doubt"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "n",
        "n",
        "o",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "trial",
        "trend",
        "laugh",
        "depth"
      ],
      "hints": [
        "t",
        "l",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "g",
        "i",
        "n",
        "p",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "least",
        "lower",
        "theme",
        "range"
      ],
      "hints": [
        "l",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "m",
        "n",
        "o",
        "s",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "naked",
        "novel",
        "delay",
        "lucky"
      ],
      "hints": [
        "n",
        "d",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "k",
        "k",
        "l",
        "o",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "shall",
        "scale",
        "leave",
        "elite"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "l",
        "l",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "video",
        "voice",
        "owner",
        "error"
      ],
      "hints": [
        "v",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "d",
        "e",
        "e",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "chief",
        "catch",
        "flame",
        "house"
      ],
      "hints": [
        "c",
        "f",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "m",
        "o",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "prior",
        "phase",
        "rough",
        "earth"
      ],
      "hints": [
        "p",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "g",
        "h",
        "i",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "bench",
        "brief",
        "heavy",
        "funny"
      ],
      "hints": [
        "b",
        "h",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "i",
        "n",
        "n",
        "n",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "photo",
        "paint",
        "offer",
        "tower"
      ],
      "hints": [
        "p",
        "o",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "f",
        "f",
        "h",
        "i",
        "n",
        "o",
        "o",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "which",
        "water",
        "happy",
        "ready"
      ],
      "hints": [
        "w",
        "h",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "e",
        "h",
        "i",
        "p",
        "p",
        "t"
      ]
    },
    {
      "words": [
        "union",
        "under",
        "novel",
        "rural"
      ],
      "hints": [
        "u",
        "n",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "forth",
        "false",
        "hotel",
        "equal"
      ],
      "hints": [
        "f",
        "h",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "l",
        "o",
        "o",
        "q",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "label",
        "lemon",
        "lover",
        "never"
      ],
      "hints": [
        "l",
        "l",
        "n",
        "r"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "e",
        "e",
        "e",
        "m",
        "o",
        "o",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "write",
        "wheel",
        "entry",
        "lucky"
      ],
      "hints": [
        "w",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "h",
        "i",
        "k",
        "n",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "tough",
        "troop",
        "honey",
        "party"
      ],
      "hints": [
        "t",
        "h",
        "p",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "quiet",
        "quick",
        "trace",
        "knife"
      ],
      "hints": [
        "q",
        "t",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "f",
        "i",
        "i",
        "i",
        "n",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "grant",
        "newly",
        "today"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "waste",
        "while",
        "entry",
        "essay"
      ],
      "hints": [
        "w",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "l",
        "n",
        "r",
        "s",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "actor",
        "awful",
        "river",
        "layer"
      ],
      "hints": [
        "a",
        "r",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "f",
        "i",
        "o",
        "t",
        "u",
        "v",
        "w",
        "y"
      ]
    },
    {
      "words": [
        "house",
        "heart",
        "eager",
        "their"
      ],
      "hints": [
        "h",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "o",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "laugh",
        "lunch",
        "honor",
        "humor"
      ],
      "hints": [
        "l",
        "h",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "g",
        "m",
        "n",
        "n",
        "o",
        "o",
        "o",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "lower",
        "learn",
        "round",
        "naked"
      ],
      "hints": [
        "l",
        "r",
        "n",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "k",
        "n",
        "o",
        "o",
        "r",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "basis",
        "build",
        "sheet",
        "draft"
      ],
      "hints": [
        "b",
        "s",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "l",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "worth",
        "white",
        "horse",
        "elite"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "chain",
        "check",
        "noise",
        "knife"
      ],
      "hints": [
        "c",
        "n",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "piece",
        "plane",
        "enemy",
        "essay"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "n",
        "s",
        "s"
      ]
    },
    {
      "words": [
        "swear",
        "sport",
        "react",
        "treat"
      ],
      "hints": [
        "s",
        "r",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "o",
        "p",
        "r",
        "r",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "angle",
        "about",
        "enter",
        "tower"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "g",
        "l",
        "n",
        "n",
        "o",
        "o",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "young",
        "yours",
        "guide",
        "sauce"
      ],
      "hints": [
        "y",
        "g",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "i",
        "n",
        "o",
        "o",
        "r",
        "u",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "owner",
        "onion",
        "river",
        "never"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "i",
        "i",
        "n",
        "n",
        "o",
        "v",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "fiber",
        "found",
        "rough",
        "depth"
      ],
      "hints": [
        "f",
        "r",
        "d",
        "h"
      ],
      "letters": [
        "b",
        "e",
        "e",
        "g",
        "i",
        "n",
        "o",
        "o",
        "p",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "sales",
        "shout",
        "small",
        "trial"
      ],
      "hints": [
        "s",
        "s",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "l",
        "m",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "daily",
        "doubt",
        "young",
        "thing"
      ],
      "hints": [
        "d",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "a",
        "b",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "sharp",
        "solid",
        "price",
        "drive"
      ],
      "hints": [
        "s",
        "p",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "i",
        "i",
        "l",
        "o",
        "r",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "flesh",
        "fruit",
        "heart",
        "treat"
      ],
      "hints": [
        "f",
        "h",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "l",
        "r",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "chief",
        "color",
        "final",
        "rural"
      ],
      "hints": [
        "c",
        "f",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "grand",
        "guess",
        "depth",
        "south"
      ],
      "hints": [
        "g",
        "d",
        "s",
        "h"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "n",
        "o",
        "p",
        "r",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "virus",
        "voter",
        "stair",
        "river"
      ],
      "hints": [
        "v",
        "s",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "i",
        "i",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "aside",
        "about",
        "exist",
        "tight"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "b",
        "d",
        "g",
        "h",
        "i",
        "i",
        "i",
        "o",
        "s",
        "s",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "weigh",
        "watch",
        "house",
        "horse"
      ],
      "hints": [
        "w",
        "h",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "false",
        "fight",
        "eight",
        "treat"
      ],
      "hints": [
        "f",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "below",
        "bench",
        "woman",
        "human"
      ],
      "hints": [
        "b",
        "w",
        "h",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "m",
        "m",
        "n",
        "o",
        "o",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "quote",
        "quiet",
        "eager",
        "tower"
      ],
      "hints": [
        "q",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "i",
        "o",
        "o",
        "t",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "track",
        "truth",
        "knife",
        "house"
      ],
      "hints": [
        "t",
        "k",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "f",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "honey",
        "humor",
        "yield",
        "round"
      ],
      "hints": [
        "h",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "l",
        "m",
        "n",
        "n",
        "o",
        "o",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "blame",
        "buyer",
        "eager",
        "river"
      ],
      "hints": [
        "b",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "i",
        "l",
        "m",
        "u",
        "v",
        "y"
      ]
    },
    {
      "words": [
        "court",
        "cloud",
        "these",
        "dance"
      ],
      "hints": [
        "c",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "l",
        "n",
        "o",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "small",
        "swear",
        "lover",
        "refer"
      ],
      "hints": [
        "s",
        "l",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "l",
        "m",
        "o",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "smile",
        "shoot",
        "elect",
        "tight"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "m",
        "o",
        "o"
      ]
    },
    {
      "words": [
        "wrong",
        "woman",
        "glove",
        "nerve"
      ],
      "hints": [
        "w",
        "g",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "l",
        "m",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "birth",
        "begin",
        "honey",
        "newly"
      ],
      "hints": [
        "b",
        "h",
        "n",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "g",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "peace",
        "pause",
        "enjoy",
        "essay"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "j",
        "n",
        "o",
        "s",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "proof",
        "price",
        "fight",
        "exist"
      ],
      "hints": [
        "p",
        "f",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "g",
        "h",
        "i",
        "i",
        "i",
        "o",
        "o",
        "r",
        "r",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "trace",
        "trust",
        "early",
        "truly"
      ],
      "hints": [
        "t",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "l",
        "l",
        "r",
        "r",
        "r",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "faith",
        "floor",
        "heart",
        "react"
      ],
      "hints": [
        "f",
        "h",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "ready",
        "round",
        "youth",
        "depth"
      ],
      "hints": [
        "r",
        "y",
        "d",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "n",
        "o",
        "o",
        "p",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "scene",
        "store",
        "entry",
        "every"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "n",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "watch",
        "works",
        "habit",
        "smart"
      ],
      "hints": [
        "w",
        "h",
        "s",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "i",
        "k",
        "m",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "major",
        "minor",
        "range",
        "raise"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "g",
        "i",
        "i",
        "j",
        "n",
        "n",
        "o",
        "o",
        "s"
      ]
    },
    {
      "words": [
        "hotel",
        "house",
        "leave",
        "elite"
      ],
      "hints": [
        "h",
        "l",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "l",
        "o",
        "o",
        "s",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "order",
        "other",
        "react",
        "right"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "issue",
        "image",
        "exist",
        "event"
      ],
      "hints": [
        "i",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "i",
        "m",
        "n",
        "s",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "print",
        "paper",
        "tower",
        "refer"
      ],
      "hints": [
        "p",
        "t",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "n",
        "o",
        "p",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "sleep",
        "strip",
        "paper",
        "power"
      ],
      "hints": [
        "s",
        "p",
        "p",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "i",
        "l",
        "o",
        "p",
        "r",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "lower",
        "lover",
        "refer",
        "river"
      ],
      "hints": [
        "l",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "o",
        "o",
        "v",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "blade",
        "bunch",
        "exist",
        "heart"
      ],
      "hints": [
        "b",
        "e",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "l",
        "n",
        "r",
        "s",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "young",
        "youth",
        "grave",
        "horse"
      ],
      "hints": [
        "y",
        "g",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "n",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "smile",
        "slice",
        "empty",
        "entry"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "c",
        "i",
        "i",
        "l",
        "l",
        "m",
        "m",
        "n",
        "p",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "steal",
        "super",
        "leave",
        "raise"
      ],
      "hints": [
        "s",
        "l",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "i",
        "p",
        "s",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "motor",
        "mouth",
        "refer",
        "humor"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "f",
        "m",
        "o",
        "o",
        "o",
        "o",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "group",
        "glove",
        "pilot",
        "event"
      ],
      "hints": [
        "g",
        "p",
        "e",
        "t"
      ],
      "letters": [
        "e",
        "i",
        "l",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "u",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "proof",
        "pause",
        "fight",
        "elect"
      ],
      "hints": [
        "p",
        "f",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "onion",
        "other",
        "never",
        "refer"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "h",
        "i",
        "n",
        "o",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "dozen",
        "dream",
        "night",
        "mount"
      ],
      "hints": [
        "d",
        "n",
        "m",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "o",
        "r",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "sheet",
        "still",
        "taste",
        "loose"
      ],
      "hints": [
        "s",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "o",
        "o",
        "s",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "begin",
        "brand",
        "nurse",
        "dance"
      ],
      "hints": [
        "b",
        "n",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "i",
        "n",
        "n",
        "r",
        "r",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "fruit",
        "flesh",
        "tight",
        "habit"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "l",
        "r",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "exist",
        "eager",
        "tower",
        "river"
      ],
      "hints": [
        "e",
        "t",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "g",
        "i",
        "i",
        "o",
        "s",
        "v",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "twice",
        "tower",
        "equal",
        "rural"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "q",
        "r",
        "u",
        "u",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "twice",
        "tired",
        "every",
        "dirty"
      ],
      "hints": [
        "t",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "i",
        "i",
        "i",
        "r",
        "r",
        "r",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "extra",
        "enjoy",
        "award",
        "yield"
      ],
      "hints": [
        "e",
        "a",
        "y",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "j",
        "l",
        "n",
        "o",
        "r",
        "r",
        "t",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "solve",
        "storm",
        "extra",
        "media"
      ],
      "hints": [
        "s",
        "e",
        "m",
        "a"
      ],
      "letters": [
        "d",
        "e",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "t",
        "t",
        "v",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "swear",
        "slide",
        "route",
        "elite"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "i",
        "l",
        "l",
        "o",
        "t",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "error",
        "early",
        "reach",
        "youth"
      ],
      "hints": [
        "e",
        "r",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "story",
        "shift",
        "yours",
        "terms"
      ],
      "hints": [
        "s",
        "y",
        "t",
        "s"
      ],
      "letters": [
        "e",
        "f",
        "h",
        "i",
        "m",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "group",
        "ghost",
        "piece",
        "title"
      ],
      "hints": [
        "g",
        "p",
        "t",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "i",
        "i",
        "l",
        "o",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "stake",
        "seize",
        "elect",
        "exist"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "i",
        "k",
        "l",
        "s",
        "t",
        "x",
        "z"
      ]
    }
  ],
  [
    {
      "words": [
        "swear",
        "sauce",
        "range",
        "elite"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "g",
        "i",
        "l",
        "n",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "upper",
        "urban",
        "route",
        "noise"
      ],
      "hints": [
        "u",
        "r",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "i",
        "o",
        "o",
        "p",
        "p",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "labor",
        "lunch",
        "ratio",
        "hello"
      ],
      "hints": [
        "l",
        "r",
        "h",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "i",
        "l",
        "l",
        "n",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "rural",
        "raise",
        "lunch",
        "earth"
      ],
      "hints": [
        "r",
        "l",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "i",
        "n",
        "r",
        "r",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "cloud",
        "chart",
        "delay",
        "truly"
      ],
      "hints": [
        "c",
        "d",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "l",
        "l",
        "l",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "power",
        "patch",
        "refer",
        "honor"
      ],
      "hints": [
        "p",
        "r",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "n",
        "o",
        "o",
        "o",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "board",
        "basis",
        "drink",
        "stock"
      ],
      "hints": [
        "b",
        "d",
        "s",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "tower",
        "twice",
        "reach",
        "earth"
      ],
      "hints": [
        "t",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "i",
        "o",
        "r",
        "t",
        "w",
        "w"
      ]
    },
    {
      "words": [
        "trade",
        "tower",
        "essay",
        "reply"
      ],
      "hints": [
        "t",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "l",
        "o",
        "p",
        "r",
        "s",
        "s",
        "w"
      ]
    },
    {
      "words": [
        "score",
        "sense",
        "eight",
        "elect"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "adapt",
        "along",
        "three",
        "grave"
      ],
      "hints": [
        "a",
        "t",
        "g",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "h",
        "l",
        "n",
        "o",
        "p",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "front",
        "fault",
        "twice",
        "there"
      ],
      "hints": [
        "f",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "about",
        "alone",
        "theme",
        "elite"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "b",
        "e",
        "h",
        "i",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "smoke",
        "super",
        "eager",
        "refer"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "f",
        "g",
        "k",
        "m",
        "o",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "stair",
        "slave",
        "ready",
        "entry"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "i",
        "l",
        "n",
        "r",
        "t",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "smoke",
        "short",
        "entry",
        "truly"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "h",
        "k",
        "l",
        "m",
        "n",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "stage",
        "small",
        "every",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "g",
        "k",
        "l",
        "m",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "bread",
        "bunch",
        "dance",
        "house"
      ],
      "hints": [
        "b",
        "d",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "n",
        "n",
        "o",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "forth",
        "fewer",
        "honor",
        "river"
      ],
      "hints": [
        "f",
        "h",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "sorry",
        "shift",
        "young",
        "thing"
      ],
      "hints": [
        "s",
        "y",
        "t",
        "g"
      ],
      "letters": [
        "f",
        "h",
        "h",
        "i",
        "i",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "shall",
        "start",
        "layer",
        "their"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "h",
        "h",
        "i",
        "l",
        "r",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "trail",
        "tough",
        "layer",
        "humor"
      ],
      "hints": [
        "t",
        "l",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "i",
        "m",
        "o",
        "o",
        "r",
        "u",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "sharp",
        "sleep",
        "prime",
        "pride"
      ],
      "hints": [
        "s",
        "p",
        "p",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "m",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "watch",
        "whose",
        "house",
        "elite"
      ],
      "hints": [
        "w",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "l",
        "o",
        "o",
        "s",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "group",
        "grass",
        "pause",
        "slave"
      ],
      "hints": [
        "g",
        "p",
        "s",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "l",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "shall",
        "stair",
        "level",
        "rural"
      ],
      "hints": [
        "s",
        "l",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "l",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "upper",
        "union",
        "right",
        "night"
      ],
      "hints": [
        "u",
        "r",
        "n",
        "t"
      ],
      "letters": [
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "young",
        "yours",
        "guard",
        "spend"
      ],
      "hints": [
        "y",
        "g",
        "s",
        "d"
      ],
      "letters": [
        "a",
        "e",
        "n",
        "n",
        "o",
        "o",
        "p",
        "r",
        "r",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "event",
        "exact",
        "those",
        "table"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "h",
        "l",
        "n",
        "o",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "coast",
        "chart",
        "those",
        "theme"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "h",
        "h",
        "m",
        "o",
        "o",
        "r",
        "s",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "equal",
        "early",
        "lunch",
        "youth"
      ],
      "hints": [
        "e",
        "l",
        "y",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "l",
        "n",
        "o",
        "q",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "fully",
        "fence",
        "youth",
        "earth"
      ],
      "hints": [
        "f",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "fence",
        "favor",
        "eight",
        "react"
      ],
      "hints": [
        "f",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "would",
        "world",
        "doubt",
        "draft"
      ],
      "hints": [
        "w",
        "d",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "b",
        "f",
        "l",
        "l",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "fight",
        "fewer",
        "table",
        "range"
      ],
      "hints": [
        "f",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "g",
        "g",
        "h",
        "i",
        "l",
        "n",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "occur",
        "ought",
        "rough",
        "tough"
      ],
      "hints": [
        "o",
        "r",
        "t",
        "h"
      ],
      "letters": [
        "c",
        "c",
        "g",
        "g",
        "g",
        "h",
        "o",
        "o",
        "u",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "plate",
        "proud",
        "essay",
        "delay"
      ],
      "hints": [
        "p",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "l",
        "l",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shade",
        "shoot",
        "eight",
        "tight"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "d",
        "g",
        "g",
        "h",
        "h",
        "h",
        "h",
        "i",
        "i",
        "o",
        "o"
      ]
    },
    {
      "words": [
        "eight",
        "exist",
        "title",
        "three"
      ],
      "hints": [
        "e",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "r",
        "s",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "slide",
        "score",
        "exact",
        "elect"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "c",
        "d",
        "e",
        "i",
        "l",
        "l",
        "o",
        "r",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "horse",
        "honor",
        "elect",
        "react"
      ],
      "hints": [
        "h",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "c",
        "e",
        "e",
        "l",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "shake",
        "shine",
        "enter",
        "error"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "i",
        "k",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "green",
        "grade",
        "novel",
        "equal"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "o",
        "q",
        "r",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "drive",
        "dance",
        "eight",
        "event"
      ],
      "hints": [
        "d",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "h",
        "i",
        "i",
        "n",
        "n",
        "r",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "guess",
        "grain",
        "smoke",
        "noise"
      ],
      "hints": [
        "g",
        "s",
        "n",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "i",
        "i",
        "k",
        "m",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "title",
        "tough",
        "enter",
        "humor"
      ],
      "hints": [
        "t",
        "e",
        "h",
        "r"
      ],
      "letters": [
        "e",
        "g",
        "i",
        "l",
        "m",
        "n",
        "o",
        "o",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "ought",
        "occur",
        "total",
        "rural"
      ],
      "hints": [
        "o",
        "t",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "g",
        "h",
        "o",
        "r",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "tight",
        "trail",
        "tribe",
        "leave"
      ],
      "hints": [
        "t",
        "t",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "g",
        "h",
        "i",
        "i",
        "i",
        "r",
        "r",
        "v"
      ]
    },
    {
      "words": [
        "cable",
        "count",
        "enemy",
        "truly"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "l",
        "l",
        "m",
        "n",
        "n",
        "o",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "model",
        "major",
        "legal",
        "rural"
      ],
      "hints": [
        "m",
        "l",
        "r",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "e",
        "g",
        "j",
        "o",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "light",
        "leave",
        "taste",
        "elite"
      ],
      "hints": [
        "l",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "h",
        "i",
        "i",
        "l",
        "s",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "shift",
        "style",
        "trial",
        "equal"
      ],
      "hints": [
        "s",
        "t",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "h",
        "i",
        "i",
        "l",
        "q",
        "r",
        "t",
        "u",
        "y"
      ]
    },
    {
      "words": [
        "tribe",
        "trial",
        "exact",
        "light"
      ],
      "hints": [
        "t",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "g",
        "h",
        "i",
        "i",
        "i",
        "r",
        "r",
        "x"
      ]
    },
    {
      "words": [
        "blame",
        "blade",
        "elect",
        "exist"
      ],
      "hints": [
        "b",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "i",
        "l",
        "l",
        "l",
        "m",
        "s",
        "x"
      ]
    },
    {
      "words": [
        "stake",
        "stair",
        "eager",
        "river"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "i",
        "i",
        "k",
        "t",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "cause",
        "catch",
        "entry",
        "heavy"
      ],
      "hints": [
        "c",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "n",
        "r",
        "s",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "favor",
        "faith",
        "route",
        "horse"
      ],
      "hints": [
        "f",
        "r",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "i",
        "o",
        "o",
        "o",
        "r",
        "s",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "brush",
        "bunch",
        "happy",
        "honey"
      ],
      "hints": [
        "b",
        "h",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "n",
        "n",
        "o",
        "p",
        "p",
        "r",
        "s",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "count",
        "crowd",
        "title",
        "dance"
      ],
      "hints": [
        "c",
        "t",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "fifty",
        "favor",
        "yield",
        "rapid"
      ],
      "hints": [
        "f",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "i",
        "i",
        "i",
        "l",
        "o",
        "p",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "cheek",
        "crime",
        "knife",
        "elite"
      ],
      "hints": [
        "c",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "i",
        "l",
        "m",
        "n",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "pilot",
        "paper",
        "today",
        "reply"
      ],
      "hints": [
        "p",
        "t",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "i",
        "l",
        "l",
        "o",
        "o",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "aware",
        "agent",
        "exist",
        "tight"
      ],
      "hints": [
        "a",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "g",
        "h",
        "i",
        "i",
        "n",
        "r",
        "s",
        "w",
        "x"
      ]
    },
    {
      "words": [
        "civil",
        "chase",
        "least",
        "exist"
      ],
      "hints": [
        "c",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "s",
        "s",
        "s",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "storm",
        "scope",
        "minor",
        "enter"
      ],
      "hints": [
        "s",
        "m",
        "e",
        "r"
      ],
      "letters": [
        "c",
        "e",
        "i",
        "n",
        "n",
        "o",
        "o",
        "o",
        "p",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "sorry",
        "slide",
        "youth",
        "earth"
      ],
      "hints": [
        "s",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "l",
        "o",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "swear",
        "slave",
        "right",
        "elect"
      ],
      "hints": [
        "s",
        "r",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "l",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "truth",
        "table",
        "heart",
        "exact"
      ],
      "hints": [
        "t",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "c",
        "e",
        "l",
        "r",
        "r",
        "t",
        "u",
        "x"
      ]
    },
    {
      "words": [
        "would",
        "watch",
        "doubt",
        "habit"
      ],
      "hints": [
        "w",
        "d",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "b",
        "c",
        "i",
        "l",
        "o",
        "o",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "seven",
        "since",
        "north",
        "earth"
      ],
      "hints": [
        "s",
        "n",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "space",
        "solar",
        "enjoy",
        "ready"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "j",
        "l",
        "n",
        "o",
        "o",
        "p"
      ]
    },
    {
      "words": [
        "alter",
        "alive",
        "reach",
        "earth"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "l",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "state",
        "steal",
        "enter",
        "layer"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "n",
        "t",
        "t",
        "t",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "light",
        "local",
        "their",
        "lover"
      ],
      "hints": [
        "l",
        "t",
        "l",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "start",
        "stand",
        "trust",
        "doubt"
      ],
      "hints": [
        "s",
        "t",
        "d",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "ought",
        "order",
        "tired",
        "rapid"
      ],
      "hints": [
        "o",
        "t",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "e",
        "g",
        "h",
        "i",
        "i",
        "p",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "angle",
        "alone",
        "elect",
        "event"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "g",
        "l",
        "l",
        "l",
        "n",
        "n",
        "n",
        "o",
        "v"
      ]
    },
    {
      "words": [
        "sugar",
        "shall",
        "ready",
        "lucky"
      ],
      "hints": [
        "s",
        "r",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "d",
        "e",
        "g",
        "h",
        "k",
        "l",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "skill",
        "sight",
        "loose",
        "there"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "e"
      ],
      "letters": [
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "k",
        "l",
        "o",
        "o",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "twice",
        "three",
        "event",
        "eight"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "r",
        "v",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "stuff",
        "sound",
        "fully",
        "dirty"
      ],
      "hints": [
        "s",
        "f",
        "d",
        "y"
      ],
      "letters": [
        "f",
        "i",
        "l",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "train",
        "treat",
        "night",
        "tight"
      ],
      "hints": [
        "t",
        "n",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "piano",
        "prove",
        "other",
        "enter"
      ],
      "hints": [
        "p",
        "o",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "peace",
        "print",
        "eager",
        "tower"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "g",
        "i",
        "n",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "place",
        "paper",
        "error",
        "river"
      ],
      "hints": [
        "p",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "o",
        "p",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "empty",
        "exact",
        "yield",
        "third"
      ],
      "hints": [
        "e",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "i",
        "i",
        "l",
        "m",
        "p",
        "r",
        "t",
        "x"
      ]
    },
    {
      "words": [
        "onion",
        "other",
        "naked",
        "rapid"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "h",
        "i",
        "i",
        "k",
        "n",
        "o",
        "p",
        "t"
      ]
    },
    {
      "words": [
        "solve",
        "small",
        "enjoy",
        "lucky"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "j",
        "k",
        "l",
        "l",
        "m",
        "n",
        "o",
        "o",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "table",
        "these",
        "exact",
        "exist"
      ],
      "hints": [
        "t",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "h",
        "i",
        "l",
        "s",
        "s",
        "x",
        "x"
      ]
    },
    {
      "words": [
        "match",
        "mouse",
        "heart",
        "exist"
      ],
      "hints": [
        "m",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "hotel",
        "habit",
        "lucky",
        "truly"
      ],
      "hints": [
        "h",
        "l",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "b",
        "c",
        "e",
        "i",
        "k",
        "l",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "limit",
        "loose",
        "tribe",
        "elite"
      ],
      "hints": [
        "l",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "b",
        "i",
        "i",
        "i",
        "i",
        "l",
        "m",
        "o",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "birth",
        "blade",
        "horse",
        "elite"
      ],
      "hints": [
        "b",
        "h",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "other",
        "ocean",
        "react",
        "night"
      ],
      "hints": [
        "o",
        "r",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "t"
      ]
    },
    {
      "words": [
        "arise",
        "allow",
        "empty",
        "worry"
      ],
      "hints": [
        "a",
        "e",
        "w",
        "y"
      ],
      "letters": [
        "i",
        "l",
        "l",
        "m",
        "o",
        "o",
        "p",
        "r",
        "r",
        "r",
        "s",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "sugar",
        "swear",
        "round",
        "rapid"
      ],
      "hints": [
        "s",
        "r",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "g",
        "i",
        "n",
        "o",
        "p",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "wound",
        "whole",
        "dance",
        "elite"
      ],
      "hints": [
        "w",
        "d",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "l",
        "l",
        "n",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "humor",
        "human",
        "rural",
        "novel"
      ],
      "hints": [
        "h",
        "r",
        "n",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "m",
        "m",
        "o",
        "o",
        "r",
        "u",
        "u",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "fault",
        "forth",
        "title",
        "horse"
      ],
      "hints": [
        "f",
        "t",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "i",
        "l",
        "l",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "crime",
        "chart",
        "exist",
        "tight"
      ],
      "hints": [
        "c",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "m",
        "r",
        "r",
        "s",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "leave",
        "loose",
        "enter",
        "eager"
      ],
      "hints": [
        "l",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "n",
        "o",
        "o",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "usual",
        "union",
        "limit",
        "night"
      ],
      "hints": [
        "u",
        "l",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "i",
        "i",
        "m",
        "n",
        "o",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "minor",
        "match",
        "refer",
        "humor"
      ],
      "hints": [
        "m",
        "r",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "f",
        "i",
        "m",
        "n",
        "o",
        "o",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "shoot",
        "speak",
        "table",
        "knife"
      ],
      "hints": [
        "s",
        "t",
        "k",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "f",
        "h",
        "i",
        "l",
        "n",
        "o",
        "o",
        "p"
      ]
    },
    {
      "words": [
        "giant",
        "ghost",
        "touch",
        "tooth"
      ],
      "hints": [
        "g",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "c",
        "h",
        "i",
        "n",
        "o",
        "o",
        "o",
        "o",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "whole",
        "wheel",
        "elite",
        "loose"
      ],
      "hints": [
        "w",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "h",
        "i",
        "l",
        "l",
        "o",
        "o",
        "o",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "again",
        "apple",
        "never",
        "eager"
      ],
      "hints": [
        "a",
        "n",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "g",
        "g",
        "i",
        "l",
        "p",
        "p",
        "v"
      ]
    },
    {
      "words": [
        "pause",
        "pride",
        "entry",
        "empty"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "i",
        "m",
        "n",
        "p",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "south",
        "short",
        "honor",
        "tower"
      ],
      "hints": [
        "s",
        "h",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "h",
        "n",
        "o",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "fence",
        "frame",
        "enter",
        "error"
      ],
      "hints": [
        "f",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "m",
        "n",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "blood",
        "bunch",
        "delay",
        "happy"
      ],
      "hints": [
        "b",
        "d",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "l",
        "n",
        "o",
        "o",
        "p",
        "p",
        "u"
      ]
    },
    {
      "words": [
        "green",
        "glove",
        "never",
        "enter"
      ],
      "hints": [
        "g",
        "n",
        "e",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "l",
        "n",
        "o",
        "r",
        "t",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "virus",
        "refer",
        "sugar"
      ],
      "hints": [
        "v",
        "r",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "i",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "adopt",
        "adapt",
        "think",
        "thick"
      ],
      "hints": [
        "a",
        "t",
        "t",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "d",
        "h",
        "h",
        "i",
        "i",
        "n",
        "o",
        "p",
        "p"
      ]
    },
    {
      "words": [
        "score",
        "stuff",
        "enter",
        "favor"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "f",
        "n",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "visit",
        "value",
        "theme",
        "elite"
      ],
      "hints": [
        "v",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "l",
        "l",
        "m",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "staff",
        "sight",
        "flesh",
        "teach"
      ],
      "hints": [
        "s",
        "f",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "f",
        "g",
        "h",
        "i",
        "l",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "fruit",
        "favor",
        "those",
        "raise"
      ],
      "hints": [
        "f",
        "t",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "i",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "count",
        "chest",
        "tooth",
        "touch"
      ],
      "hints": [
        "c",
        "t",
        "t",
        "h"
      ],
      "letters": [
        "c",
        "e",
        "h",
        "n",
        "o",
        "o",
        "o",
        "o",
        "s",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "radio",
        "raise",
        "ought",
        "eight"
      ],
      "hints": [
        "r",
        "o",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "i",
        "s",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "climb",
        "cross",
        "block",
        "speak"
      ],
      "hints": [
        "c",
        "b",
        "s",
        "k"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "l",
        "m",
        "o",
        "o",
        "p",
        "r",
        "s"
      ]
    },
    {
      "words": [
        "print",
        "phase",
        "truth",
        "earth"
      ],
      "hints": [
        "p",
        "t",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "h",
        "i",
        "n",
        "r",
        "r",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "hello",
        "humor",
        "offer",
        "refer"
      ],
      "hints": [
        "h",
        "o",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "f",
        "f",
        "f",
        "l",
        "l",
        "m",
        "o",
        "u"
      ]
    },
    {
      "words": [
        "stage",
        "sugar",
        "enter",
        "refer"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "f",
        "g",
        "g",
        "n",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "maker",
        "motor",
        "radio",
        "ratio"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "o"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "d",
        "e",
        "i",
        "i",
        "k",
        "o",
        "o",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "alone",
        "actor",
        "every",
        "reply"
      ],
      "hints": [
        "a",
        "e",
        "r",
        "y"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "l",
        "l",
        "n",
        "o",
        "o",
        "p",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "ideal",
        "inner",
        "least",
        "right"
      ],
      "hints": [
        "i",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "n",
        "s"
      ]
    },
    {
      "words": [
        "imply",
        "issue",
        "youth",
        "earth"
      ],
      "hints": [
        "i",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "l",
        "m",
        "o",
        "p",
        "r",
        "s",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "crazy",
        "count",
        "youth",
        "tooth"
      ],
      "hints": [
        "c",
        "y",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "t",
        "t",
        "u",
        "u",
        "z"
      ]
    },
    {
      "words": [
        "meter",
        "maybe",
        "route",
        "elite"
      ],
      "hints": [
        "m",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "e",
        "i",
        "l",
        "o",
        "t",
        "t",
        "t",
        "u",
        "y"
      ]
    }
  ],
  [
    {
      "words": [
        "react",
        "refer",
        "third",
        "rapid"
      ],
      "hints": [
        "r",
        "t",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "h",
        "i",
        "i",
        "p",
        "r"
      ]
    },
    {
      "words": [
        "grave",
        "guide",
        "enemy",
        "entry"
      ],
      "hints": [
        "g",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "i",
        "m",
        "n",
        "n",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "young",
        "youth",
        "grant",
        "heart"
      ],
      "hints": [
        "y",
        "g",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "n",
        "n",
        "o",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "admit",
        "ahead",
        "track",
        "drink"
      ],
      "hints": [
        "a",
        "t",
        "d",
        "k"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "d",
        "e",
        "h",
        "i",
        "i",
        "m",
        "n",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "among",
        "awful",
        "guest",
        "light"
      ],
      "hints": [
        "a",
        "g",
        "l",
        "t"
      ],
      "letters": [
        "e",
        "f",
        "g",
        "h",
        "i",
        "m",
        "n",
        "o",
        "s",
        "u",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "upper",
        "until",
        "route",
        "leave"
      ],
      "hints": [
        "u",
        "r",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "i",
        "n",
        "o",
        "p",
        "p",
        "t",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "apple",
        "again",
        "enemy",
        "newly"
      ],
      "hints": [
        "a",
        "e",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "i",
        "l",
        "l",
        "m",
        "n",
        "p",
        "p",
        "w"
      ]
    },
    {
      "words": [
        "level",
        "later",
        "light",
        "react"
      ],
      "hints": [
        "l",
        "l",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "phase",
        "press",
        "enter",
        "super"
      ],
      "hints": [
        "p",
        "e",
        "s",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "h",
        "n",
        "p",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "sharp",
        "steal",
        "prove",
        "loose"
      ],
      "hints": [
        "s",
        "p",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "o",
        "o",
        "o",
        "r",
        "r",
        "s",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "quite",
        "quote",
        "essay",
        "enjoy"
      ],
      "hints": [
        "q",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "i",
        "j",
        "n",
        "o",
        "o",
        "s",
        "s",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "story",
        "shirt",
        "yield",
        "trend"
      ],
      "hints": [
        "s",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "fifty",
        "front",
        "yield",
        "trend"
      ],
      "hints": [
        "f",
        "y",
        "t",
        "d"
      ],
      "letters": [
        "e",
        "e",
        "f",
        "i",
        "i",
        "l",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "thank",
        "trade",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "f",
        "h",
        "i",
        "i",
        "l",
        "n",
        "n",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "anger",
        "abuse",
        "rural",
        "equal"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "g",
        "n",
        "q",
        "r",
        "s",
        "u",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "broad",
        "bunch",
        "delay",
        "honey"
      ],
      "hints": [
        "b",
        "d",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "class",
        "catch",
        "share",
        "house"
      ],
      "hints": [
        "c",
        "s",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "h",
        "l",
        "o",
        "r",
        "s",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "often",
        "owner",
        "night",
        "right"
      ],
      "hints": [
        "o",
        "n",
        "r",
        "t"
      ],
      "letters": [
        "e",
        "e",
        "f",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "n",
        "t",
        "w"
      ]
    },
    {
      "words": [
        "paper",
        "power",
        "round",
        "rapid"
      ],
      "hints": [
        "p",
        "r",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "n",
        "o",
        "o",
        "p",
        "p",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "count",
        "chase",
        "there",
        "elite"
      ],
      "hints": [
        "c",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "h",
        "h",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "scene",
        "sound",
        "eight",
        "doubt"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "t"
      ],
      "letters": [
        "b",
        "c",
        "e",
        "g",
        "h",
        "i",
        "n",
        "n",
        "o",
        "o",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "shell",
        "since",
        "label",
        "equal"
      ],
      "hints": [
        "s",
        "l",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "e",
        "h",
        "i",
        "l",
        "n",
        "q",
        "u"
      ]
    },
    {
      "words": [
        "cause",
        "civil",
        "exact",
        "least"
      ],
      "hints": [
        "c",
        "e",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "level",
        "leave",
        "light",
        "event"
      ],
      "hints": [
        "l",
        "l",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "e",
        "g",
        "h",
        "i",
        "n",
        "v",
        "v",
        "v"
      ]
    },
    {
      "words": [
        "photo",
        "print",
        "often",
        "train"
      ],
      "hints": [
        "p",
        "o",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "e",
        "f",
        "h",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "naked",
        "noise",
        "depth",
        "earth"
      ],
      "hints": [
        "n",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "k",
        "o",
        "p",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "super",
        "stand",
        "reply",
        "daily"
      ],
      "hints": [
        "s",
        "r",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "l",
        "l",
        "n",
        "p",
        "p",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "cheap",
        "close",
        "pride",
        "elite"
      ],
      "hints": [
        "c",
        "p",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "h",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "visit",
        "virus",
        "thing",
        "swing"
      ],
      "hints": [
        "v",
        "t",
        "s",
        "g"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "i",
        "i",
        "i",
        "n",
        "n",
        "r",
        "s",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "awful",
        "agree",
        "labor",
        "eager"
      ],
      "hints": [
        "a",
        "l",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "e",
        "f",
        "g",
        "g",
        "o",
        "r",
        "u",
        "w"
      ]
    }
  ],
  [
    {
      "words": [
        "catch",
        "child",
        "happy",
        "dirty"
      ],
      "hints": [
        "c",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "h",
        "i",
        "i",
        "l",
        "p",
        "p",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "bench",
        "bread",
        "honey",
        "dirty"
      ],
      "hints": [
        "b",
        "h",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "about",
        "adapt",
        "taste",
        "trade"
      ],
      "hints": [
        "a",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "d",
        "d",
        "o",
        "p",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "honor",
        "habit",
        "route",
        "tribe"
      ],
      "hints": [
        "h",
        "r",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "b",
        "i",
        "i",
        "n",
        "o",
        "o",
        "o",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "light",
        "large",
        "table",
        "elite"
      ],
      "hints": [
        "l",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "g",
        "g",
        "h",
        "i",
        "i",
        "l",
        "l",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "scene",
        "shirt",
        "entry",
        "today"
      ],
      "hints": [
        "s",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "h",
        "i",
        "n",
        "n",
        "o",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "touch",
        "treat",
        "happy",
        "truly"
      ],
      "hints": [
        "t",
        "h",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "l",
        "o",
        "p",
        "p",
        "r",
        "r",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "thick",
        "these",
        "knife",
        "elite"
      ],
      "hints": [
        "t",
        "k",
        "e",
        "e"
      ],
      "letters": [
        "c",
        "e",
        "f",
        "h",
        "h",
        "i",
        "i",
        "i",
        "l",
        "n",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "voice",
        "vital",
        "elite",
        "leave"
      ],
      "hints": [
        "v",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "i",
        "l",
        "o",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "strip",
        "start",
        "point",
        "tight"
      ],
      "hints": [
        "s",
        "p",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "g",
        "h",
        "i",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "focus",
        "final",
        "smart",
        "light"
      ],
      "hints": [
        "f",
        "s",
        "l",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "h",
        "i",
        "i",
        "m",
        "n",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "press",
        "place",
        "split",
        "event"
      ],
      "hints": [
        "p",
        "s",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "i",
        "l",
        "l",
        "n",
        "p",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "tower",
        "tribe",
        "rural",
        "equal"
      ],
      "hints": [
        "t",
        "r",
        "e",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "e",
        "i",
        "o",
        "q",
        "r",
        "r",
        "u",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "catch",
        "count",
        "human",
        "train"
      ],
      "hints": [
        "c",
        "h",
        "t",
        "n"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "i",
        "m",
        "n",
        "o",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "shape",
        "smile",
        "exact",
        "elect"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "e",
        "h",
        "i",
        "l",
        "l",
        "m",
        "p",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "scene",
        "style",
        "eight",
        "elect"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "t"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "g",
        "h",
        "i",
        "l",
        "l",
        "n",
        "t",
        "y"
      ]
    },
    {
      "words": [
        "plate",
        "price",
        "error",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "i",
        "l",
        "n",
        "o",
        "r",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "horse",
        "honor",
        "event",
        "react"
      ],
      "hints": [
        "h",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "n",
        "n",
        "o",
        "o",
        "o",
        "r",
        "s",
        "v"
      ]
    },
    {
      "words": [
        "shore",
        "shall",
        "elite",
        "leave"
      ],
      "hints": [
        "s",
        "e",
        "l",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "h",
        "i",
        "l",
        "l",
        "o",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "voter",
        "video",
        "refer",
        "occur"
      ],
      "hints": [
        "v",
        "r",
        "o",
        "r"
      ],
      "letters": [
        "c",
        "c",
        "d",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "o",
        "t",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "truth",
        "topic",
        "habit",
        "coast"
      ],
      "hints": [
        "t",
        "h",
        "c",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "i",
        "i",
        "o",
        "o",
        "p",
        "r",
        "s",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "laugh",
        "leave",
        "heart",
        "eight"
      ],
      "hints": [
        "l",
        "h",
        "e",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "g",
        "g",
        "h",
        "i",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "price",
        "pride",
        "enter",
        "eager"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "e",
        "g",
        "i",
        "i",
        "n",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "slice",
        "sauce",
        "enjoy",
        "early"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "c",
        "i",
        "j",
        "l",
        "l",
        "n",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "lunch",
        "later",
        "humor",
        "refer"
      ],
      "hints": [
        "l",
        "h",
        "r",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "e",
        "f",
        "m",
        "n",
        "o",
        "t",
        "u",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "smell",
        "sport",
        "layer",
        "tower"
      ],
      "hints": [
        "s",
        "l",
        "t",
        "r"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "l",
        "m",
        "o",
        "o",
        "p",
        "r",
        "w",
        "y"
      ]
    },
    {
      "words": [
        "third",
        "taste",
        "delay",
        "empty"
      ],
      "hints": [
        "t",
        "d",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "m",
        "p",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "throw",
        "title",
        "where",
        "elite"
      ],
      "hints": [
        "t",
        "w",
        "e",
        "e"
      ],
      "letters": [
        "e",
        "h",
        "h",
        "i",
        "i",
        "l",
        "l",
        "o",
        "r",
        "r",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "grand",
        "ghost",
        "depth",
        "teach"
      ],
      "hints": [
        "g",
        "d",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "n",
        "o",
        "p",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "carry",
        "cover",
        "yield",
        "rapid"
      ],
      "hints": [
        "c",
        "y",
        "r",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "i",
        "i",
        "l",
        "o",
        "p",
        "r",
        "r",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "motor",
        "meter",
        "refer",
        "river"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "o",
        "o",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "leave",
        "light",
        "elite",
        "three"
      ],
      "hints": [
        "l",
        "e",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "r",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "arise",
        "aside",
        "enjoy",
        "entry"
      ],
      "hints": [
        "a",
        "e",
        "e",
        "y"
      ],
      "letters": [
        "d",
        "i",
        "i",
        "j",
        "n",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "score",
        "swear",
        "eight",
        "right"
      ],
      "hints": [
        "s",
        "e",
        "r",
        "t"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "g",
        "g",
        "h",
        "h",
        "i",
        "i",
        "o",
        "r",
        "w"
      ]
    },
    {
      "words": [
        "cause",
        "cabin",
        "equal",
        "novel"
      ],
      "hints": [
        "c",
        "e",
        "n",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "i",
        "o",
        "q",
        "s",
        "u",
        "u",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "drive",
        "draft",
        "equal",
        "total"
      ],
      "hints": [
        "d",
        "e",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "f",
        "i",
        "o",
        "q",
        "r",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "anger",
        "adapt",
        "rough",
        "truth"
      ],
      "hints": [
        "a",
        "r",
        "t",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "g",
        "g",
        "n",
        "o",
        "p",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "grain",
        "giant",
        "naked",
        "third"
      ],
      "hints": [
        "g",
        "n",
        "t",
        "d"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "i",
        "i",
        "k",
        "n",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "prove",
        "paint",
        "exist",
        "trust"
      ],
      "hints": [
        "p",
        "e",
        "t",
        "t"
      ],
      "letters": [
        "a",
        "i",
        "i",
        "n",
        "o",
        "r",
        "r",
        "s",
        "s",
        "u",
        "v",
        "x"
      ]
    },
    {
      "words": [
        "phase",
        "porch",
        "essay",
        "happy"
      ],
      "hints": [
        "p",
        "e",
        "h",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "c",
        "h",
        "o",
        "p",
        "p",
        "r",
        "s",
        "s",
        "s"
      ]
    }
  ],
  [
    {
      "words": [
        "legal",
        "lunch",
        "lower",
        "honor"
      ],
      "hints": [
        "l",
        "l",
        "h",
        "r"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "g",
        "n",
        "n",
        "o",
        "o",
        "o",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "alter",
        "argue",
        "raise",
        "elite"
      ],
      "hints": [
        "a",
        "r",
        "e",
        "e"
      ],
      "letters": [
        "a",
        "e",
        "g",
        "i",
        "i",
        "l",
        "l",
        "r",
        "s",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "naked",
        "nerve",
        "depth",
        "earth"
      ],
      "hints": [
        "n",
        "d",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "e",
        "e",
        "k",
        "p",
        "r",
        "r",
        "t",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "throw",
        "third",
        "where",
        "dance"
      ],
      "hints": [
        "t",
        "w",
        "d",
        "e"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "h",
        "h",
        "h",
        "i",
        "n",
        "o",
        "r",
        "r",
        "r"
      ]
    },
    {
      "words": [
        "extra",
        "event",
        "alter",
        "tower"
      ],
      "hints": [
        "e",
        "a",
        "t",
        "r"
      ],
      "letters": [
        "e",
        "e",
        "e",
        "l",
        "n",
        "o",
        "r",
        "t",
        "t",
        "v",
        "w",
        "x"
      ]
    }
  ],
  [
    {
      "words": [
        "about",
        "alone",
        "twice",
        "elite"
      ],
      "hints": [
        "a",
        "t",
        "e",
        "e"
      ],
      "letters": [
        "b",
        "c",
        "i",
        "i",
        "l",
        "l",
        "n",
        "o",
        "o",
        "t",
        "u",
        "w"
      ]
    },
    {
      "words": [
        "build",
        "brain",
        "draft",
        "night"
      ],
      "hints": [
        "b",
        "d",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "f",
        "g",
        "h",
        "i",
        "i",
        "i",
        "l",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "pilot",
        "proof",
        "third",
        "found"
      ],
      "hints": [
        "p",
        "t",
        "f",
        "d"
      ],
      "letters": [
        "h",
        "i",
        "i",
        "l",
        "n",
        "o",
        "o",
        "o",
        "o",
        "r",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "chase",
        "cabin",
        "elect",
        "night"
      ],
      "hints": [
        "c",
        "e",
        "n",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "c",
        "e",
        "g",
        "h",
        "h",
        "i",
        "i",
        "l",
        "s"
      ]
    },
    {
      "words": [
        "juice",
        "joint",
        "enemy",
        "today"
      ],
      "hints": [
        "j",
        "e",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "d",
        "e",
        "i",
        "i",
        "m",
        "n",
        "n",
        "o",
        "o",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "until",
        "uncle",
        "laugh",
        "earth"
      ],
      "hints": [
        "u",
        "l",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "g",
        "i",
        "l",
        "n",
        "n",
        "r",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "motor",
        "maker",
        "route",
        "range"
      ],
      "hints": [
        "m",
        "r",
        "r",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "k",
        "n",
        "o",
        "o",
        "o",
        "t",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "refer",
        "range",
        "ready",
        "entry"
      ],
      "hints": [
        "r",
        "r",
        "e",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "d",
        "e",
        "e",
        "e",
        "f",
        "g",
        "n",
        "n",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "smile",
        "stare",
        "error",
        "eager"
      ],
      "hints": [
        "s",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "g",
        "i",
        "l",
        "m",
        "o",
        "r",
        "r",
        "r",
        "t"
      ]
    },
    {
      "words": [
        "shape",
        "salad",
        "enemy",
        "dirty"
      ],
      "hints": [
        "s",
        "e",
        "d",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "h",
        "i",
        "l",
        "m",
        "n",
        "p",
        "r",
        "t"
      ]
    }
  ],
  [
    {
      "words": [
        "cheek",
        "could",
        "knock",
        "drink"
      ],
      "hints": [
        "c",
        "k",
        "d",
        "k"
      ],
      "letters": [
        "c",
        "e",
        "e",
        "h",
        "i",
        "l",
        "n",
        "n",
        "o",
        "o",
        "r",
        "u"
      ]
    },
    {
      "words": [
        "dream",
        "death",
        "mount",
        "heart"
      ],
      "hints": [
        "d",
        "m",
        "h",
        "t"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "e",
        "e",
        "e",
        "n",
        "o",
        "r",
        "r",
        "t",
        "u"
      ]
    },
    {
      "words": [
        "draft",
        "doubt",
        "those",
        "these"
      ],
      "hints": [
        "d",
        "t",
        "t",
        "e"
      ],
      "letters": [
        "a",
        "b",
        "e",
        "f",
        "h",
        "h",
        "o",
        "o",
        "r",
        "s",
        "s",
        "u"
      ]
    },
    {
      "words": [
        "peace",
        "phase",
        "error",
        "enter"
      ],
      "hints": [
        "p",
        "e",
        "e",
        "r"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "e",
        "h",
        "n",
        "o",
        "r",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "fiber",
        "fruit",
        "ready",
        "today"
      ],
      "hints": [
        "f",
        "r",
        "t",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "b",
        "d",
        "d",
        "e",
        "e",
        "i",
        "i",
        "o",
        "r",
        "u"
      ]
    }
  ],
  [
    {
      "words": [
        "occur",
        "owner",
        "river",
        "refer"
      ],
      "hints": [
        "o",
        "r",
        "r",
        "r"
      ],
      "letters": [
        "c",
        "c",
        "e",
        "e",
        "e",
        "e",
        "f",
        "i",
        "n",
        "u",
        "v",
        "w"
      ]
    },
    {
      "words": [
        "sense",
        "staff",
        "early",
        "fifty"
      ],
      "hints": [
        "s",
        "e",
        "f",
        "y"
      ],
      "letters": [
        "a",
        "a",
        "e",
        "f",
        "f",
        "i",
        "l",
        "n",
        "r",
        "s",
        "t",
        "t"
      ]
    },
    {
      "words": [
        "equal",
        "event",
        "label",
        "trial"
      ],
      "hints": [
        "e",
        "l",
        "t",
        "l"
      ],
      "letters": [
        "a",
        "a",
        "a",
        "b",
        "e",
        "e",
        "i",
        "n",
        "q",
        "r",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "often",
        "onion",
        "noise",
        "nerve"
      ],
      "hints": [
        "o",
        "n",
        "n",
        "e"
      ],
      "letters": [
        "e",
        "e",
        "f",
        "i",
        "i",
        "n",
        "o",
        "o",
        "r",
        "s",
        "t",
        "v"
      ]
    },
    {
      "words": [
        "movie",
        "media",
        "enter",
        "anger"
      ],
      "hints": [
        "m",
        "e",
        "a",
        "r"
      ],
      "letters": [
        "d",
        "e",
        "e",
        "e",
        "g",
        "i",
        "i",
        "n",
        "n",
        "o",
        "t",
        "v"
      ]
    }
  ],
  [
    {
      "words": [
        "study",
        "sense",
        "youth",
        "earth"
      ],
      "hints": [
        "s",
        "y",
        "e",
        "h"
      ],
      "letters": [
        "a",
        "d",
        "e",
        "n",
        "o",
        "r",
        "s",
        "t",
        "t",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "field",
        "faith",
        "dance",
        "horse"
      ],
      "hints": [
        "f",
        "d",
        "h",
        "e"
      ],
      "letters": [
        "a",
        "a",
        "c",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "r",
        "s",
        "t"
      ]
    },
    {
      "words": [
        "heart",
        "hotel",
        "truly",
        "lucky"
      ],
      "hints": [
        "h",
        "t",
        "l",
        "y"
      ],
      "letters": [
        "a",
        "c",
        "e",
        "e",
        "k",
        "l",
        "o",
        "r",
        "r",
        "t",
        "u",
        "u"
      ]
    },
    {
      "words": [
        "river",
        "round",
        "reply",
        "dirty"
      ],
      "hints": [
        "r",
        "r",
        "d",
        "y"
      ],
      "letters": [
        "e",
        "e",
        "i",
        "i",
        "l",
        "n",
        "o",
        "p",
        "r",
        "t",
        "u",
        "v"
      ]
    },
    {
      "words": [
        "guard",
        "green",
        "dirty",
        "newly"
      ],
      "hints": [
        "g",
        "d",
        "n",
        "y"
      ],
      "letters": [
        "a",
        "e",
        "e",
        "e",
        "i",
        "l",
        "r",
        "r",
        "r",
        "t",
        "u",
        "w"
      ]
    }
  ]
];