<template>
  <b-container class="d-flex flex-column justify-content-between h-full">
    <header-menu />
    <game-board />
    <key-board />

    <modal-complete @close="modalOpen = false" />
    <modal-solved @close="nextPuzzle" />
  </b-container>
</template>

<script>
import GameBoard from '@/components/blocks/GameBoard.vue'
import HeaderMenu from '@/components/blocks/HeaderMenu.vue'
import KeyBoard from '@/components/blocks/KeyBoard.vue'
import ModalComplete from '@/components/modals/Complete.vue'
import ModalSolved from '@/components/modals/Solved.vue'

export default {
  name: 'App',
  components: {
    GameBoard,
    HeaderMenu,
    KeyBoard,
    ModalComplete,
    ModalSolved,
  },
  data: () => ({
    puzzle: {},
    modalOpen: false,
    allSolved: false,
  }),
  watch: {
    '$store.getters.puzzle': {
      handler() {
        this.puzzle = this.$store.getters.puzzle

        if (this.puzzle === null) {
          this.modalOpen = true
          this.allSolved = true
          this.$store.commit('setPuzzle', 0)
          this.$bvModal.show('modalComplete')
        } else if (this.puzzle.solved && !this.modalOpen) {
          this.modalOpen = true
          this.$bvModal.show('modalSolved')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.nextPuzzle()

    if (window.localStorage.getItem('wp_first_load') !== '1') {
      window.localStorage.setItem('wp_first_load', '1')
      this.$bvModal.show('modalAbout')
    }
  },
  methods: {
    nextPuzzle() {
      this.modalOpen = false

      if (!this.allSolved) {
        this.$store.commit('load')
      }
    },
  },
}
</script>

<style>
.h-full { height: 92vh; }
</style>
