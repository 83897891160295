<template>
  <div class="board">
    <div class="row">
      <game-board-cell :index="0" check="tl" hint />
      <game-board-cell :index="1" check="t" />
      <game-board-cell :index="2" check="t" />
      <game-board-cell :index="3" check="t" />
      <game-board-cell :index="4" check="tr" hint />
    </div>

    <div class="row">
      <game-board-cell :index="5" check="l" />
      <div class="spacer" />
      <game-board-cell :index="6" check="r" />
    </div>

    <div class="row">
      <game-board-cell :index="7" check="l" />
      <div class="spacer text-center pt-2">
        <strong>PUZZLE #{{ index + 1 }}</strong>
        <br>
        <em class="text-muted">{{ date }}</em>
      </div>
      <game-board-cell :index="8" check="r" />
    </div>

    <div class="row">
      <game-board-cell :index="9" check="l" />
      <div class="spacer" />
      <game-board-cell :index="10" check="r" />
    </div>

    <div class="row">
      <game-board-cell :index="11" check="bl" hint />
      <game-board-cell :index="12" check="b" />
      <game-board-cell :index="13" check="b" />
      <game-board-cell :index="14" check="b" />
      <game-board-cell :index="15" check="br" hint />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import GameBoardCell from './GameBoardCell.vue'

export default {
  name: 'GameBoard',
  components: {
    GameBoardCell,
  },
  computed: {
    date() {
      return moment().format('D MMMM YYYY')
    },
    index() {
      return this.$store.getters.index
    },
  },
}
</script>

<style>
.row {
  display: flex;
  justify-content: center;
}
.spacer { width: 12.75rem; }

</style>
