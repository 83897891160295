import moment from 'moment'
import puzzles from '@/assets/puzzles.js'
import words from '@/assets/words.js'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 0,
    puzzles: [],
    active: -1,
  },
  getters: {
    active: state => state.active,
    index: state => state.index,
    puzzle: state => state.index == -1 ? null : state.puzzles[ state.index ],
    puzzles: state => state.puzzles,
  },
  mutations: {
    selectCell (state, index) {
      // Reset current letter
      if (state.puzzles[ state.index ].board[ index ] !== null) {
        const letter = state.puzzles[ state.index ].board[ index ]
        state.puzzles[ state.index ].board[ index ] = null

        for (let key in state.puzzles[ state.index ].letters) {
          if (
            state.puzzles[ state.index ].letters[ key ].letter === letter &&
            state.puzzles[ state.index ].letters[ key ].enabled === false
          ) {
            state.puzzles[ state.index ].letters[ key ].enabled = true
            break
          }
        }
      }

      // Is a new letter selected?
      if (state.active !== -1) {
        state.puzzles[ state.index ].board[ index ] = state.puzzles[ state.index ].letters[ state.active ].letter
        state.puzzles[ state.index ].letters[ state.active ].enabled = false
        state.active = -1
      }

      // Check words
      this.commit('checkWords')

      // Check if puzzle is solved
      const nulls = state.puzzles[ state.index ].board.filter(cell => cell === null)
      state.puzzles[ state.index ].solved = (nulls.length === 0 && state.puzzles[ state.index ].results === '')

      this.commit('save')
    },
    setPuzzle (state, index) {
      state.index = index
    },
    setActive (state, index) {
      state.active = index
    },
    checkWords (state) {
      const tWord =
        state.puzzles[ state.index ].board[ 0 ] +
        state.puzzles[ state.index ].board[ 1 ] +
        state.puzzles[ state.index ].board[ 2 ] +
        state.puzzles[ state.index ].board[ 3 ] +
        state.puzzles[ state.index ].board[ 4 ]
      const lWord =
        state.puzzles[ state.index ].board[ 0 ] +
        state.puzzles[ state.index ].board[ 5 ] +
        state.puzzles[ state.index ].board[ 7 ] +
        state.puzzles[ state.index ].board[ 9 ] +
        state.puzzles[ state.index ].board[ 11 ]
      const rWord =
        state.puzzles[ state.index ].board[ 4 ] +
        state.puzzles[ state.index ].board[ 6 ] +
        state.puzzles[ state.index ].board[ 8 ] +
        state.puzzles[ state.index ].board[ 10 ] +
        state.puzzles[ state.index ].board[ 15 ]
      const bWord =
        state.puzzles[ state.index ].board[ 11 ] +
        state.puzzles[ state.index ].board[ 12 ] +
        state.puzzles[ state.index ].board[ 13 ] +
        state.puzzles[ state.index ].board[ 14 ] +
        state.puzzles[ state.index ].board[ 15 ]

      state.puzzles[ state.index ].results = [
        tWord.length === 5 && words.indexOf(tWord) === -1 ? 't' : '',
        lWord.length === 5 && words.indexOf(lWord) === -1 ? 'l' : '',
        rWord.length === 5 && words.indexOf(rWord) === -1 ? 'r' : '',
        bWord.length === 5 && words.indexOf(bWord) === -1 ? 'b' : '',
      ].join('')
    },
    load (state) {
      const today = puzzles[ moment().dayOfYear() ]

      let statuses = {}
      if (window.localStorage.getItem('wb_puzzles')) {
        statuses = JSON.parse(window.localStorage.getItem('wb_puzzles'))
      }

      if (statuses[ moment().format('YYYY-MM-DD') ]) {
        state.puzzles = statuses[ moment().format('YYYY-MM-DD') ]
      } else {
        state.puzzles = []

        today.forEach(puzzle => {
          const letters = puzzle.letters.map(letter => ({
            letter,
            enabled: true,
          }))

          const board = new Array(16).fill(null)
          board[0] = puzzle.hints[0]
          board[4] = puzzle.hints[1]
          board[11] = puzzle.hints[2]
          board[15] = puzzle.hints[3]

          state.puzzles.push({
            letters,
            board,
            results: '',
            hints: puzzle.hints,
            solved: false,
          })
        })

        statuses[ moment().format('YYYY-MM-DD') ] = state.puzzles
        window.localStorage.setItem('wb_puzzles', JSON.stringify(statuses))
      }

      for (let i = 0; i < state.puzzles.length; i++) {
        if (!state.puzzles[i].solved) {
          state.index = i
          return
        }
      }

      state.index = -1
    },
    save (state) {
      let statuses = {}
      if (window.localStorage.getItem('wb_puzzles')) {
        statuses = JSON.parse(window.localStorage.getItem('wb_puzzles'))
      }

      statuses[ moment().format('YYYY-MM-DD') ] = state.puzzles
      window.localStorage.setItem('wb_puzzles', JSON.stringify(statuses))
    },
  },
})
