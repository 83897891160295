<template>
  <header class="d-flex justify-content-between align-items-center py-3 border-bottom">
    <b-button class="pt-2" variant="outline-dark" @click="$bvModal.show('modalPuzzles')">
      <b-icon-list />
    </b-button>

    <a href="/" class="text-dark">
      <h1 class="logo mt-2 mb-0">WordBox</h1>
    </a>

    <b-button class="pt-2" variant="outline-dark" @click="$bvModal.show('modalAbout')">
      <b-icon-question />
    </b-button>

    <modal-about />
    <modal-puzzles />
  </header>
</template>

<script>
import { BIconList, BIconQuestion } from 'bootstrap-vue'
import ModalAbout from '@/components/modals/About.vue'
import ModalPuzzles from '@/components/modals/Puzzles.vue'

export default {
  name: 'HeaderMenu',
  components: {
    BIconList,
    BIconQuestion,
    ModalAbout,
    ModalPuzzles,
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&display=swap');

.logo { font-family: 'Josefin Sans', sans-serif; }
</style>
