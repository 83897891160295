<template>
  <b-modal
    id="modalAbout"
    title="About WordBox"
    centered hide-footer
  >
    <p>
      The objective of <em>WordBox</em> is to arrange all of the
      letters to spell four words along the sides of a square.
    </p>

    <p>
      The letters in each corner are used by both words. To help you
      out, the corner letters are provided as a hint.
    </p>

    <p>
      Simply tap a letter from the bottom, and then tap a grid cell
      to place the letter.
    </p>

    <p>
      Five puzzles are provided each day, so come back tomorrow to play again!
    </p>

    <hr>

    <div class="puzzle-summary">
      <div class="hint">W</div>
      <div>O</div>
      <div>O</div>
      <div>D</div>
      <div class="hint">S</div>

      <div>E</div>
      <div class="spacer">&nbsp;</div>
      <div>I</div>

      <div>A</div>
      <div class="spacer">&nbsp;</div>
      <div>G</div>

      <div>R</div>
      <div class="spacer">&nbsp;</div>
      <div>H</div>

      <div class="hint">S</div>
      <div>H</div>
      <div>I</div>
      <div>P</div>
      <div class="hint">S</div>
    </div>

    <hr>

    <p>If the entered word is invalid, the letters will be highlighted in red.</p>

    <p class="mb-0">To remove a letter from the grid, click it and it will be replaced with a blank.</p>
  </b-modal>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped>
.puzzle-summary {
  width: calc(5 * 2.25rem);
  margin: 1rem auto;
}
.puzzle-summary > div {
  display: inline-block;
  width: 2rem; height: 2rem;
  margin: 0.125rem;
  border: 0.125rem solid #dee2e6;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
}
.puzzle-summary > div.hint { border-color: #343a40; }
.puzzle-summary > div.spacer {
  width: 6.5rem;
  border: none;
}
</style>

