<template>
  <b-modal
    id="modalPuzzles"
    title="Today's Puzzles"
    centered hide-footer body-class="p-0"
    @show="loadPuzzles"
  >
    <div
      class="puzzle-row" @click="setPuzzle(index)"
      v-for="(puzzle, index) in puzzles" :key="index"
    >
      <span>
        <strong>#{{ index + 1 }}</strong>: &nbsp;
        <span class="text-uppercase">{{ puzzle.hints.join(', ') }}</span>
      </span>

      <b-badge variant="success" v-if="puzzle.solved">Solved</b-badge>
      <b-icon-chevron-right v-else />
    </div>
  </b-modal>
</template>

<script>
import { BIconChevronRight } from 'bootstrap-vue'

export default {
  name: 'Puzzles',
  components: {
    BIconChevronRight,
  },
  data: () => ({
    puzzles: [],
  }),
  methods: {
    loadPuzzles() {
      this.puzzles = this.$store.getters.puzzles
    },
    setPuzzle(index) {
      this.$store.commit('setPuzzle', index)
      this.$bvModal.hide('modalPuzzles')
    },
  },
}
</script>

<style scoped>
.puzzle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}
.puzzle-row:last-of-type { border-bottom: none !important; }
</style>
