import { render, staticRenderFns } from "./KeyBoard.vue?vue&type=template&id=fef87418&scoped=true&"
import script from "./KeyBoard.vue?vue&type=script&lang=js&"
export * from "./KeyBoard.vue?vue&type=script&lang=js&"
import style0 from "./KeyBoard.vue?vue&type=style&index=0&id=fef87418&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fef87418",
  null
  
)

export default component.exports