<template>
  <b-modal
    id="modalComplete"
    title="All Puzzles Solved!"
    ok-variant="dark"
    centered ok-only
    @hide="$emit('close')"
  >
    <p>
      You solved all 5 of today's puzzles!
    </p>

    <p class="mb-0">
      Come back tomorrow to play 5 new puzzles!
    </p>
  </b-modal>
</template>

<script>
export default {
  name: 'Complete',
}
</script>
